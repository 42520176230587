import React, { useState, useEffect } from 'react';
import { Table, Form, Button, Tooltip } from 'antd';
import {Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import store from "../store/store.js";
const MessageTable = (props) => {
    var originData = props.msglist;
    var Type = props.type;
    const [form] = Form.useForm();
    const [data, setData] = useState(originData);
    const [type, setType] = useState(Type);
    useEffect(() => {
        setData(originData);
    }, [originData]);
    useEffect(() => {
        setType(Type);
    }, [Type]);
    var columns = [];
    props.type == 1 ?
        columns = [
            {
                title: 'id',
                dataIndex: 'id',
                width: 10
            },
            {
                title: '标题',
                dataIndex: 'title',
                width: 100,
            },
            // {
            //     title: '内容',
            //     dataIndex: 'content',
            //     width: 300,
            //     ellipsis: {
            //         showTitle: false,
            //     },
            //     render: content => (
            //         <Tooltip placement="topLeft" title={content}>
            //             {content}
            //         </Tooltip>
            //     ),
            // },
            {
                title: '收信人',
                dataIndex: 'jsid',
                width: 100,
                render: (text, record) => 
                    text.map((list, index) => { return <span key={index} >{index!=0?'，':null}{list.name}</span> }
                    )
                ,
            },
            // {
            //     title: '附件',
            //     dataIndex: 'fileid',
            //     width: 100,
            //     render: (text, record) =>
            //         text.map((list, index) => 
            //             { return <a href={list.url} key={index} download style={{display:'block'}}>{list.name}</a> }
            //         )
            //     ,
            // },
            {
                title: '已读人数/总人数',
                dataIndex: 'status',
                width: 80,
                sorter: (a, b) => a.status2 - b.status2,
                render: (text, record) => (
                    <Tooltip 
                        placement="topLeft" 
                        title={
                            record.wjsid ?
                                (record.wjsid.map((list, index) => {return <span key = { index } > {index != 0 ? '，' : null} {list.name} </span> }))
                                :
                                null
                        }
                    >
                       { record.yidu + '/' + record.weidu}
                    </Tooltip>
                )
                ,
            },
            {
                title: '发送人',
                dataIndex: 'send',
                width: 50,
            },
            {
                title: '发送时间',
                dataIndex: 'addtime',
                width: 50,
            },
            {
                title: '操作',
                key: 'operation',
                // fixed: 'right',
                width: 100,
                align: 'center',
                render: (text, record) =>
                    <>
                        <Link to={{ pathname: '/messageview/' + record.id }} >查看</Link>
                    </>
                ,
            },
        ]
    :
        columns = [
            {
                title: 'id',
                dataIndex: 'id',
                width: 50,
            },
            {
                title: '标题',
                dataIndex: 'title',
                width: 100,
            },
            // {
            //     title: '内容',
            //     dataIndex: 'content',
            //     width: 300,
            //     ellipsis: {
            //         showTitle: false,
            //     },
            //     render: content => (
            //         <Tooltip placement="topLeft" title={content}>
            //             {content}
            //         </Tooltip>
            //     ),
            // },
            // {
            //     title: '附件',
            //     dataIndex: 'fileid',
            //     width: 100,
            //     render: (text, record) =>
            //         text.map((list, index) => { return <a href={list.url} key={index} download style={{ display: 'block' }}>{list.name}</a> }
            //         )
            //     ,
            // },
            {
                title: '状态',
                dataIndex: 'status',
                width: 60,
                sorter: (a, b) => a.status2 - b.status2,
            },
            {
                title: '发送时间',
                dataIndex: 'addtime',
                width: 100,
            },
            {
                title: '发送人',
                dataIndex: 'send',
                width: 100,
            },
            {
                title: '操作',
                key: 'operation',
                // fixed: 'right',
                width: 100,
                align: 'center',
                render: (text, record) =>
                    <>
                        <Link to={{ pathname: '/messageview/' + record.id }}>查看</Link>
                    </>
                ,
            },
        ];
    return (
        <Form form={form} component={false}>
            <Table
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="editable-row"
                pagination={false}
                scroll={store.getState().data.ismobile == 1 ? { x: 700 } : null}
            />
        </Form>
    );
};
export default MessageTable;