import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { DatePicker, Space, Select, Input, Button, Table, Modal, Divider, Row, Col, Form, Radio, Upload, message, Drawer, Avatar, Tabs, Popconfirm, Tag, Tooltip } from 'antd';
import store from "../store/store.js";
import { DownloadOutlined, CalendarOutlined, AlertOutlined, UserOutlined, PaperClipOutlined, DeleteOutlined, TagsOutlined, ClockCircleOutlined } from '@ant-design/icons'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Addgzx from "./addgzx";
import Gzxview from "./gzxview";
import Addxm from "./addxm";
import moment from 'moment';
const { Option } = Select;
const { Dragger } = Upload;
const { TextArea } = Input;
const { TabPane } = Tabs;
export default class View extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id,
                uid: store.getState().data.info.id ? store.getState().data.info.id : 0,
                fjlist1: [],
                fjlist2: [],
                gzxlist1: [],
                gzxlist2: [],
                gzxlist3: [],
                gzxlist4: [],
            },
            listtype: props.listtype ? props.listtype : '1',
            visible: false,
            loading: false,
            loading2: false
        }
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.isLogin)
        })
    }
    componentDidMount() {

    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onSelChange2(name, value) {
        let that = this
        console.log(name, value)
        this.setState({
            [name]: value
        })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={Number(item.id)} key={index}>{item.name}</Option>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }
    sub() {
        let that = this
        // that.setState({
        //     loading: true
        // })
        // fetch('/Rizhi/doview', {
        //     method: "POST",
        //     body: JSON.stringify(that.state.condition)
        // })
        //     .then(res => res.json())
        //     .then(data => {
        //         if (data.status == 1) {
        //             message.success(data.msg);
        //             this.props.refresh()
        //             this.mdchange()
        //         } else {
        //             message.error(data.msg)
        //             that.setState({
        //                 loading: false
        //             })
        //         }
        //     })
    }
    getdata() {
        let that = this
        fetch('/Xiangmu/view2', {
            method: "POST",
            body: JSON.stringify({
                id: this.state.condition.id,
                uid: this.state.condition.uid
            })
        })
            .then(res => res.json())
            .then(data => {
                if (this.state.condition.id) {
                    if (data.status == 1) {
                        data.data.status = Number(data.data.status);
                        that.setState({
                            condition: data.data,
                        })
                    } else {
                        message.error(data.msg)
                        that.mdchange()
                    }
                }
                console.log(data)
            })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata()
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                    uid: store.getState().data.info.id ? store.getState().data.info.id : 0,
                    fjlist1: [],
                    fjlist2: [],
                    gzxlist1: [],
                    gzxlist2: [],
                    gzxlist3: [],
                    gzxlist4: [],
                },
                listtype: this.props.listtype ? this.props.listtype : '1',
                loading: false
            })
        }
    }
    delfile(index, e) {
        console.log(index, e)
        var picfile = this.state.condition.picfile
        picfile.splice(index, 1)
        let data = Object.assign({}, this.state.condition, { picfile: picfile })
        this.setState({
            condition: data,
        })
    }
    // 上传图片
    picChange(info) {
        // console.log(info)
        if (info.file.status === 'uploading') {
            this.setState({ loading2: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            // console.log(info.file.response)

            var picfile = this.state.condition.picfile
            picfile = picfile.concat(info.file.response)
            let data = Object.assign({}, this.state.condition, { picfile: picfile })
            this.setState({
                condition: data,
                loading2: false,
            })
        }
    };
    arrfile(arr,type) {
        if (arr) {
            return arr.map((item, index) =>
                <div className='item' key={index}>
                    <div className='l'>
                        <div className='pic'>
                            {(item.ext == 'doc' || item.ext == 'docx') ? <i className='iconfont' style={{ color: 'rgb(20,169,218)' }}>&#xe66e;</i> : ''}
                            {(item.ext == 'xls' || item.ext == 'xlsx') ? <i className='iconfont' style={{ color: 'rgb(69,176,88)' }}>&#xe665;</i> : ''}
                            {(item.ext == 'ppt' || item.ext == 'pptx') ? <i className='iconfont' style={{ color: 'rgb(227,66,33)' }}>&#xe66b;</i> : ''}
                            {(item.ext == 'jpg' || item.ext == 'jpge' || item.ext == 'png') ? <i className='iconfont' style={{ color: 'rgb(73,201,167)' }}>&#xe667;</i> : ''}
                            {(item.ext == 'pdf') ? <i className='iconfont' style={{ color: 'rgb(140,24,26)' }}>&#xe66a;</i> : ''}
                            {(item.ext == 'psd') ? <i className='iconfont' style={{ color: 'rgb(12,119,198)' }}>&#xe66c;</i> : ''}
                            {(item.ext == 'txt') ? <i className='iconfont' style={{ color: 'rgb(249,202,6)' }}>&#xe66d;</i> : ''}
                            {(item.ext == 'zip' || item.ext == 'rar') ? <i className='iconfont' style={{ color: 'rgb(129,153,175)' }}>&#xe8b7;</i> : ''}
                        </div>
                        <div className='info'>
                            <div className='p1'>{item.name}</div>
                            <div className='p2'>{item.daxiao} {item.aname ? ('来自 ' + item.aname) : ''}</div>
                        </div>
                    </div>
                    <div className='r r1'>
                        {item.time}
                    </div>
                    <div className='r r2'>
                        <Space>
                            <a href={item.url} target="_blank" download={item.name2}><DownloadOutlined /></a>
                            {item.aid==store.getState().data.info.id?
                                <Popconfirm
                                    title="确定删除?"
                                    onConfirm={this.delfj.bind(this,type,item.id,item.pgid)}
                                    okText="确定"
                                    cancelText="取消"
                                >
                                    <a href='javascript:void(0)' className='del'><DeleteOutlined /></a>
                                </Popconfirm>
                                :null
                            }
                        </Space>
                    </div>
                </div>
            )
        }
    }
    delfj = (type,id,pgid) =>{
        let that = this
        console.log(type,id,pgid)
        fetch('/Common/delfl', {
            method: "POST",
            body: JSON.stringify({
                type: type,
                id: id,
                pgid: pgid,
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                if (data.status == 1) {
                    message.success(data.msg)
                    that.getdata()
                } else {
                    message.error(data.msg)
                }
            })
            
    }
    refreshAll = () => {
        this.getdata()
        this.props.refresh()
    }
    // 拖拽调整顺序
    onDragEnd = (result) => {
        let that = this
        // console.log(result)
        const sourceIndex = result.source.index;
        const sourceDroppableId = result.source.droppableId;
        if (result.destination) {
            const destinationIndex = result.destination.index;
            const destinationDroppableId = result.destination.droppableId;
            if (sourceIndex === destinationIndex && sourceDroppableId === destinationDroppableId) {
                return;
            }
            if (sourceDroppableId !== destinationDroppableId) {
                const sourcelist = that.state.condition[sourceDroppableId].concat();
                const [draggedItem] = sourcelist.splice(sourceIndex, 1);
                const destinationlist = that.state.condition[destinationDroppableId].concat();
                destinationlist.splice(destinationIndex, 0, draggedItem);

                let data = Object.assign({}, that.state.condition, { [sourceDroppableId]: sourcelist, [destinationDroppableId]: destinationlist })
                that.setState({
                    condition: data,
                })
            } else {
                const sourcelist = that.state.condition[sourceDroppableId].concat();
                const [draggedItem] = sourcelist.splice(sourceIndex, 1);
                sourcelist.splice(destinationIndex, 0, draggedItem);
                let data = Object.assign({}, that.state.condition, { [sourceDroppableId]: sourcelist })
                that.setState({
                    condition: data,
                })
            }
        }
    };
    render() {
        const count = this.state.condition.gzxnum1 + this.state.condition.gzxnum2 + this.state.condition.gzxnum3 + this.state.condition.gzxnum4
        const count2 = this.state.condition.fjlist1.length + '+' + this.state.condition.fjlist2.length
        return (
            <>
                {this.state.condition.id ?
                    <a href='javascript:void(0)' type="link" onClick={this.mdchange.bind(this)} >{this.props.text}</a>
                    :
                    <Button type="primary" onClick={this.mdchange.bind(this)} className='but yellow'>添加</Button>
                }
                <Drawer
                    title="项目详情"
                    width={1400}
                    visible={this.state.visible}
                    onClose={this.mdchange.bind(this)}
                // bodyStyle={{ padding: 0 }}
                >
                    <div className='xmTop'>
                        <div className='p1'>#  <Addxm id={this.state.condition.id} refresh={this.refreshAll.bind(this)} title={this.state.condition.xmmc} /></div>
                        <div className='p2'>
                            <Space size={[50, 25]} wrap>
                                <div className='item'>
                                    <div className='l'>
                                        <Avatar icon={<UserOutlined />} size={35} src={this.state.condition.picurl} />
                                    </div>
                                    <div className='r'>
                                        <div className='p1'>项目负责人</div>
                                        <div className='p2'>{this.state.condition.fzrname}</div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='l'>
                                        <Avatar icon={<UserOutlined />} size={35} />
                                    </div>
                                    <div className='r'>
                                        <div className='p1'>客户名称</div>
                                        <div className='p2'>{this.state.condition.khmc}</div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='l'>
                                        <Avatar icon={<AlertOutlined />} style={{ backgroundColor: 'rgb(229,81,52)', }} size={35} />
                                    </div>
                                    <div className='r'>
                                        <div className='p1'>紧急</div>
                                        <div className='p2'>{this.state.condition.gzxnum1 ? this.state.condition.gzxnum1 : 0}</div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='l'>
                                        <Avatar icon={<i className='iconfont'>&#xeb1b;</i>} style={{ backgroundColor: 'rgb(240,161,1)', }} size={35} />
                                    </div>
                                    <div className='r'>
                                        <div className='p1'>进行中</div>
                                        <div className='p2'>{this.state.condition.gzxnum2 ? this.state.condition.gzxnum2 : 0}</div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='l'>
                                        <Avatar icon={<i className='iconfont'>&#xeb19;</i>} style={{ backgroundColor: 'rgb(52,143,229)', }} size={35} />
                                    </div>
                                    <div className='r'>
                                        <div className='p1'>等待</div>
                                        <div className='p2'>{this.state.condition.gzxnum3 ? this.state.condition.gzxnum3 : 0}</div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='l'>
                                        <Avatar icon={<i className='iconfont'>&#xeb16;</i>} style={{ backgroundColor: 'rgb(37,180,127)', }} size={35} />
                                    </div>
                                    <div className='r'>
                                        <div className='p1'>已完成</div>
                                        <div className='p2'>{this.state.condition.gzxnum4 ? this.state.condition.gzxnum4 : 0}</div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='l'>
                                        <Avatar icon={<CalendarOutlined />} style={{ backgroundColor: 'rgb(204,204,204)', }} size={35} />
                                    </div>
                                    <div className='r'>
                                        <div className='p1'>项目计划周期</div>
                                        <div className='p2'>{this.state.condition.starttime} ~ {this.state.condition.endtime}</div>
                                    </div>
                                </div>
                            </Space>
                        </div>
                    </div>
                    <Tabs className='xmviewgzx' activeKey={this.state.listtype ? this.state.listtype : 1} onChange={this.onSelChange2.bind(this, 'listtype')}>
                        <TabPane tab={<>工作项{count ? '(' + count + ')' : ''}</>} key={1}>
                            <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                                <div className="tuozhuai">
                                    <div className='itemlist'>
                                        <div className='title'>
                                            <div className='l'>
                                                <div className='p1'>紧急</div>
                                                <div className='p2 p2_1'>{this.state.condition.gzxlist1 ? this.state.condition.gzxlist1.length : 0}</div>
                                            </div>
                                            <div className='r'>
                                                <Addgzx type="1" typename="紧急" refresh={this.getdata.bind(this)} pid={this.state.condition.id} />
                                            </div>
                                        </div>
                                        <div
                                            className="list">
                                            {this.state.condition.gzxlist1 ? this.state.condition.gzxlist1.map((item, index) => (
                                                <div
                                                    className="item"
                                                    key={item.name}>
                                                    <div className='p1'>
                                                        <Gzxview id={item.id} name={item.name} refresh={this.getdata.bind(this)} type="1" typename="紧急" />

                                                    </div>
                                                    <div className='p2'>
                                                        <TagsOutlined /> {item.protitle}
                                                    </div>
                                                    <div className='p3'>
                                                        <Space wrap>
                                                            {item.ulist ? item.ulist.map((item1, index1) => (
                                                                <div className={'bqitem ' + (item1.status == 1 ? 'iconfont hover' : '')} key={index}>{item1.msg}</div>
                                                            )) : ''}
                                                        </Space>
                                                    </div>
                                                    <div className='p4'>
                                                        <div className='l l1'>
                                                            <ClockCircleOutlined className='biao' />{item.time} {item.iscs == 1 ? <Tag color="red">{item.cs}</Tag> : null}
                                                        </div>
                                                        <div className='r'>
                                                            <Space wrap>
                                                                {item.ulist ? item.ulist.map((item1, index1) => (
                                                                    <Tooltip title={item1.name} key={index}>
                                                                        <div className={'txitem ' + (item1.status == 1 ? 'iconfont hover' : '')}>
                                                                            <Avatar size="small" icon={<UserOutlined />} src={item1.picurl} />
                                                                        </div>
                                                                    </Tooltip>
                                                                )) : ''}

                                                            </Space>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) : null}
                                        </div>
                                    </div>
                                    <div className='itemlist'>
                                        <div className='title'>
                                            <div className='l'>
                                                <div className='p1'>进行中</div>
                                                <div className='p2 p2_2'>{this.state.condition.gzxlist2 ? this.state.condition.gzxlist2.length : 0}</div>
                                            </div>
                                            <div className='r'>
                                                <Addgzx type="2" typename="进行中" refresh={this.getdata.bind(this)} pid={this.state.condition.id} />
                                            </div>
                                        </div>
                                        <div
                                            className="list"
                                        >
                                            {this.state.condition.gzxlist2 ? this.state.condition.gzxlist2.map((item, index) => (
                                                <div
                                                    className="item"
                                                    key={item.name}
                                                >
                                                    <div className='p1'>
                                                        <Gzxview id={item.id} name={item.name} refresh={this.getdata.bind(this)} type="2" typename="进行中" />

                                                    </div>
                                                    <div className='p2'>
                                                        <TagsOutlined /> {item.protitle}
                                                    </div>
                                                    <div className='p3'>
                                                        <Space wrap>
                                                            {item.ulist ? item.ulist.map((item1, index1) => (
                                                                <div className={'bqitem ' + (item1.status == 1 ? 'iconfont hover' : '')} key={index}>{item1.msg}</div>
                                                            )) : ''}
                                                        </Space>
                                                    </div>
                                                    <div className='p4'>
                                                        <div className='l l1'>
                                                            <ClockCircleOutlined className='biao' />{item.time} {item.iscs == 1 ? <Tag color="red">{item.cs}</Tag> : null}
                                                        </div>
                                                        <div className='r'>
                                                            <Space wrap>
                                                                {item.ulist ? item.ulist.map((item1, index1) => (
                                                                    <Tooltip title={item1.name} key={index}>
                                                                        <div className={'txitem ' + (item1.status == 1 ? 'iconfont hover' : '')}>
                                                                            <Avatar size="small" icon={<UserOutlined />} src={item1.picurl} />
                                                                        </div>
                                                                    </Tooltip>
                                                                )) : ''}

                                                            </Space>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) : null}
                                        </div>
                                    </div>
                                    <div className='itemlist'>
                                        <div className='title'>
                                            <div className='l'>
                                                <div className='p1'>等待</div>
                                                <div className='p2 p2_3'>{this.state.condition.gzxlist3 ? this.state.condition.gzxlist3.length : 0}</div>
                                            </div>
                                            <div className='r'>
                                                <Addgzx type="3" typename="等待" refresh={this.getdata.bind(this)} pid={this.state.condition.id} />
                                            </div>
                                        </div>
                                        <div
                                            className="list"
                                        >
                                            {this.state.condition.gzxlist3 ? this.state.condition.gzxlist3.map((item, index) => (
                                                <div
                                                    className="item"
                                                    key={item.name}
                                                >
                                                    <div className='p1'>
                                                        <Gzxview id={item.id} name={item.name} refresh={this.getdata.bind(this)} type="3" typename="等待" />

                                                    </div>
                                                    <div className='p2'>
                                                        <TagsOutlined /> {item.protitle}
                                                    </div>
                                                    <div className='p3'>
                                                        <Space wrap>
                                                            {item.ulist ? item.ulist.map((item1, index1) => (
                                                                <div className={'bqitem ' + (item1.status == 1 ? 'iconfont hover' : '')} key={index}>{item1.msg}</div>
                                                            )) : ''}
                                                        </Space>
                                                    </div>
                                                    <div className='p4'>
                                                        <div className='l l1'>
                                                            <ClockCircleOutlined className='biao' />{item.time} {item.iscs == 1 ? <Tag color="red">{item.cs}</Tag> : null}
                                                        </div>
                                                        <div className='r'>
                                                            <Space wrap>
                                                                {item.ulist ? item.ulist.map((item1, index1) => (
                                                                    <Tooltip title={item1.name} key={index}>
                                                                        <div className={'txitem ' + (item1.status == 1 ? 'iconfont hover' : '')}>
                                                                            <Avatar size="small" icon={<UserOutlined />} src={item1.picurl} />
                                                                        </div>
                                                                    </Tooltip>
                                                                )) : ''}

                                                            </Space>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) : null}
                                        </div>
                                    </div>
                                    <div className='itemlist'>
                                        <div className='title'>
                                            <div className='l'>
                                                <div className='p1'>已完成</div>
                                                <div className='p2 p2_4'>{this.state.condition.gzxlist4 ? this.state.condition.gzxlist4.length : 0}</div>
                                            </div>
                                            <div className='r'>
                                                <Addgzx type="4" typename="已完成" refresh={this.getdata.bind(this)} pid={this.state.condition.id} />
                                            </div>
                                        </div>
                                        <div
                                            className="list">
                                            {this.state.condition.gzxlist4 ? this.state.condition.gzxlist4.map((item, index) => (
                                                <div
                                                    className="item"
                                                    key={item.name}
                                                >
                                                    <div className='p1'>
                                                        <Gzxview id={item.id} name={item.name} refresh={this.getdata.bind(this)} type="4" typename="已完成" />
                                                        <div className='r'>{item.isjj == 1 ? <div className='jj'>紧急</div> : ''}</div>
                                                    </div>
                                                    <div className='p2'>
                                                        <TagsOutlined /> {item.protitle}
                                                    </div>
                                                    <div className='p3'>
                                                        <Space wrap>
                                                            {item.ulist ? item.ulist.map((item1, index1) => (
                                                                <div className={'bqitem ' + (item1.status == 1 ? 'iconfont hover' : '')} key={index}>{item1.msg}</div>
                                                            )) : ''}
                                                        </Space>
                                                    </div>
                                                    <div className='p4'>
                                                        <div className='l l1'>
                                                            <ClockCircleOutlined className='biao' />{item.time} {item.iscs == 1 ? <Tag color="red">{item.cs}</Tag> : null}
                                                        </div>
                                                        <div className='r'>
                                                            <Space wrap>
                                                                {item.ulist ? item.ulist.map((item1, index1) => (
                                                                    <Tooltip title={item1.name} key={index}>
                                                                        <div className={'txitem ' + (item1.status == 1 ? 'iconfont hover' : '')}>
                                                                            <Avatar size="small" icon={<UserOutlined />} src={item1.picurl} />
                                                                        </div>
                                                                    </Tooltip>
                                                                )) : ''}

                                                            </Space>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </DragDropContext>

                        </TabPane>
                        <TabPane tab={<>附件{count2 ? '(' + count2 + ')' : ''}</>} key={2}>
                            <div className='fjlist'>
                                <div className='item'>
                                    <div className='fjtitle'><PaperClipOutlined /> 项目附件</div>
                                    <div className='list'>
                                        {this.arrfile(this.state.condition.fjlist1,1)}
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='fjtitle'><PaperClipOutlined /> 工作项附件</div>
                                    <div className='list'>
                                        {this.arrfile(this.state.condition.fjlist2,2)}
                                    </div>
                                </div>
                            </div>
                        </TabPane>
                    </Tabs>
                </Drawer>
            </>
        )
    }
}