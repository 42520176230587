import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Divider, Select, Button, Radio, message, Switch, Modal,Upload,Table,Input, Space,Popconfirm,Tooltip } from 'antd';
import { ZoomInOutlined, UploadOutlined,AreaChartOutlined,DownloadOutlined,SnippetsOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import store from "../store/store.js";
const { Option } = Select;
class List extends React.Component {
    constructor(props) {
        super(props);
        const myDate = new Date();
        this.state = {
            data:props.data?props.data:''
        }
        store.subscribe(() => {
            // console.log('state状态改变了，新状态如下')
            // console.log(store.getState().data.info.type)
        })
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            data:nextProps.data,
        })
    }
    componentDidMount() {
        console.log(this.state.data)
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`请输入`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                搜索
              </Button>
              <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                重置
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
    
    handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      this.setState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
      });
    };
    handleReset = clearFilters => {
      clearFilters();
      this.setState({ searchText: '' });
    };
    delpro(id,e){
      fetch('/Project/del', {
        method: "POST",
        body: JSON.stringify({ 'id': id, })
      })
      .then(res => res.json())
      .then(data => {
          console.log(data)
          if(data.status==1){
            this.props.shuaxin()
          }else{
            message.error(data.msg);
          }
      })
    }
    changeStatus(id,status,e){
      fetch('/Project/changeStatus', {
        method: "POST",
        body: JSON.stringify({ 'id': id,'status':status })
      })
      .then(res => res.json())
      .then(data => {
          console.log(data)
          if(data.status==1){
            this.props.shuaxin()
          }else{
            message.error(data.msg);
          }
      })
    }
    render(){
        var columns = [
            {
                title: '序号',
                dataIndex: 'xuhao',
                width: 70,
                align:'center',
                fixed: 'left',
            },
            {
                title: '项目名称',
                dataIndex: 'title',
                width: 230,
                fixed: 'left',
                ellipsis:true,
                ...this.getColumnSearchProps('title'),
                render: raw => (
                  <Tooltip placement="topLeft" title={raw}>
                    {raw}
                  </Tooltip>
                ),
            },
            {
              title: '建设单位/个人',
              dataIndex: 'jianshe',
              ellipsis:true,
              width: 300,
              render: raw => (
                <Tooltip placement="topLeft" title={raw}>
                  {raw}
                </Tooltip>
              ),
            },
            {
              title: '状态',
              dataIndex: 'status1',
              width: 100,
            },
            {
              title: '合同是否签订',
              dataIndex: 'isqd',
              align:'center',
              width: 120,
            },
            {
              title: '项目签订时间',
              dataIndex: 'qdtime',
              width: 150,
            },
            {
              title: '服务周期',
              dataIndex: 'zhouqi',
              width: 100,
            },
            {
              title: '项目造价（元）',
              dataIndex: 'zaojia',
              width: 150,
            },
            {
              title: '本市/外埠',
              dataIndex: 'city',
              width: 100,
            },
            {
              title: '服务器型号',
              dataIndex: 'xinghao',
              width: 120,
            },
            {
              title: '技术维护服务时间',
              dataIndex: 'fwtime',
              width: 350,
              render: (raw,record) => (
                <><div dangerouslySetInnerHTML={{__html: raw}}></div></>
              )
            },
            {
              title: '项目完成比例%',
              dataIndex: 'wcbl',
              width: 150,
            },
            {
              title: '已付款（元）',
              dataIndex: 'yfk',
              width: 120,
            },
            {
              title: '已付比例%',
              dataIndex: 'yfbl',
              width: 120,
            },
            {
              title: '剩余金额（元）',
              dataIndex: 'syje',
              width: 150,
            },
            {
              title: '维护费用',
              dataIndex: 'whfy',
              children: [
                {
                    title: '技术支持（元）',
                    dataIndex: 'jszc',
                    width: 150,
                },
                {
                    title: '服务器（元）',
                    dataIndex: 'fwq',
                    width: 120,
                },
                {
                    title: '域名（元）',
                    dataIndex: 'ym',
                    width: 120,
                }
              ]
            },
            {
              title: '备注',
              dataIndex: 'beizhu',
              width: 300,
            },
            {
              title: '网站域名',
              dataIndex: 'wzym',
              width: 200,
            },
            {
              title: '备案状态',
              dataIndex: 'bazt',
              width: 100,
            },
            {
              title: '联系人',
              dataIndex: 'lxr',
              children: [
                {
                    title: '姓名',
                    dataIndex: 'xm',
                    width: 120,
                },
                {
                    title: '电话',
                    dataIndex: 'tel',
                    width: 200,
                },
                {
                    title: '邮箱',
                    dataIndex: 'email',
                    width: 200,
                }
              ]
            },
            {
              title: '操作',
              dataIndex: 'caozuo',
              width: 180,
              fixed: 'right',
              render: (raw,record) => (
                <>
                  <Link to={{ pathname: '/proview/'+record.id }}>编辑</Link>
                  <Popconfirm
                    title="确认删除?"
                    onConfirm={this.delpro.bind(this,record.id)}
                    // onCancel={cancel}
                    okText="是"
                    cancelText="否"
                  ><a href="javascript:void(0);" style={{marginLeft:15}} >删除</a></Popconfirm>
                  <a href="javascript:void(0);" onClick={this.changeStatus.bind(this,record.id,record.status==2?1:2)} style={{marginLeft:15}} >{record.status==2?'开启':'停止'}</a>
                </>
              ),
            },
        ]
        return (
            <>
                <Table
                    bordered
                    dataSource={this.state.data?this.state.data:null}
                    columns={columns}
                    scroll={{ x: 2700, y: 'calc(100vh - 400px)'}}
                    rowClassName={(record, index)=> record.status==2?'fc':''}
                />
            </>
        )
    }
        
}
export default List;