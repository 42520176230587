import React from 'react';
import { Divider, Select, Button, Radio, message, Switch, Modal,Upload } from 'antd';
import { ZoomInOutlined, UploadOutlined,AreaChartOutlined,DownloadOutlined,SnippetsOutlined } from '@ant-design/icons';
import EditableTable from '../salary/EditableTable.js';
import Uploadfile from '../salary/uploadfile.js';
import ExportExcel from '../exportjs/xlsxtotal2.js';
import Ygtablelist from '../salary/ygtablelist.js';
import store from "../store/store.js";
// store.dispatch({ type: 'GET_INFO' });
const { Option } = Select;
class Gzlist extends React.Component {
    constructor(props) {
        super(props);
        const myDate = new Date();
        this.state = {
            defaultyear: myDate.getFullYear(),
            defaultmonth: (myDate.getMonth() + 1).toString(),
            isopen: false,
            yearlist:[],
            // company: store.getState().data.info.id != 4 ?1:2,
            company:0,
            companylist: [{ 'id': 1, 'name': '天津炎汉科技有限公司' }, { 'id': 2, 'name': '天津朝夕科技有限公司' }, { 'id': 3, 'name': '天津度尺科技有限公司' },]
        }
        store.subscribe(() => {
            // console.log('state状态改变了，新状态如下')
            // console.log(store.getState().data.info.type)
        })
    }
    handleChange = value => {
        this.setState({
            defaultyear: value,
        })
        this.sxgzlist(value, this.state.defaultmonth);
    }
    comChange = value => {
        this.setState({
            company: value,
        })
    }
    handleSizeChange = e => {
        this.setState({ 
            defaultmonth: e.target.value 
        });
        this.sxgzlist(this.state.defaultyear, e.target.value);
    };
    //筛选全部工资单
    sxgzlist = (year,month) =>{
        fetch('/Salary/index', {
            method: "POST",
            body: JSON.stringify({ 'year': year, 'month': month })
        })
        .then(res => res.json())
        .then(data => {
            // console.log(data)
            this.setState({
                defaultyear: data.year,
                defaultmonth: (data.month).toString(),
                jsid: data.jsid,
                jishu: data.jishu,
                sbjs: data.sbjs,
                gjjs: data.gjjs,
                yearlist: data.yearlist,
                // originData1: data.gzlist.gzlist1,
                // originData2: data.gzlist.gzlist2,
                // originData3: data.gzlist.gzlist3,
                originData: data.gzlist,
            })
        })
    }
    //获取登录账号的工资单
    usergzlist = () => {
        fetch('/Salary/getUserGzList', {
            method: "POST",
            body: JSON.stringify({ 'id': store.getState().data.info.id })
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    originData: data.gzlist,
                })
            })
    }
    componentDidMount() {
        // this.props.GET_INFO();
        this.setState(store.getState())
        if (store.getState().data.info.type == 1) {
            this.sxgzlist();
        }
        if (store.getState().data.info.type == 2) {
            this.usergzlist();
        }
    }
    arryear(){
        const arr = this.state.yearlist
        return (
            arr.map((list, index) =>
                <Option value={list.num} key={index}>{list.num + '年'}</Option>
            )
        )
    }
    arrcom() {
        const arr = this.state.companylist
        return (
            arr.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    daoruChange = (info) => {
        if (info.file.status === 'done') {
            // console.log(info.file.response)
            message.success(`${info.file.name} 上传成功`);
            this.sxgzlist(this.state.defaultyear,this.state.defaultmonth);
        }
    }
    caiji = () => {
        fetch('/Email/getzr2', {
            method: "POST",
            body: JSON.stringify({'year':this.state.defaultyear,'month':this.state.defaultmonth})
        })
            .then(res => res.json())
            .then(data => {
                this.sxgzlist(this.state.defaultyear, this.state.defaultmonth);
            })
    }
    render(){
        const props = {
            action:"/Salary/daoru",
            data:{'year': this.state.defaultyear, 'month': this.state.defaultmonth,'company': this.state.company},
            onChange:this.daoruChange,
            showUploadList:false,
        }
        return (
            <div>
                    {/* <div style={{ marginBottom: 15 }}>
                        {store.getState().data.info.id != 4 ?
                        <Select value={this.state.company} onChange={this.comChange.bind(this)}>
                            {this.arrcom()}
                        </Select>
                        :null}
                        {this.state.company==1?
                                <Button type="primary" style={{ marginLeft: 15 }} onClick={() => ExportExcel(this.state.originData1, '', 1, this.state.jishu)} icon={<UploadOutlined />}  >导出</Button>
                            :
                            null
                        }
                        {this.state.company == 2 ?
                                <Button type="primary" style={{ marginLeft: 15 }} onClick={() => ExportExcel(this.state.originData2, '', 1, this.state.jishu)} icon={<UploadOutlined />} >导出</Button>
                            :
                            null
                        }
                        {this.state.company == 3 ?
                                <Button type="primary" style={{ marginLeft: 15 }} onClick={() => ExportExcel(this.state.originData3, '', 1, this.state.jishu)} icon={<UploadOutlined />} >导出</Button>
                            :
                            null
                        }
                        <Upload  {...props} >
                                <Button type="primary" style={{ marginLeft: 15 }} icon={<DownloadOutlined />} >导入</Button>
                        </Upload>
                    </div> */}
                    {store.getState().data.info.type == 1 ?
                    <div style={{marginBottom:15}}>
                        <Select value={this.state.defaultyear} style={{ width: 120,marginRight:15 }} onChange={this.handleChange.bind(this)}>
                            {this.arryear()}
                        </Select>
                        <Radio.Group value={this.state.defaultmonth} onChange={this.handleSizeChange.bind(this)} buttonStyle="solid">
                            {
                                this.state.defaultyear!='2020' ? 
                                    <>
                                        <Radio.Button value="1">1月</Radio.Button>
                                        <Radio.Button value="2">2月</Radio.Button>
                                        <Radio.Button value="3">3月</Radio.Button>
                                        <Radio.Button value="4">4月</Radio.Button>
                                        <Radio.Button value="5">5月</Radio.Button>
                                        <Radio.Button value="6">6月</Radio.Button>
                                        <Radio.Button value="7">7月</Radio.Button>
                                        <Radio.Button value="8">8月</Radio.Button>
                                        
                                    </>
                                    : null
                            }
                            <Radio.Button value="9">9月</Radio.Button>
                            <Radio.Button value="10">10月</Radio.Button>
                            <Radio.Button value="11">11月</Radio.Button>
                            <Radio.Button value="12">12月</Radio.Button>
                            {/* <Radio.Button value="13">统计</Radio.Button> */}
                        </Radio.Group>

                        <Upload  {...props} >
                                <Button type="primary" style={{ marginLeft: 15 }} icon={<DownloadOutlined />} >导入</Button>
                        </Upload>
                        {this.state.defaultmonth!=13?
                        <Button type="primary" onClick={this.caiji.bind(this)} style={{ marginLeft: 15 }} icon={<SnippetsOutlined />} >采集</Button>
                        :null
                        }
                    </div>
                        :
                        null
                    }

                    <>
                        <EditableTable  gzlist={this.state.originData} type={store.getState().data.info.type} year={this.state.defaultyear} month={this.state.defaultmonth} istj={this.state.defaultmonth} />
                        {/* {this.state.company==1 ?
                            <EditableTable  gzlist={this.state.originData1} />
                            :
                            null
                        }
                        {this.state.company == 2 ?
                            <EditableTable  gzlist={this.state.originData2} />
                            :
                            null
                        }
                        {this.state.company == 3 ?
                            <EditableTable  gzlist={this.state.originData3} />
                            :
                            null
                        } */}
                    </>
            </div>
        )
    }
        
}
export default Gzlist;