import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Form, Modal, Checkbox, Space, Tooltip, DatePicker, TimePicker, Select, message, Switch, Popconfirm } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, UploadOutlined, CopyOutlined } from '@ant-design/icons';
import Tongji from "../daka/tongji.js";
import moment from 'moment';
import store from "../store/store.js";
const { Option } = Select;
class Daka extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                { title: '姓名', width: 150, dataIndex: 'name', ellipsis: { showTitle: false }, key: 'name', ...this.getColumnSearchProps('name'), fixed: 'left', render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>) },
            ],
            dataSource: [

            ],
            starttime: '',
            endtime: '',
            company: 2,
            isfc: false,
            isModalVisible: false,
            dakainfo: {},
            arrstatus: [],
            ulist: [],
            arruser: []
        }
        store.subscribe(() => {
            // console.log('state状态改变了，新状态如下')
            // console.log(store.getState().data.info.type)
        })
    }
    componentDidMount() {
        this.getDakaList()
    }
    arrmap(arr) {
        return (
            arr.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    getDakaList(dayin = 0, save = 0, e) {
        let that = this
        console.log(dayin);
        fetch('/Daka/index', {
            method: "POST",
            body: JSON.stringify({
                startdate: this.state.starttime,
                enddate: this.state.endtime,
                isfc: this.state.isfc,
                company: this.state.company,
                ulist: this.state.ulist,
                export: dayin,
                save: save
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                if (dayin == 0) {
                    var newColumns = [
                        { title: '姓名', width: 150, dataIndex: 'name', ellipsis: { showTitle: false }, key: 'name', ...this.getColumnSearchProps('name'), fixed: 'left', render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>) },
                    ];
                    var columns = data.columns.concat()
                    columns.forEach(item => {
                        item['render'] = (content, record) => {
                            if (content) {
                                return (
                                    <Tooltip placement="topLeft" onClick={store.getState().data.info.id != 4 ? content['islz'] == 0 ? this.getdakainfo.bind(this, content, '') : null : null} title={<p>{content['address'] ? content['address'][0] : ''}<br />{content['address'] ? content['address'][1] : ''}</p>}> <div dangerouslySetInnerHTML={{ __html: (content['time'] ? content['time'][0] ? content['time'][0] : ' ' : ' ') + '<br />' + (content['time'] ? content['time'][1] ? content['time'][1] : ' ' : ' ') }}></div></Tooltip>
                                )
                            } else {
                                return (
                                    <div onClick={store.getState().data.info.id != 4 ? this.getdakainfo.bind(this, '', record) : null} style={{ width: '100%', height: '100%' }}>&nbsp;</div>
                                )
                            }
                        }
                        newColumns.push(item)
                    });
                    that.setState({
                        columns: newColumns,
                        dataSource: data.dataSource,
                        arrstatus: data.arrstatus,
                        firsttime: data.firsttime,
                        arruser: data.arruser,
                    })
                    if (data.ulist.length) {
                        that.setState({
                            ulist: data.ulist
                        })
                    }
                    if (that.state.starttime == '') {
                        that.setState({
                            starttime: data.monfirst
                        })
                    }
                    if (that.state.endtime == '') {
                        that.setState({
                            endtime: data.monend
                        })
                    }
                } else {
                    // console.log(data);
                    try {
                        var elemIF = document.createElement("iframe");
                        elemIF.src = data.url;
                        elemIF.style.display = "none";
                        document.body.appendChild(elemIF);
                    } catch (e) {
                        alert("下载异常！");
                    }
                }
            })
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`查询条件`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        搜索
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        清除
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    onChangePicker(name, date, dateString) {
        // console.log(name,date, dateString);
        this.setState({
            [name]: dateString
        })
    }
    handleTypeChange = (name, value) => {
        // console.log(name, value);
        this.setState({
            [name]: value
        })
    };
    onChangePicker2(name, date, dateString) {
        // console.log(name, date, dateString);
        var newObj = this.state.dakainfo;
        newObj[name] = dateString
        this.setState({
            dakainfo: newObj
        })
    }
    disabledDate = (current) => {
        // Can not select days before today and today
        // console.log(this)
        if (this.state.firsttime) {
            return current && current < moment(this.state.firsttime).endOf('day');
        } else {
            return false
        }
    }
    onChangeCheckbox = (e) => {
        // console.log(`checked = ${e.target.checked}`);
        this.setState({
            isfc: e.target.checked
        })
    }
    onChangeInput = (name, e) => {
        // console.log(name,e.target.value)
        var newObj = this.state.dakainfo;
        newObj[name] = e.target.value
        this.setState({
            dakainfo: newObj
        })
    }
    onChangeSwitch = (name, checked) => {
        // console.log(name, checked);
        var newObj = this.state.dakainfo;
        newObj[name] = checked
        this.setState({
            dakainfo: newObj
        })
    }
    handleChangeSelect = (name, value) => {
        // console.log(name, value)
        var newObj = this.state.dakainfo;
        newObj[name] = value
        this.setState({
            dakainfo: newObj
        })
    }
    handleChangeSelect2 = (name, value) => {
        // console.log(name, value)
        var newObj = this.state.dakainfo;

        this.setState({
            [name]: value
        })
    }
    //获取打卡信息
    getdakainfo = (arr1, arr2, e) => {
        let that = this
        that.setState({
            isModalVisible: true
        })
        var dakainfo = that.state.dakainfo
        if (arr1) {
            // console.log(arr1)
            fetch('/Daka/getdakainfo', {
                method: "POST",
                body: JSON.stringify({
                    id: arr1.id,
                })
            })
                .then(res => res.json())
                .then(data => {
                    // console.log(data);
                    dakainfo['uid'] = arr1.uid;
                    dakainfo['name'] = arr1.name;
                    dakainfo['id'] = arr1.id;
                    dakainfo['riqi'] = data.riqi;
                    dakainfo['time1'] = data.time1;
                    dakainfo['time2'] = data.time2;
                    dakainfo['address1'] = data.address1;
                    dakainfo['address2'] = data.address2;
                    dakainfo['status1'] = parseInt(data.status1);
                    dakainfo['status2'] = parseInt(data.status2);
                    dakainfo['iswq1'] = data.iswq1;
                    dakainfo['iswq2'] = data.iswq2;
                    that.setState({
                        dakainfo: dakainfo
                    })
                })
        }
        if (arr2) {
            // console.log(arr2)
            dakainfo['uid'] = arr2.key;
            dakainfo['name'] = arr2.name;
            that.setState({
                dakainfo: dakainfo
            })

        }
    }
    getdakazr(){
        let that = this
        fetch('/Email/getdakazr', {
            method: "POST",
            body: JSON.stringify({
                starttime: this.state.starttime,
                endtime: this.state.endtime,
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                if (data.status == 1) {
                    message.success(data.msg);
                    that.getDakaList();
                } else {
                    message.error(data.msg);
                }
            })
    }
    //提交打卡信息
    handleOk = (e) => {
        let data = this.state.dakainfo;
        let that = this
        fetch('/Daka/doedit', {
            method: "POST",
            body: JSON.stringify({
                data: data,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.status == 1) {
                    message.success(data.msg);
                    that.getDakaList();
                    that.handleCancel();
                } else {
                    message.error(data.msg);
                }
            })
    }
    handleCancel = () => {
        this.setState({
            isModalVisible: false,
            dakainfo: {}
        })
    }

    render() {
        const layout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
        };
        return <>
            <div style={{ marginBottom: 15 }}>
                <DatePicker onChange={this.onChangePicker.bind(this, 'starttime')} value={this.state.starttime ? moment(this.state.starttime) : ''} placeholder="开始时间" style={{ marginRight: 15 }} />
                <DatePicker onChange={this.onChangePicker.bind(this, 'endtime')} value={this.state.endtime ? moment(this.state.endtime) : ''} placeholder="结束时间" style={{ marginRight: 15 }} disabledDate={this.disabledDate} />
                {/* {store.getState().data.info.id != 4 ?
                    <Select value={this.state.company} style={{ width: 120, marginRight: 15 }} onChange={this.handleTypeChange.bind(this,'company')}>
                        <Option value={1} key={1}>炎汉科技</Option>
                        <Option value={2} key={2}>朝夕科技</Option>
                        <Option value={3} key={3}>度尺科技</Option>
                    </Select>
                :null} */}
                <Select
                    mode="multiple"
                    allowClear
                    style={{ width: 350, marginRight: 15 }}
                    placeholder="请选择人名单"
                    maxTagCount={3}
                    value={this.state.ulist}
                    onChange={this.handleChangeSelect2.bind(this, 'ulist')}
                >
                    {this.arrmap(this.state.arruser)}
                </Select>
                <Checkbox checked={this.state.isfc} onChange={this.onChangeCheckbox.bind(this)} style={{ marginRight: 15 }}>是否显示封存</Checkbox>
                <Button type="primary" onClick={this.getDakaList.bind(this, 0, 1)} style={{ marginRight: 15 }}>搜索</Button>
                <Button type="primary" onClick={this.getDakaList.bind(this, 1, 0)} icon={<UploadOutlined />}>导出</Button>
                <Tongji ulist={this.state.ulist} starttime={this.state.starttime} endtime={this.state.endtime} />
                <Popconfirm
                    title="覆盖当前时间段张瑞的打卡记录?"
                    onConfirm={this.getdakazr.bind(this)}
                    okText="确定"
                    cancelText="取消"
                >
                    <Button type="primary" style={{ marginLeft: 15 }} icon={<CopyOutlined />}>采集</Button>
                </Popconfirm>
            </div>
            <Modal title="编辑" visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>
                <Form
                    {...layout}
                    name="form_in_modal"
                    labelAlign="left"
                >
                    <Form.Item
                        label="姓名"
                    >
                        {this.state.dakainfo.name}
                    </Form.Item>
                    <Form.Item
                        label="日期"
                    >
                        <DatePicker onChange={this.onChangePicker2.bind(this, 'riqi')} value={this.state.dakainfo.riqi ? moment(this.state.dakainfo.riqi) : ''} placeholder="打卡日期" style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        label="时间(上班)"
                    >
                        <TimePicker onChange={this.onChangePicker2.bind(this, 'time1')} value={this.state.dakainfo.time1 ? moment(this.state.dakainfo.time1, 'HH:mm') : ''} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        label="地点(上班)"
                    >
                        <Input onChange={this.onChangeInput.bind(this, 'address1')} value={this.state.dakainfo.address1 ? this.state.dakainfo.address1 : ''} />
                    </Form.Item>
                    <Form.Item
                        label="状态(上班)"
                    >
                        <Select value={this.state.dakainfo.status1 ? this.state.dakainfo.status1 : null} style={{ width: '100%' }} onChange={this.handleChangeSelect.bind(this, 'status1')} placeholder="请选择">
                            {this.arrmap(this.state.arrstatus)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="是否外勤(上班)"
                    >
                        <Switch checked={this.state.dakainfo.iswq1 == 1 ? true : false} onChange={this.onChangeSwitch.bind(this, 'iswq1')} />
                    </Form.Item>
                    <Form.Item
                        label="时间(下班)"
                    >
                        <TimePicker onChange={this.onChangePicker2.bind(this, 'time2')} value={this.state.dakainfo.time2 ? moment(this.state.dakainfo.time2, 'HH:mm') : ''} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        label="地点(下班)"
                    >
                        <Input onChange={this.onChangeInput.bind(this, 'address2')} value={this.state.dakainfo.address2 ? this.state.dakainfo.address2 : ''} />
                    </Form.Item>
                    <Form.Item
                        label="状态(下班)"
                    >
                        <Select value={this.state.dakainfo.status2 ? this.state.dakainfo.status2 : null} style={{ width: '100%' }} onChange={this.handleChangeSelect.bind(this, 'status2')} placeholder="请选择">
                            {this.arrmap(this.state.arrstatus)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="是否外勤(下班)"
                    >
                        <Switch checked={this.state.dakainfo.iswq2 == 1 ? true : false} onChange={this.onChangeSwitch.bind(this, 'iswq2')} />
                    </Form.Item>
                </Form>
            </Modal>
            <Table
                dataSource={this.state.dataSource}
                columns={this.state.columns}
                bordered
                pagination={false}
                scroll={{ x: this.state.columns.length > 5 ? 2500 : 0, y: 'calc(100vh - 300px)' }}
            />
        </>

    }
}
export default Daka;