import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { DatePicker, Space, Select, Input, Button, Table, Modal, Divider, Row, Col, Form, Radio, Upload, message, Drawer, Tag, Avatar, Tabs, Popconfirm, Tooltip } from 'antd';
import store from "../store/store.js";
import { DownloadOutlined, CalendarOutlined, RedoOutlined, UserOutlined, FormOutlined, PlusOutlined, TagsOutlined, ClockCircleOutlined } from '@ant-design/icons'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Addgzx from "../xiangmu/addgzx";
import Addrz from "./addrz";
import Gzxview from "../xiangmu/gzxview";
import moment from 'moment';
const { Option } = Select;
const { Dragger } = Upload;
const { TextArea } = Input;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
export default class View extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                gzxlist1: [],
                gzxlist2: [],
                gzxlist3: [],
                gzxlist4: [],
            },
            newcondition: {

            },
            visible: false,
            loading: false,
            loading2: false
        }
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.isLogin)
        })
    }
    componentDidMount() {
        this.getdata()
    }
    getdata() {
        let that = this
        let arr = that.state.newcondition
        fetch('/Gzx/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                that.setState({
                    condition: data.gzxlist,
                    user_react:data.user_react,
                })
            })
    }
    // 拖拽调整顺序
    onDragEnd = (result) => {
        let that = this
        // if (store.getState().data.info.type != 1) {
        //     message.error('无调整权限');
        //     return;
        // }
        // console.log(result)
        const sourceIndex = result.source.index;
        const sourceDroppableId = result.source.droppableId;
        if (result.destination) {
            const destinationIndex = result.destination.index;
            const destinationDroppableId = result.destination.droppableId;
            if (sourceIndex === destinationIndex && sourceDroppableId === destinationDroppableId) {
                return;
            }
            if (sourceDroppableId !== destinationDroppableId) {
                const sourcelist = that.state.condition[sourceDroppableId].concat();
                const [draggedItem] = sourcelist.splice(sourceIndex, 1);
                const destinationlist = that.state.condition[destinationDroppableId].concat();
                destinationlist.splice(destinationIndex, 0, draggedItem);

                let data = Object.assign({}, that.state.condition, { [sourceDroppableId]: sourcelist, [destinationDroppableId]: destinationlist })
                that.setState({
                    condition: data,
                })
            } else {
                const sourcelist = that.state.condition[sourceDroppableId].concat();
                const [draggedItem] = sourcelist.splice(sourceIndex, 1);
                sourcelist.splice(destinationIndex, 0, draggedItem);
                let data = Object.assign({}, that.state.condition, { [sourceDroppableId]: sourcelist })
                that.setState({
                    condition: data,
                })
            }
        }
        setTimeout(function () {
            fetch('/Gzx/sortchange', {
                method: "POST",
                body: JSON.stringify(that.state.condition)
            })
                .then(res => res.json())
                .then(data => {
                    console.log(data)
                })
        })
    };
    gzxWc(gzx_u_id, e) {
        console.log(gzx_u_id)
        let that = this
        fetch('/Gzx/gzxWc', {
            // post提交
            method: "POST",
            body: JSON.stringify({
                id: gzx_u_id
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                if (data.status == 1) {
                    message.success(data.msg)
                    that.getdata()
                } else {
                    message.error(data.msg)
                }
            })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
        if(name=='uid'){
            setTimeout(()=>that.getdata())
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }
    onTimeChange(name, dates, dateStrings) {
        let that = this
        console.log(name, dates, dateStrings)
        if(name=='date'){
            let data = Object.assign({}, this.state.newcondition, { date: dateStrings[0],date2: dateStrings[1] })
            this.setState({
                newcondition: data
            })
            setTimeout(()=>that.getdata())
        }
    }
    render() {
        const total = this.state.condition.gzxlist1.length + this.state.condition.gzxlist2.length + this.state.condition.gzxlist3.length + this.state.condition.gzxlist4.length
        return (
            <>
                <div className='gzxtop'>
                    <div className='l'>
                        {/* <div className='p1'>日期</div> */}
                        <div className='p2'>
                            <Space wrap>
                                <RangePicker placeholder={["开始时间","结束时间"]} onChange={this.onTimeChange.bind(this, 'date')} value={[this.state.newcondition.date ? moment(this.state.newcondition.date,'YYYY/MM/DD') : null,this.state.newcondition.date2 ? moment(this.state.newcondition.date2,'YYYY/MM/DD') : null]} format='YYYY/MM/DD' />
                                {store.getState().data.info.type == 1 ?
                                    <Select
                                        className='select'
                                        style={{ width: 100 }}
                                        placeholder="参与人"
                                        value={this.state.newcondition.uid ? this.state.newcondition.uid : null}
                                        onChange={this.onSelChange.bind(this, 'uid')}
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    >
                                        {this.arrsel(this.state.user_react)}
                                    </Select>
                                    : null
                                }
                            </Space>
                        </div>
                        <div className='p3'>{total}项安排</div>
                    </div>
                    <div className='r'>
                        <div className='item' onClick={this.getdata.bind(this)}>
                            <RedoOutlined />刷新
                        </div>
                        {store.getState().data.info.type != 1 ?
                            <Addrz />
                            : null
                        }
                    </div>
                </div>
                <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                    <div className="tuozhuai">
                        <Droppable droppableId="gzxlist1" direction="vertical">
                            {(provided) => (
                                <div className='itemlist'>
                                    <div className='title'>
                                        <div className='l'>
                                            <div className='p1'>紧急</div>
                                            <div className='p2 p2_1'>{this.state.condition.gzxlist1 ? this.state.condition.gzxlist1.length : 0}</div>
                                        </div>
                                        <div className='r'>
                                            <Addgzx type="1" typename="紧急" refresh={this.getdata.bind(this)} />
                                        </div>
                                    </div>
                                    <div
                                        className="list"
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}>
                                        {this.state.condition.gzxlist1 ? this.state.condition.gzxlist1.map((item, index) => (
                                            <Draggable draggableId={item.name} index={index} key={item.id}>
                                                {(provided) => (
                                                    <div
                                                        className="item"
                                                        key={item.name}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}>
                                                        <div className='p1'>
                                                            <Space>
                                                                <Gzxview id={item.id} name={item.name} refresh={this.getdata.bind(this)} type="1" typename="紧急" />
                                                                {item.aid == store.getState().data.info.id ?
                                                                    <Addgzx id={item.id} title={<FormOutlined style={{ color: '#f00' }} />} type="1" typename="紧急" refresh={this.getdata.bind(this)} />
                                                                    :
                                                                    null
                                                                }
                                                            </Space>
                                                            {item.wc_click == 1 ?
                                                                <div className='r'>
                                                                    <Popconfirm
                                                                        title="确认完成?"
                                                                        onConfirm={this.gzxWc.bind(this, item.gzx_u_id)}
                                                                        okText="确定"
                                                                        cancelText="取消"
                                                                    >
                                                                        <div className='ok'>完成</div>
                                                                    </Popconfirm>
                                                                </div>
                                                                : null
                                                            }
                                                        </div>
                                                        <div className='p2'>
                                                            <TagsOutlined /> {item.protitle}
                                                        </div>
                                                        <div className='p3'>
                                                            <Space wrap>
                                                                {item.ulist ? item.ulist.map((item1, index1) => (
                                                                    <div className={'bqitem ' + (item1.status == 1 ? 'iconfont hover' : '')} key={index}>{item1.msg}</div>
                                                                )) : ''}
                                                            </Space>
                                                        </div>
                                                        <div className='p4'>
                                                            <div className='l l1'>
                                                                <ClockCircleOutlined className='biao' />{item.time} {item.iscs == 1 ? <Tag color="red">{item.cs}</Tag> : null}
                                                            </div>
                                                            <div className='r'>
                                                                <Space wrap>
                                                                    {item.ulist ? item.ulist.map((item1, index1) => (
                                                                        <Tooltip title={item1.name} key={index1}>
                                                                            <div className={'txitem ' + (item1.status == 1 ? 'iconfont hover' : '')}>
                                                                                <Avatar size="small" icon={<UserOutlined />} src={item1.picurl} />
                                                                            </div>
                                                                        </Tooltip>
                                                                    )) : ''}

                                                                </Space>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        )) : null}
                                        {provided.placeholder}
                                    </div>
                                </div>
                            )}
                        </Droppable>
                        <Droppable droppableId="gzxlist2" direction="vertical">
                            {(provided) => (
                                <div className='itemlist'>
                                    <div className='title'>
                                        <div className='l'>
                                            <div className='p1'>进行中</div>
                                            <div className='p2 p2_2'>{this.state.condition.gzxlist2 ? this.state.condition.gzxlist2.length : 0}</div>
                                        </div>
                                        <div className='r'>
                                            <Addgzx type="2" typename="进行中" refresh={this.getdata.bind(this)} />
                                        </div>
                                    </div>
                                    <div
                                        className="list"
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}>
                                        {this.state.condition.gzxlist2 ? this.state.condition.gzxlist2.map((item, index) => (
                                            <Draggable draggableId={item.name} index={index} key={item.id}>
                                                {(provided) => (
                                                    <div
                                                        className="item"
                                                        key={item.name}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}>
                                                        <div className='p1'>
                                                            <Space>
                                                                <Gzxview id={item.id} name={item.name} refresh={this.getdata.bind(this)} type="2" typename="进行中" />
                                                                {item.aid == store.getState().data.info.id ?
                                                                    <Addgzx id={item.id} title={<FormOutlined style={{ color: '#f00' }} />} type="2" typename="进行中" refresh={this.getdata.bind(this)} />
                                                                    :
                                                                    null
                                                                }
                                                            </Space>
                                                            {item.wc_click == 1 ?
                                                                <div className='r'>
                                                                    <Popconfirm
                                                                        title="确认完成?"
                                                                        onConfirm={this.gzxWc.bind(this, item.gzx_u_id)}
                                                                        okText="确定"
                                                                        cancelText="取消"
                                                                    >
                                                                        <div className='ok'>完成</div>
                                                                    </Popconfirm>
                                                                </div>
                                                                : null
                                                            }
                                                        </div>
                                                        <div className='p2'>
                                                            <TagsOutlined /> {item.protitle}
                                                        </div>
                                                        <div className='p3'>
                                                            <Space wrap>
                                                                {item.ulist ? item.ulist.map((item1, index1) => (
                                                                    <div className={'bqitem ' + (item1.status == 1 ? 'iconfont hover' : '')} key={index}>{item1.msg}</div>
                                                                )) : ''}
                                                            </Space>
                                                        </div>
                                                        <div className='p4'>
                                                            <div className='l l1'>
                                                                <ClockCircleOutlined className='biao' />{item.time} {item.iscs == 1 ? <Tag color="red">{item.cs}</Tag> : null}
                                                            </div>
                                                            <div className='r'>
                                                                <Space wrap>
                                                                    {item.ulist ? item.ulist.map((item1, index1) => (
                                                                        <Tooltip title={item1.name} key={index1}>
                                                                            <div className={'txitem ' + (item1.status == 1 ? 'iconfont hover' : '')}>
                                                                                <Avatar size="small" icon={<UserOutlined />} src={item1.picurl} />
                                                                            </div>
                                                                        </Tooltip>
                                                                    )) : ''}

                                                                </Space>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        )) : null}
                                        {provided.placeholder}
                                    </div>
                                </div>
                            )}
                        </Droppable>
                        <Droppable droppableId="gzxlist3" direction="vertical">
                            {(provided) => (
                                <div className='itemlist'>
                                    <div className='title'>
                                        <div className='l'>
                                            <div className='p1'>等待</div>
                                            <div className='p2 p2_3'>{this.state.condition.gzxlist3 ? this.state.condition.gzxlist3.length : 0}</div>
                                        </div>
                                        <div className='r'>
                                            <Addgzx type="3" typename="等待" refresh={this.getdata.bind(this)} />
                                        </div>
                                    </div>
                                    <div
                                        className="list"
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}>
                                        {this.state.condition.gzxlist3 ? this.state.condition.gzxlist3.map((item, index) => (
                                            <Draggable draggableId={item.name} index={index} key={item.id}>
                                                {(provided) => (
                                                    <div
                                                        className="item"
                                                        key={item.name}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}>
                                                        <div className='p1'>
                                                            <Space>
                                                                <Gzxview id={item.id} name={item.name} refresh={this.getdata.bind(this)} type="3" typename="等待" />
                                                                {item.aid == store.getState().data.info.id ?
                                                                    <Addgzx id={item.id} title={<FormOutlined style={{ color: '#f00' }} />} type="3" typename="等待" refresh={this.getdata.bind(this)} />
                                                                    :
                                                                    null
                                                                }
                                                            </Space>
                                                            {/* {item.wc_click == 1 ?
                                                                <div className='r'>
                                                                    <Popconfirm
                                                                        title="确认完成?"
                                                                        onConfirm={this.gzxWc.bind(this, item.gzx_u_id)}
                                                                        okText="确定"
                                                                        cancelText="取消"
                                                                    >
                                                                        <div className='ok'>完成</div>
                                                                    </Popconfirm>
                                                                </div>
                                                                : null
                                                            } */}
                                                        </div>
                                                        <div className='p2'>
                                                            <TagsOutlined /> {item.protitle}
                                                        </div>
                                                        <div className='p3'>
                                                            <Space wrap>
                                                                {item.ulist ? item.ulist.map((item1, index1) => (
                                                                    <div className={'bqitem ' + (item1.status == 1 ? 'iconfont hover' : '')} key={index}>{item1.msg}</div>
                                                                )) : ''}
                                                            </Space>
                                                        </div>
                                                        <div className='p4'>
                                                            <div className='l l1'>
                                                                <ClockCircleOutlined className='biao' />{item.time} {item.iscs == 1 ? <Tag color="red">{item.cs}</Tag> : null}
                                                            </div>
                                                            <div className='r'>
                                                                <Space wrap>
                                                                    {item.ulist ? item.ulist.map((item1, index1) => (
                                                                        <Tooltip title={item1.name} key={index1}>
                                                                            <div className={'txitem ' + (item1.status == 1 ? 'iconfont hover' : '')}>
                                                                                <Avatar size="small" icon={<UserOutlined />} src={item1.picurl} />
                                                                            </div>
                                                                        </Tooltip>
                                                                    )) : ''}

                                                                </Space>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        )) : null}
                                        {provided.placeholder}
                                    </div>
                                </div>
                            )}
                        </Droppable>
                        <div className='itemlist'>
                            <div className='title'>
                                <div className='l'>
                                    <div className='p1'>今日完成</div>
                                    <div className='p2 p2_4'>{this.state.condition.gzxlist4 ? this.state.condition.gzxlist4.length : 0}</div>
                                </div>
                                <div className='r'>
                                    <Addgzx type="4" typename="今日完成" refresh={this.getdata.bind(this)} />
                                </div>
                            </div>
                            <div
                                className="list">
                                {this.state.condition.gzxlist4 ? this.state.condition.gzxlist4.map((item, index) => (
                                    <div
                                        className="item"
                                        key={item.name}
                                    >
                                        <div className='p1'>
                                            <Gzxview id={item.id} name={item.name} type="4" typename="今日完成" refresh={this.getdata.bind(this)} />
                                            <div className='r'>{item.isjj == 1 ? <div className='jj'>紧急</div> : ''}</div>
                                        </div>
                                        <div className='p2'>
                                            <TagsOutlined /> {item.protitle}
                                        </div>
                                        <div className='p3'>
                                            <Space wrap>
                                                {item.ulist ? item.ulist.map((item1, index1) => (
                                                    <div className={'bqitem ' + (item1.status == 1 ? 'iconfont hover' : '')} key={index}>{item1.msg}</div>
                                                )) : ''}
                                            </Space>
                                        </div>
                                        <div className='p4'>
                                            <div className='l l1'>
                                                <ClockCircleOutlined className='biao' />{item.time} {item.iscs == 1 ? <Tag color="red">{item.cs}</Tag> : null}
                                            </div>
                                            <div className='r'>
                                                <Space wrap>
                                                    {item.ulist ? item.ulist.map((item1, index1) => (
                                                        <Tooltip title={item1.name} key={index1}>
                                                            <div className={'txitem ' + (item1.status == 1 ? 'iconfont hover' : '')}>
                                                                <Avatar size="small" icon={<UserOutlined />} src={item1.picurl} />
                                                            </div>
                                                        </Tooltip>
                                                    )) : ''}

                                                </Space>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </DragDropContext>
            </>
        )
    }
}