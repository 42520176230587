import React from 'react';
import { Upload, message, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

class Uploadfile extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            year:'',
            month:'',
        }
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            year:nextProps.year,
            month:nextProps.month
        })
    }
    handleChange = info => {
        const { status } = info.file;
        if (status !== 'uploading') {
            // console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            // console.log(info)
            if(info.file.response.info){
                message.success(`${info.file.response.info}`);return;
            }
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    };
    render() {
        // function getls() {
        //     console.log(this.props);
        //     this.props.getls(this.state.year, this.state.month);
        // }
        const props = {
            name: 'file',
            data: { 'type': this.props.type ,'year':this.state.year,'month':this.state.month},
            multiple: false,
            showUploadList:false,
            action: this.props.action,
            onChange: this.handleChange,
            
        };
        return (
            <Upload {...props}>
                <Button icon={<UploadOutlined />} style={{ marginLeft: 15 }}>上传</Button>
            </Upload>
        ) 
    }
}
export default Uploadfile;

