import React from 'react';
import { Upload, message, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
class UploadFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: props.defaultlist ? props.defaultlist:[],
            id: props.fjid
        }
    }
    componentDidMount() {
        
    }
    componentWillReceiveProps(nextProps) {
        // console.log(nextProps)
        this.setState({
            fileList: nextProps.defaultlist ? nextProps.defaultlist : [],
            id: nextProps.fjid
        })
    }
    handleChange = info => {
        let fileList = [...info.fileList];
        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(0);

        // 2. Read from response and show file link
        fileList = fileList.map(file => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;
                file.uid = parseInt(file.response.id);
            }
            return file;
        });
        var fileIdList=[]
        fileList.map(file => {
            fileIdList.push(file.uid);
        }); 
        if (info.file.status != 'uploading') {
            // console.log(fileList)
            this.props.getfileIdList(fileIdList,this.state.id)
            this.setState({ fileList });
        }
    };
    render() {
        const props = {
            name: 'file',
            action: this.props.action,
            listType:"picture-card",
            showUploadList:{
                showPreviewIcon:false,
                showDownloadIcon: true,
                showRemoveIcon: this.props.disabled === true ? false : true
            },
            headers: {
                authorization: 'authorization-text',
            },
            onChange: this.handleChange,
        };
        return (
            <Upload {...props} fileList={this.state.fileList} fileIdList={this.state.fileIdList} >
                {this.props.disabled === true ? null : <PlusOutlined />}
            </Upload>
        )
    }
}
export default UploadFile;