import React from 'react';
import { Input, Select, Button, Popconfirm, Typography} from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import UploadFile from './Upload.js';
import moment from 'moment';
import store from "../store/store.js";
const { Paragraph } = Typography;
const { TextArea } = Input;
class Cominfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: 0,
            id: store.getState().data.info.id != 4 ? 1:2,
            fujian:[],
            zhanghao: [
                {
                    pingtai: '',
                    username: '',
                    password: '',
                    tel: '',
                    email: '',
                }
            ],
        }
        store.subscribe(() => {
            // console.log('state状态改变了，新状态如下')
            // console.log(store.getState().data.info.type)
        })
    }
    componentDidMount() {
        store.getState().data.info.id != 4 ? this.getinfo(1) : this.getinfo(2)
        
    }
    getinfo(id){
        fetch('/Cominfo/index', {
            method: "POST",
            body: JSON.stringify({
                id:id,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data);
                this.setState({
                    title: data.title,
                    code: data.code,
                    address: data.address,
                    faren: data.faren,
                    jyfw: data.jyfw,
                    bank: data.bank,
                    banknumber: data.banknumber,
                    tel: data.tel,
                    fujian: data.fujian,
                    
                    titletime: data.titletime,
                    codetime: data.codetime,
                    addresstime: data.addresstime,
                    farentime: data.farentime,
                    jyfwtime: data.	jyfwtime,
                    banktime: data.banktime,
                    banknumbertime: data.banknumbertime,
                    teltime: data.teltime,
                    zhanghaotime: data.zhanghaotime,
                })
                if (data.zhanghao) {
                    this.setState({
                        zhanghao: data.zhanghao,
                    })
                }else{
                    this.setState({
                        zhanghao: [
                            {
                                pingtai: '',
                                username: '',
                                password: '',
                                tel: '',
                                email: '',
                            }
                        ],
                    })
                }
            })
    }
    isedit() {
        this.setState({
            edit: this.state.edit == 1 ? 0 : 1
        })
    }
    onChange(e) {
        console.log(e.target.name, e.target.value);
        if (!e.target.value) {
            e.target.value='';
        }
        this.setState({
            [e.target.name]: e.target.value
        })
        this.subinfo(e.target.name, e.target.value);
    }
    getfileIdList = (arr,id) => {
        // console.log(arr,id);
        this.subfjinfo(id, 'filelist', arr)
    }
    inputChange(item, index, name, e) {
        // console.log("修改后的值为：", moment(e).format('YYYY-MM-DD HH:mm:ss'));
        // console.log("name：", name);
        var value = e.target.value
        const { zhanghao } = this.state;
        zhanghao[index][name] = value;
        let newArr = zhanghao;
        this.setState({
            zhanghao: newArr
        })
        this.subinfo('zhanghao', newArr);
    }
    delzhanghao(num, e) {
        const { zhanghao } = this.state;
        try {
            zhanghao.forEach((item, index) => {
                if (index == num) {
                    zhanghao[num] = null;
                    throw new Error(num);
                }
            });
        } catch (e) {
            // throw e;
            // console.log(zhanghao.filter(Boolean));
            this.setState({
                zhanghao: zhanghao.filter(Boolean)
            })
        }
        this.subinfo('zhanghao', zhanghao.filter(Boolean));
    }
    addzhanghao() {
        const { zhanghao } = this.state;
        const newinfo = {
            pingtai: '',
            username: '',
            password: '',
            tel: '',
            email: '',
        }
        const newArr = zhanghao.concat(newinfo);
        // console.log(newArr);
        this.setState({
            zhanghao: newArr
        })
        this.subinfo('zhanghao', newArr);
    }

    inputChange2(item, index, name, e) {
        // console.log("修改后的值为：", moment(e).format('YYYY-MM-DD HH:mm:ss'));
        // console.log("name：", name);
        var value = e.target.value
        const { fujian } = this.state;
        fujian[index][name] = value;
        fujian[index]['fujiantime'] = moment(e).format('YYYY-MM-DD');
        let newArr = fujian;
        this.setState({
            fujian: newArr
        })
        this.subfjinfo(fujian[index]['id'],'title',value)
    }
    delfujian(num, e) {
        const { fujian } = this.state;
        // console.log(fujian[num]);
        this.delfj(fujian[num]['id']);
        try {
            fujian.forEach((item, index) => {
                if (index == num) {
                    fujian[num] = null;
                    throw new Error(num);
                }
            });
        } catch (e) {
            // throw e;
            this.setState({
                fujian: fujian.filter(Boolean)
            })
        }
        let fjidlist = new Array();
        fujian.filter(Boolean).map((item, index) => {
            fjidlist.push(item.id)
        });
    }
    addfujian() {
        const { fujian } = this.state;
        fetch('/Cominfo/editfj', {
            method: "POST",
            body: JSON.stringify({
                cid: this.state.id,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data);
                const newinfo = {
                    id: data.id,
                    title: '',
                    fileList: [],
                }
                const newArr = fujian.concat(newinfo);
                this.setState({
                    fujian: newArr
                })
                let fjidlist = new Array();
                newArr.map((item, index) => {
                    fjidlist.push(item.id)
                });
            })
        
        // console.log(fjidlist);
    }

    subinfo(field,val) {
        fetch('/Cominfo/edit', {
            method: "POST",
            body: JSON.stringify({
                id:this.state.id,
                [field]:val
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data);
                var str = field + 'time';
                this.setState({
                    [field + 'time']: data[str],
                })
            })
    }
    subfjinfo(id,field, val) {
        // console.log(field,val)
        fetch('/Cominfo/editfj', {
            method: "POST",
            body: JSON.stringify({
                id: id,
                cid: this.state.id,
                [field]: val
            })
        })
            .then(res => res.json())
            .then(data => {

            })
    }
    delfj(id) {
        fetch('/Cominfo/delfj', {
            method: "POST",
            body: JSON.stringify({
                cid: this.state.id,
                id: id,
            })
        })
            .then(res => res.json())
            .then(data => {

            })
    }
    changeid(id,e){
        this.setState({
            id:id
        })
        this.getinfo(id)
    }
    render() {
        return (
            <>
                {store.getState().data.info.id != 4 ?
                <div style={{ float: "left", marginBottom: 24 }}>
                    <Button type={this.state.id == 1 ? 'primary' : null} style={{marginRight:15}} onClick={this.changeid.bind(this, 1)} >天津炎汉科技有限公司</Button>
                    <Button type={this.state.id == 2 ? 'primary' : null} style={{ marginRight: 15 }} onClick={this.changeid.bind(this, 2)} >天津朝夕科技有限公司</Button>
                    <Button type={this.state.id == 3 ? 'primary' : null} style={{ marginRight: 15 }} onClick={this.changeid.bind(this, 3)} >天津度尺科技有限公司</Button>
                </div>
                :null}
                <div style={{ float: "right", marginBottom: 24 }}>
                    <Button type="primary" onClick={this.isedit.bind(this)}>{this.state.edit == 1 ? '编辑中' : '编辑'}</Button>
                </div>
                {this.state.edit == 1 ?
                    <>
                        <table className="ant-table grinfo">
                            <tbody>
                                <tr>
                                <td className="ant-table-cell th" colSpan="2">基本信息</td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title" width="150">公司名称</td>
                                    <td className="ant-table-cell" width="">
                                        <Input name="title" onChange={this.onChange.bind(this)} value={this.state.title} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">统一社会信用代码</td>
                                    <td className="ant-table-cell">
                                        <Input name="code" onChange={this.onChange.bind(this)} value={this.state.code} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">注册地址</td>
                                    <td className="ant-table-cell">
                                        <Input name="address" onChange={this.onChange.bind(this)} value={this.state.address} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">法定代表人</td>
                                    <td className="ant-table-cell">
                                        <Input name="faren" onChange={this.onChange.bind(this)} value={this.state.faren} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">经营范围</td>
                                    <td className="ant-table-cell">
                                        <TextArea
                                            name="jyfw"
                                            value={this.state.jyfw}
                                            onChange={this.onChange.bind(this)}
                                            placeholder="请输入"
                                            autoSize={{ minRows: 5, maxRows: 8 }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">开户银行</td>
                                    <td className="ant-table-cell">
                                        <Input name="bank" onChange={this.onChange.bind(this)} value={this.state.bank} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">银行账号</td>
                                    <td className="ant-table-cell">
                                        <Input name="banknumber" onChange={this.onChange.bind(this)} value={this.state.banknumber} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">电话</td>
                                    <td className="ant-table-cell">
                                        <Input name="tel" onChange={this.onChange.bind(this)} value={this.state.tel} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="ant-table grinfo">
                            <tbody>
                                <tr>
                                    <td className="ant-table-cell th" colSpan="2">附件信息</td>
                                    <td className="ant-table-cell th" >
                                        <Button type="link" onClick={this.addfujian.bind(this)} block icon={<PlusOutlined style={{color:'#333'}} />} />
                                    </td>
                                </tr>
                                {
                                    this.state.fujian.map((list, index) => 
                                    { 
                                        return  <tr key={index}>
                                                    <td className="ant-table-cell title" width="150">
                                                        <Input name="title" onChange={this.inputChange2.bind(this, list, index, 'title')} value={list.title} />
                                                    </td>
                                                    <td className="ant-table-cell" width="">
                                                        <UploadFile action="/Common/uploadFile" fjid={list.id} getfileIdList={this.getfileIdList} defaultlist={list.filelist} />
                                                    </td>
                                                    <td className="ant-table-cell" width="50">
                                                        {
                                                        ['1','2','3','4','5','6'].indexOf(list.id)== -1 ?
                                                            <Popconfirm placement="top" title={'确认删除？'} onConfirm={this.delfujian.bind(this, index)} okText="Yes" cancelText="No">
                                                                <Button type="link" block icon={<CloseOutlined style={{ color: '#f00' }} />} />
                                                            </Popconfirm>
                                                            :
                                                            null
                                                        }
                                                    </td>
                                                </tr> 
                                    })
                                }
                            </tbody>
                        </table>
                        <table className="ant-table grinfo">
                            <tbody>
                                <tr>
                                    <td className="ant-table-cell th" colSpan="6">相关账号</td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title" width="">
                                        平台
                                    </td>
                                    <td className="ant-table-cell title" width="">
                                        账号名称
                                    </td>
                                    <td className="ant-table-cell title" width="">
                                        密码
                                    </td>
                                    <td className="ant-table-cell title" width="">
                                        手机
                                    </td>
                                    <td className="ant-table-cell title" width="">
                                        邮箱
                                    </td>
                                    <td className="ant-table-cell" width="50" >
                                        <Button type="link" onClick={this.addzhanghao.bind(this)} block icon={<PlusOutlined style={{ color: '#333' }} />} />
                                    </td>
                                </tr>
                                {
                                    this.state.zhanghao.map((list, index) => {
                                        return <tr key={index}>
                                            <td className="ant-table-cell" width="">
                                                <Input name="pingtai" onChange={this.inputChange.bind(this, list, index, 'pingtai')} value={list.pingtai} />
                                            </td>
                                            <td className="ant-table-cell" width="">
                                                <Input name="username" onChange={this.inputChange.bind(this, list, index, 'username')} value={list.username} />
                                            </td>
                                            <td className="ant-table-cell" width="">
                                                <Input name="password" onChange={this.inputChange.bind(this, list, index, 'password')} value={list.password} />
                                            </td>
                                            <td className="ant-table-cell" width="">
                                                <Input name="tel" onChange={this.inputChange.bind(this, list, index, 'tel')} value={list.tel} />
                                            </td>
                                            <td className="ant-table-cell" width="">
                                                <Input name="email" onChange={this.inputChange.bind(this, list, index, 'email')} value={list.email} />
                                            </td>
                                            <td className="ant-table-cell" >
                                                <Popconfirm placement="top" title={'确认删除？'} onConfirm={this.delzhanghao.bind(this, index)} okText="Yes" cancelText="No">
                                                    <Button type="link" block icon={<CloseOutlined style={{ color: '#f00' }} />} />
                                                </Popconfirm>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </>
                    :
                    <>
                        <table className="ant-table grinfo">
                            <tbody>
                                <tr>
                                    <td className="ant-table-cell th" colSpan="2">基本信息</td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title" width="150">公司名称</td>
                                    <td className="ant-table-cell" width="">
                                        {this.state.title ? <Paragraph copyable>{this.state.title}</Paragraph> : null}
                                        <span className="time">{this.state.titletime}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">统一社会信用代码</td>
                                    <td className="ant-table-cell">
                                        {this.state.code?<Paragraph copyable>{this.state.code}</Paragraph>:null}
                                        <span className="time">{this.state.codetime}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">注册地址</td>
                                    <td className="ant-table-cell">
                                        {this.state.address?<Paragraph copyable>{this.state.address}</Paragraph>:null}
                                        <span className="time">{this.state.addresstime}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">法定代表人</td>
                                    <td className="ant-table-cell">
                                        {this.state.faren ?<Paragraph copyable>{this.state.faren}</Paragraph>:null}
                                        <span className="time">{this.state.farentime}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">经营范围</td>
                                    <td className="ant-table-cell">
                                        {this.state.jyfw ? <Paragraph copyable>{this.state.jyfw}</Paragraph> : null}
                                        <span className="time">{this.state.jyfwtime}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">开户银行</td>
                                    <td className="ant-table-cell">
                                        {this.state.bank ? <Paragraph copyable>{this.state.bank}</Paragraph> : null}
                                        <span className="time">{this.state.banktime}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">银行账号</td>
                                    <td className="ant-table-cell">
                                        {this.state.banknumber ? <Paragraph copyable>{this.state.banknumber}</Paragraph> : null}
                                        <span className="time">{this.state.banknumbertime}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">电话</td>
                                    <td className="ant-table-cell">
                                        {this.state.tel ? <Paragraph copyable>{this.state.tel}</Paragraph> : null}
                                        <span className="time">{this.state.teltime}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="ant-table grinfo">
                            <tbody>
                                <tr>
                                    <td className="ant-table-cell th" colSpan="2">附件信息</td>
                                </tr>
                                {
                                    this.state.fujian.map((list, index) => {
                                        return <tr key={index}>
                                            <td className="ant-table-cell title" width="150">
                                                {list.title}{<p className="time">({list.fujiantime})</p>}
                                            </td>
                                            <td className="ant-table-cell" width="">
                                                <UploadFile action="/Common/uploadFile" disabled={true} fjid={list.id} getfileIdList={this.getfileIdList} defaultlist={list.filelist} />
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                        <table className="ant-table grinfo">
                            <tbody>
                                <tr>
                                    <td className="ant-table-cell th" colSpan="6">相关账号<span className="time">({this.state.zhanghaotime})</span></td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title" width="">
                                        平台
                                    </td>
                                    <td className="ant-table-cell title" width="">
                                        账号名称
                                    </td>
                                    <td className="ant-table-cell title" width="">
                                        密码
                                    </td>
                                    <td className="ant-table-cell title" width="">
                                        手机
                                    </td>
                                    <td className="ant-table-cell title" width="">
                                        邮箱
                                    </td>
                                </tr>
                                {
                                    this.state.zhanghao.map((list, index) => {
                                        return <tr key={index}>
                                            <td className="ant-table-cell" width="">
                                                {list.pingtai}
                                            </td>
                                            <td className="ant-table-cell" width="">
                                                {list.username ? <Paragraph copyable>{list.username}</Paragraph> : null}
                                            </td>
                                            <td className="ant-table-cell" width="">
                                                {list.password ? <Paragraph copyable>{list.password}</Paragraph> : null}
                                            </td>
                                            <td className="ant-table-cell" width="">
                                                {list.tel}
                                            </td>
                                            <td className="ant-table-cell" width="">
                                                {list.email}
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>

                    </>
                }

            </>
        )
    }
}
export default Cominfo;