//reducer

import { act } from "react-dom/test-utils";

const data = {
	isLogin: false,
	info: '',
	nav:'',
	navname:'',
	ismobile:'',
}
function IndexRedux(state = data, action) {
	// console.log(action.nav, action.navname);
	switch (action.type) {
		case "GO_LOGIN":
			//登录
			state.isLogin = true;
			state.info = action.info;
			return Object.assign({}, state, {})
		case "OUT_LOGIN":
			//退出登录
			state.isLogin = false;
			state.info = '';
			state.nav = '';
			state.navname = '';
			return Object.assign({}, state, {})
		case "GET_INFO":
			//获取登陆信息
			fetch('/Login/checklogin', {
				method: "POST",
			})
			.then(res => res.json())
			.then(data => {
				state.info = data;
				return Object.assign({},state,{})
			})
		case "NAV_CHANGE":
			state.nav = action.nav;
			state.navname = action.navname;
			return Object.assign({}, state, {})
		case "IS_MOBILE":
			var sUserAgent = navigator.userAgent.toLowerCase();
			if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
				//移动端页面
				// console.log(111);
				state.ismobile = true;
			} else {
				//pc端页面
				// console.log(222);
				state.ismobile = false;
			}
			return Object.assign({}, state, {})
		default:
		  	return state
	}
}


export default IndexRedux