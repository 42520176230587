import React from 'react';
import { Divider, Radio, Select, Tabs } from 'antd';
import Uploadfile from '../financial/uploadfile';
import List from '../financial/list';
import Invoice from '../financial/invoice';
import store from "../store/store.js";
const { Option } = Select;
const { TabPane } = Tabs;
class Financial extends React.Component {
    constructor(props) {
        super(props);
        // if (this.props.match.path == '/financial1') {
        //     var type = 1;
        // }
        // if (this.props.match.path == '/financial2') {
        //     var type = 2;
        // }
        // if (this.props.match.path == '/financial3') {
        //     var type = 3;
        // }
        const myDate = new Date();
        this.state = {
            yearlist: [],
            invoice:[],
            defaultyear: myDate.getFullYear(),
            defaultmonth: (myDate.getMonth() + 1).toString(),
            type: store.getState().data.info.id != 4 ?1:2,
            data:[]
        }
        store.subscribe(() => {
            // console.log('state状态改变了，新状态如下')
            // console.log(store.getState().data.info.type)
        })
        // this.lslist(this.state.defaultyear, this.state.defaultmonth);
    }
    componentWillReceiveProps(nextProps) {
        
    }
    componentDidMount() {
        // this.props.GET_INFO();
        this.lslist(this.state.defaultyear, this.state.defaultmonth);
        this.fplist();
        // setTimeout(() => {
        //     this.aa()
        // }, 1000);
    }
    // aa() {
    //     if (store.getState().data.info.type != 1) {
    //         this.props.history.push("/userview");
    //     }
    // }
    handleChange = value => {
        this.setState({
            defaultyear: value,
        })
        this.lslist(value, this.state.defaultmonth);
    }
    handleSizeChange = e => {
        this.setState({
            defaultmonth: e.target.value
        });
        this.lslist(this.state.defaultyear, e.target.value);
    };
    handleTypeChange = value => {
        // console.log(value);
        this.setState({
            type: value
        });
        this.lslist(this.state.defaultyear, this.state.defaultmonth, value);
        this.fplist(value)
    };
    arryear() {
        const arr = this.state.yearlist
        return (
            arr.map((list, index) =>
                <Option value={list.num} key={index}>{list.num + '年'}</Option>
            )
        )
    }
    //筛选流水账单
    lslist = (year, month,type) => {
        fetch('/Financial/index', {
            method: "POST",
            body: JSON.stringify({ 'year': year, 'month': month, 'type': type ?type:this.state.type })
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    defaultyear: data.year,
                    defaultmonth: data.month,
                    data: data.liushui,
                    yearlist: data.yearlist,
                })
            })
    }
    //查询发票
    fplist = (value) =>{
        fetch('/Fapiao/index', {
            method: "POST",
            body: JSON.stringify({ 'type': value ?value:this.state.type})
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    invoice: data.invoice,
                    arrfptype: data.arrfptype,
                    arrfktype: data.arrfktype,
                    arrfpstatus: data.arrfpstatus,
                    arrcustomer: data.arrcustomer,
                })
            })
    }
    changeTitle(){
        if (this.state.type == 1) {
            return <Divider orientation="left" plain>炎汉科技</Divider>
        }
        if (this.state.type == 2) {
            return < Divider orientation = "left" plain > 朝夕科技</Divider >
        }
        if (this.state.type == 3) {
            return < Divider orientation = "left" plain > 度尺科技</Divider >
        }
    }
    render(){
        function callback(key) {
            // console.log(key);
        }
        return (
            <Tabs defaultActiveKey="1" type="card" onChange={callback}>
                <TabPane tab="流水" key="1">
                    <div style={{float:"left"}}>
                        <Select value={this.state.defaultyear} style={{ width: 120, marginRight: 15 }} onChange={this.handleChange.bind(this)}>
                            {this.arryear()}
                        </Select>
                        <Radio.Group value={this.state.defaultmonth} onChange={this.handleSizeChange.bind(this)} buttonStyle="solid">
                            <Radio.Button value="1">1月</Radio.Button>
                            <Radio.Button value="2">2月</Radio.Button>
                            <Radio.Button value="3">3月</Radio.Button>
                            <Radio.Button value="4">4月</Radio.Button>
                            <Radio.Button value="5">5月</Radio.Button>
                            <Radio.Button value="6">6月</Radio.Button>
                            <Radio.Button value="7">7月</Radio.Button>
                            <Radio.Button value="8">8月</Radio.Button>
                            <Radio.Button value="9">9月</Radio.Button>
                            <Radio.Button value="10">10月</Radio.Button>
                            <Radio.Button value="11">11月</Radio.Button>
                            <Radio.Button value="12">12月</Radio.Button>
                            <Radio.Button value="99">全部</Radio.Button>
                        </Radio.Group>
                        {store.getState().data.info.id != 4 ?
                        <Select value={this.state.type} style={{ width: 120, marginLeft: 15 }} onChange={this.handleTypeChange.bind(this)}>
                            <Option value={1} key={1}>炎汉科技</Option>
                            <Option value={2} key={2}>朝夕科技</Option>
                            <Option value={3} key={3}>度尺科技</Option>
                        </Select>
                        :null}
                    </div>
                    <div style={{ float: "right" }}>
                        <Uploadfile type={this.state.type} action="/Financial/uploadExcel" year={this.state.defaultyear} month={this.state.defaultmonth} getls={this.lslist} />
                    </div>
                    <div className="clearfix"></div>
                    {this.changeTitle()}
                    <List type={this.state.type} liushui={this.state.data} year={this.state.defaultyear} month={this.state.defaultmonth} refresh={this.lslist.bind(this)} />
                </TabPane>
                <TabPane tab="发票" key="2">
                    <Invoice type={this.state.type} typechange={this.handleTypeChange} getfp={this.fplist} invoice={this.state.invoice} arrfptype={this.state.arrfptype} arrfktype={this.state.arrfktype} arrfpstatus={this.state.arrfpstatus} arrcustomer={this.state.arrcustomer} />
                </TabPane>
            </Tabs>
        )
        
    }
}
export default Financial;