import React from 'react';
import { Upload, message, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
class UploadFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: props.defaultlist
        }
    }
    componentDidMount() {
        
    }
    componentWillReceiveProps(nextProps) {
        // this.setState({
        //     fileList: nextProps.defaultlist
        // })
    }
    handleChange = info => {
        let fileList = [...info.fileList];

        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(0);

        // 2. Read from response and show file link
        fileList = fileList.map(file => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;
                file.uid = file.response.id;
            }
            return file;
        });

        var fileIdList=[]
        fileList.map(file => {
            fileIdList.push(file.uid);
        }); 
        // console.log(fileIdList)
        if (info.file.status != 'uploading') {
            this.props.getfileIdList(fileIdList)
            this.setState({ fileList });
        }

    };
    render() {
        const props = {
            name: 'file',
            action: this.props.action,
            headers: {
                authorization: 'authorization-text',
            },
            onChange: this.handleChange,
        };
        return (
            <Upload {...props} fileList={this.state.fileList} fileIdList={this.state.fileIdList} >
                <Button icon={<UploadOutlined />}>上传</Button>
            </Upload>
        )
    }
}
export default UploadFile;