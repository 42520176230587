import React from 'react';
import { Input, Select, DatePicker, Button, Radio, message, Cascader} from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import store from "../store/store.js";
const {Option} = Select;
class Userview extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {
            edit:0,
            id: '',
            name: '',
            sex: 1,
            jijin: 1,
            company: 0,
            hktype: 0,
            contract: '',
            constart: '',
            conend: '',
            idcard: '',
            khbank: '',
            bank: '',
            banknum: '',
            address: '',
            tel: '',
            addtime: '',
            // fctime: '',
            // status: '',
            mz: 0,
            zzmm: 0,
            hkaddress:'',
            hkaddtime:'',
            hytype:'',
            xl: 0,
            xltype: 0,
            email: '',
            qq: '',
            jinjiname: '',
            jinjitel: '',
            jinjiaddress: '',
            companytype: [],
            contracttype: [],
            arrhktype: [],
            arrzzmmtype:[],
            arrmztype:[],
            arrhytype:[],
            arrxl: [],
            arrxltype: [],
            hktype: [],
            arrjybj: [
                {
                    from: '',
                    to: '',
                    schoolname: '',
                    major: '',
                    degree: '',
                }
            ],
            arrgzjl: [
                {
                    from: '',
                    to: '',
                    cpmpanyname: '',
                    dept: '',
                    position: '',
                }
            ],
            jkstatus:0,
            arrjkstatus:[],
            tjjy:0,
            isyingjie:0,
            iskunnan:0,
            iszyzg:0,
            zyzg:0,
            arrzyzg:[],
            zyzggettime:'',
            zyzglevel:0,
            arrzyzglevel:[],
            iszyjs:0,
            zyjsgettime:'',
            zyjsname:'',
            nianjia:'',
            // data: store.getState().data.info
        }
        store.subscribe(() => {
            // console.log('state状态改变了，新状态如下')
            // console.log(store.getState().data.info.type)
            this.getuser();
        })
    }
    componentDidMount() {
        this.setState({
            id: store.getState().data.info.id,
        })
        this.getuser();
    }
    isedit(){
        this.setState({
            edit: this.state.edit == 1 ? 0 : 1
        })
    }
    getuser = () => {
        var id = this.props.match.params.id;
        if(!id){
            id = store.getState().data.info.id;
        }
        fetch('/User/view', {
            method: "POST",
            body: JSON.stringify({ 'id': id,'cid': store.getState().data.info.id })
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    id: data.id,
                    // password: data.password,
                    name: data.name,
                    sex: data.sex,
                    idcard: data.idcard,
                    company: data.company,
                    khbank: data.khbank,
                    bank: data.bank,
                    banknum: data.banknum,
                    address: data.address,
                    tel: data.tel,
                    fctime: data.fctime,
                    addtime: data.addtime,
                    status: data.status,
                    contract: data.contract,
                    constart: data.constart,
                    conend: data.conend,
                    hktype: data.hktype,
                    fctime: data.fctime,
                    status: data.status,

                    mz: data.mz?data.mz:0,
                    zzmm: data.zzmm?data.zzmm:0,
                    hkaddress: data.hkaddress,
                    hkaddtime: data.hkaddtime,
                    hytype: data.hytype?data.hytype:0,
                    xl: data.xl?data.xl:0,
                    xltype: data.xltype?data.xltype:0,
                    email: data.email,
                    qq: data.qq,
                    jinjiname: data.jinjiname,
                    jinjitel: data.jinjitel,
                    jinjiaddress: data.jinjiaddress,

                    jkstatus: data.jkstatus?data.jkstatus:0,
                    zxqk: data.zxqk?data.zxqk:0,
                    tjjy: data.tjjy,
                    isyingjie: data.isyingjie?data.isyingjie:0,
                    iskunnan: data.iskunnan?data.iskunnan:0,
                    iszyzg: data.iszyzg,
                    zyzg: data.zyzg?data.zyzg:0,
                    zyzggettime: data.zyzggettime,
                    zyzglevel: data.zyzglevel?data.zyzglevel:0,
                    iszyjs: data.iszyjs,
                    zyjsgettime: data.zyjsgettime,
                    zyjsname: data.zyjsname,

                    companytype: data.companytype,
                    contracttype: data.contracttype,
                    arrhktype: data.arrhktype,
                    arrzzmmtype: data.arrzzmmtype,
                    arrmztype: data.arrmztype,
                    arrhytype: data.arrhytype,
                    arrxl: data.arrxl,
                    arrxltype: data.arrxltype,

                    arrjkstatus: data.arrjkstatus,
                    arrzyzg: data.arrzyzg,
                    arrzyzglevel: data.arrzyzglevel,
                    arrzyjsname: data.arrzyjsname,
                    isok: data.isok,

                    nianjia: data.nianjia?data.nianjia:0,
                    
                })
                if (data.arrjybj) {
                    this.setState({
                        arrjybj: data.arrjybj,
                    })
                }
                if (data.arrgzjl) {
                    this.setState({
                        arrgzjl: data.arrgzjl,
                    })
                }
            })
    }
    getarrinfo(arr, val) {
        return (
            arr.map((list, index) =>
                // <Option value={list.id} key={index}>{list.name}</Option>
                list.id == val ? list.name : null
            )
        )
    }
    //公司分类
    arrcom() {
        return (
            this.state.companytype.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    //合同分类
    arrcontract() {
        return (
            this.state.contracttype.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    //户口分类
    arrhk() {
        return (
            this.state.arrhktype.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    //政治面貌
    arrzzmm() {
        return (
            this.state.arrzzmmtype.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    //民族
    arrmz() {
        return (
            this.state.arrmztype.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    //婚姻状况
    arrhy() {
        return (
            this.state.arrhytype.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    //学历
    arrxl() {
        return (
            this.state.arrxl.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    //学历类型
    arrxltype() {
        return (
            this.state.arrxltype.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    //健康状态
    arrjkstatus() {
        return (
            this.state.arrjkstatus.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    //职业(工种)资格
    arrzyzg() {
        return (
            this.state.arrzyzg.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    //职业资格等级
    arrzyzglevel() {
        return (
            this.state.arrzyzglevel.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    
    onChange(e){
       this.setState({
           [e.target.name]: e.target.value
       })
    }
    onselChange(name,value){
        this.setState({
            [name]: value
        })
    }
    ontimeChange(name,date, dateString) {
        this.setState({
            [name]: dateString
        })
    }
    ontimeChange2(name, date, dateString) {
        this.setState({
            [name]: dateString
        })
    }
    inputChange(item, index, name, e) {
        // console.log("修改后的值为：", moment(e).format('YYYY-MM-DD HH:mm:ss'));
        // console.log("name：", name);
        var value ='';
        if (name == 'from' || name == 'to'){
            value = moment(e).format('YYYY-MM-DD');
            // console.log("修改后的值为：", value);
        }
        if (name != 'from' && name != 'to') {
            value = e.target.value
        }
        const { arrjybj } = this.state;
        arrjybj[index][name] = value;
        let newArr = arrjybj;
        this.setState({
            arrjybj: newArr
        })
    }
    deljybj(num, e) {
        const { arrjybj } = this.state;
        try {
            arrjybj.forEach((item, index) => {
                if (index == num) {
                    arrjybj[num] = null;
                    throw new Error(num);
                }
            });
        } catch (e) {
            // throw e;
            // console.log(arrjybj.filter(Boolean));
            this.setState({
                arrjybj: arrjybj.filter(Boolean)
            })
        }
    }
    addjybj() {
        const { arrjybj } = this.state;
        const newinfo = {
            from: '',
            to: '',
            schoolname: '',
            major: '',
            degree: '',
        }
        const newArr = arrjybj.concat(newinfo);
        // console.log(newArr);
        this.setState({
            arrjybj: newArr
        })
    }
    inputChange2(item, index, name, e) {
        
        // console.log("name：", name);
        var value = '';
        if (name == 'from' || name == 'to') {
            value = moment(e).format('YYYY-MM-DD');
            // console.log("修改后的值为：", value);
        }
        if (name != 'from' && name != 'to') {
            value = e.target.value
        }
        const { arrgzjl } = this.state;
        arrgzjl[index][name] = value;
        let newArr = arrgzjl;
        this.setState({
            arrgzjl: newArr
        })
    }
    delgzjl(num, e) {
        const { arrgzjl } = this.state;
        try {
            arrgzjl.forEach((item, index) => {
                if (index == num) {
                    arrgzjl[num] = null;
                    throw new Error(num);
                }
            });
        } catch (e) {
            // throw e;
            // console.log(arrgzjl.filter(Boolean));
            this.setState({
                arrgzjl: arrgzjl.filter(Boolean)
            })
        }
    }
    addgzjl() {
        const { arrgzjl } = this.state;
        const newinfo = {
            from: '',
            to: '',
            cpmpanyname: '',
            dept: '',
            position: '',
        }
        const newArr = arrgzjl.concat(newinfo);
        // console.log(newArr);
        this.setState({
            arrgzjl: newArr
        })
    }
    subinfo(){
        fetch('/User/doview', {
            method: "POST",
            body: JSON.stringify({ 
                id: this.state.id,
                cid: store.getState().data.info.id,
                password: this.state.password,
                name: this.state.name,
                sex: this.state.sex,
                idcard: this.state.idcard,
                company: this.state.company,
                khbank: this.state.khbank,
                bank: this.state.bank,
                banknum: this.state.banknum,
                address: this.state.address,
                tel: this.state.tel,
                fctime: this.state.fctime,
                addtime: this.state.addtime,
                status: this.state.status,
                contract: this.state.contract,
                constart: this.state.constart,
                conend: this.state.conend,
                hktype: this.state.hktype,
                fctime: this.state.fctime,
                status: this.state.status,

                mz: this.state.mz,
                zzmm: this.state.zzmm,
                hkaddress: this.state.hkaddress,
                hkaddtime: this.state.hkaddtime,
                hytype: this.state.hytype,
                xl: this.state.xl,
                xltype: this.state.xltype,
                email: this.state.email,
                qq: this.state.qq,
                jinjiname: this.state.jinjiname,
                jinjitel: this.state.jinjitel,
                jinjiaddress: this.state.jinjiaddress,
                arrjybj: this.state.arrjybj,
                arrgzjl: this.state.arrgzjl,

                jkstatus: this.state.jkstatus,
                zxqk: this.state.zxqk,
                tjjy: this.state.tjjy,
                isyingjie: this.state.isyingjie,
                iskunnan: this.state.iskunnan,
                iszyzg: this.state.iszyzg,
                zyzg: this.state.zyzg,
                zyzggettime: this.state.zyzggettime,
                zyzglevel: this.state.zyzglevel,
                iszyjs: this.state.iszyjs,
                zyjsgettime: this.state.zyjsgettime,
                zyjsname: this.state.zyjsname,
                nianjia: this.state.nianjia,
             })
        })
            .then(res => res.json())
            .then(data => {
                if (data.msg) {
                    message.warning(data.msg);
                    document.getElementsByName("tel")[0].focus();
                }else{
                    this.isedit();
                }
            })

    }
    fcChange = (name,e) => {
        // console.log(value);
        this.setState({
            [name]: e.target.value,
        })
    }
    render() {
        return (
            <>
                {store.getState().data.info.type==1?
                    this.state.edit == 1 ?
                        <>
                            <div style={{ float: "left",lineHeight:"32px"}}>
                                封存：
                                <Radio.Group onChange={this.fcChange.bind(this,'status')} value={this.state.status}>
                                    <Radio value="1" key={1}>是</Radio>
                                    <Radio value="0" key={0}>否</Radio>
                                </Radio.Group>
                                {this.state.status==1?
                                    <>
                                    封存时间：
                                    <DatePicker name="fctime" onChange={this.ontimeChange.bind(this, 'fctime')} value={this.state.fctime ? moment(this.state.fctime) : null} placeholder="封存时间" />
                                    </>
                                    :
                                    null
                                }
                            </div>
                        </>
                        :
                        <>
                            <div style={{ float: "left", lineHeight: "32px" }}>
                                封存：{this.state.status == 1 ? '是' : '否'}&nbsp;&nbsp;&nbsp;&nbsp;
                                {this.state.status == 1 ?
                                    <>
                                    封存时间：{this.state.fctime!=0 ? this.state.fctime : null}
                                    </>
                                    :
                                    null
                                }
                            </div>
                        </>
                    :
                    null
                    
                }
                
                <div style={{ float: "right",marginBottom:24 }}>
                    <Button type="primary" onClick={this.state.edit == 1 ? this.subinfo.bind(this) : this.isedit.bind(this) }>{this.state.edit==1?'完成编辑':'编辑'}</Button>
                </div>
                {this.state.edit == 1 ?
                    <>
                        {store.getState().data.info.type == 1 ?
                            <table className="ant-table grinfo">
                                <tbody>
                                    <tr>
                                        <td className="ant-table-cell title">Full Name (Chinese) / 中文姓名</td>
                                        <td className="ant-table-cell">
                                            <Input name="name" onChange={this.onChange.bind(this)} value={this.state.name} />
                                        </td>
                                        <td className="ant-table-cell title">Password / 密码</td>
                                        <td className="ant-table-cell">
                                            <Input name="password" type="password" onChange={this.onChange.bind(this)} value={this.state.password} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ant-table-cell th" colSpan="4">Labor Relations/ 劳动关系</td>
                                    </tr>
                                    <tr>
                                        <td className="ant-table-cell title" width="25%">Company/所属公司</td>
                                        <td className="ant-table-cell" width="25%">
                                            {store.getState().data.info.id != 4 ?
                                            <Select name="company" style={{ width: '100%' }} onChange={this.onselChange.bind(this, 'company')} placeholder="请选择" value={this.state.company == 0 ? null : parseInt(this.state.company)}>
                                                {this.arrcom()}
                                            </Select>
                                            :
                                            '天津朝夕科技有限公司'
                                            }
                                        </td>
                                        <td className="ant-table-cell title" width="25%">HIREDATE/入职日期</td>
                                        <td className="ant-table-cell" width="25%">
                                            <DatePicker name="addtime" style={{ width: '100%' }} onChange={this.ontimeChange.bind(this, 'addtime')} value={this.state.addtime ? moment(this.state.addtime) : null} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ant-table-cell title">Type of Contract/合同类型</td>
                                        <td className="ant-table-cell">
                                            <Select name="contract" style={{ width: '100%' }} onChange={this.onselChange.bind(this, 'contract')} placeholder="请选择" value={this.state.contract == 0 ? null : parseInt(this.state.contract)}>
                                                {this.arrcontract()}
                                            </Select>
                                        </td>
                                        <td className="ant-table-cell title">Contract Start And End Date/合同起止日</td>
                                        <td className="ant-table-cell">
                                            <DatePicker name="constart" style={{ marginRight: 4 }} onChange={this.ontimeChange.bind(this, 'constart')} value={this.state.constart ? moment(this.state.constart) : null} placeholder="合同开始时间" />
                                            <DatePicker name="conend" onChange={this.ontimeChange.bind(this, 'conend')} value={this.state.conend ? moment(this.state.conend) : null} placeholder="合同结束时间" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            :
                            <table className="ant-table grinfo">
                                <tbody>
                                    <tr>
                                        <td className="ant-table-cell title">Full Name (Chinese) / 中文姓名</td>
                                        <td className="ant-table-cell">
                                            {this.state.name}
                                        </td>
                                        <td className="ant-table-cell title">Password / 密码</td>
                                        <td className="ant-table-cell">
                                            <Input name="password" type="password" onChange={this.onChange.bind(this)} value={this.state.password} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ant-table-cell th" colSpan="4">Labor Relations/ 劳动关系</td>
                                    </tr>
                                    <tr>
                                        <td className="ant-table-cell title" width="25%">Company/所属公司</td>
                                        <td className="ant-table-cell" width="25%">
                                            {this.getarrinfo(this.state.companytype, parseInt(this.state.company))}
                                        </td>
                                        <td className="ant-table-cell title" width="25%">HIREDATE/入职日期</td>
                                        <td className="ant-table-cell" width="25%">
                                            {this.state.addtime ? this.state.addtime : null}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ant-table-cell title">Type of Contract/合同类型</td>
                                        <td className="ant-table-cell">
                                            {this.getarrinfo(this.state.contracttype, parseInt(this.state.contract))}
                                        </td>
                                        <td className="ant-table-cell title">Contract Start And End Date/合同起止日</td>
                                        <td className="ant-table-cell">
                                            {this.state.constart ? this.state.constart : null} 至 {this.state.conend ? this.state.conend : '无期限'}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        }
                        <table className="ant-table grinfo">
                            <tbody>
                                <tr>
                                    <td className="ant-table-cell th" colSpan="4">Personal Information / 个人信息</td>
                                </tr>
                                <tr>
                                    {store.getState().data.info.type == 1 ?
                                        <>
                                            <td className="ant-table-cell title" width="25%">ID Card No. / 身份证号码</td>
                                            <td className="ant-table-cell" width="25%">
                                                <Input name="idcard" onChange={this.onChange.bind(this)} value={this.state.idcard} />
                                            </td>
                                            <td className="ant-table-cell title" width="25%">Mobile / 移动电话</td>
                                            <td className="ant-table-cell" width="25%">
                                                <Input name="tel" onChange={this.onChange.bind(this)} value={this.state.tel} />
                                            </td>
                                        </>
                                    :
                                        <>
                                            <td className="ant-table-cell title" width="25%">ID Card No. / 身份证号码</td>
                                            <td className="ant-table-cell" width="25%">
                                                {this.state.idcard}
                                            </td>
                                            <td className="ant-table-cell title" width="25%">Mobile / 移动电话</td>
                                            <td className="ant-table-cell" width="25%">
                                                <Input name="tel" onChange={this.onChange.bind(this)} value={this.state.tel} />
                                            </td>
                                        </>
                                    }
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">Nationality / 民族</td>
                                    <td className="ant-table-cell">
                                        <Select name="mz" style={{ width: '100%' }} onChange={this.onselChange.bind(this, 'mz')} placeholder="请选择" value={this.state.mz == 0 ? null : parseInt(this.state.mz)}>
                                            {this.arrmz()}
                                        </Select>
                                    </td>
                                    <td className="ant-table-cell title">Political Status/政治面貌</td>
                                    <td className="ant-table-cell">
                                        <Select name="zzmm" style={{ width: '100%' }} onChange={this.onselChange.bind(this, 'zzmm')} placeholder="请选择" value={this.state.zzmm == 0 ? null : parseInt(this.state.zzmm)}>
                                            {this.arrzzmm()}
                                        </Select>
                                    </td>
                                </tr>
                                
                                <tr>
                                    
                                    <td className="ant-table-cell title" width="25%">Marital Status / 婚姻状况</td>
                                    <td className="ant-table-cell" width="25%">
                                        <Select style={{ width: '100%' }} value={this.state.hytype == 0 ? null : parseInt(this.state.hytype)} placeholder="请选择" name="hytype" onChange={this.onselChange.bind(this, 'hytype')}>
                                            {this.arrhy()}
                                        </Select>
                                    </td>
                                    <td className="ant-table-cell title">年假</td>
                                    <td className="ant-table-cell">
                                        {store.getState().data.info.type == 1 ?
                                            <Input type="number" name="nianjia" min="0" onChange={this.onChange.bind(this)} value={this.state.nianjia} />
                                            :
                                            this.state.nianjia
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">Residential Address (Please use Chinese) / 居住地址 (详细到门牌号的地址)</td>
                                    <td className="ant-table-cell" colSpan="3">
                                        <Input name="address" onChange={this.onChange.bind(this)} value={this.state.address} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">Your highest education is/您的最高学历为</td>
                                    <td className="ant-table-cell">
                                        <Select style={{ width: '100%' }} value={this.state.xl == 0 ? null : parseInt(this.state.xl)} placeholder="请选择" name="xl" onChange={this.onselChange.bind(this, 'xl')}>
                                            {this.arrxl()}
                                        </Select>
                                    </td>
                                    <td className="ant-table-cell title">Education type/学历类型</td>
                                    <td className="ant-table-cell">
                                        <Select style={{ width: '100%' }} value={this.state.xltype == 0 ? null : parseInt(this.state.xltype)} placeholder="请选择" name="xltype" onChange={this.onselChange.bind(this, 'xltype')}>
                                            {this.arrxltype()}
                                        </Select>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">Mailbox / 邮箱（请填写本人真实有效的邮箱号,用于接收公司重要通知及工资信息）</td>
                                    <td className="ant-table-cell">
                                        <Input name="email" onChange={this.onChange.bind(this)} value={this.state.email} />
                                    </td>
                                    <td className="ant-table-cell title">QQ/公司QQ号</td>
                                    <td className="ant-table-cell">
                                        <Input name="qq" onChange={this.onChange.bind(this)} value={this.state.qq} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell th" colSpan="4">Emergency Contact / 紧急联系人</td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">Name / 姓名</td>
                                    <td className="ant-table-cell">
                                        <Input name="jinjiname" onChange={this.onChange.bind(this)} value={this.state.jinjiname} />
                                    </td>
                                    <td className="ant-table-cell title">Mobile phone / 移动电话</td>
                                    <td className="ant-table-cell">
                                        <Input name="jinjitel" onChange={this.onChange.bind(this)} value={this.state.jinjitel} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">Emergency contact address / 紧急联系地址</td>
                                    <td className="ant-table-cell" colSpan="3">
                                        <Input name="jinjiaddress" onChange={this.onChange.bind(this)} value={this.state.jinjiaddress} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="ant-table grinfo noborder">
                            <tbody>
                                {this.state.contract==1 ?
                                    <tr>
                                        <td className="ant-table-cell">
                                            是否首次在天津就业（参保）
                                            <Radio.Group onChange={this.fcChange.bind(this, 'tjjy')} value={this.state.tjjy} style={{marginLeft:15}}>
                                                <Radio value="1">是</Radio>
                                                <Radio value="2">否</Radio>
                                            </Radio.Group>
                                        </td>
                                    </tr>
                                :
                                    <tr>
                                        <td className="ant-table-cell">
                                            在校情况
                                            <Radio.Group onChange={this.fcChange.bind(this, 'zxqk')} value={this.state.zxqk} style={{ marginLeft: 15 }}>
                                                <Radio value="1">在读</Radio>
                                                <Radio value="2">已毕业</Radio>
                                            </Radio.Group>
                                        </td>
                                    </tr>  
                                }
                            </tbody>
                        </table>
                        {this.state.tjjy==1 && this.state.contract==1 ?
                            <table className="ant-table grinfo">
                                <tbody>
                                    <tr>
                                        <td className="ant-table-cell th" colSpan="4">Please fill in the first employment (insurance) in Tianjin / 首次在天津就业（参保）请填写</td>
                                    </tr>
                                    <tr>
                                        <td className="ant-table-cell title" width="25%">Household Type/户口性质</td>
                                        <td className="ant-table-cell" width="25%">
                                            <Select style={{ width: '100%' }} value={this.state.hktype == 0 ? null : parseInt(this.state.hktype)} placeholder="请选择" name="hktype" onChange={this.onselChange.bind(this, 'hktype')}>
                                                {this.arrhk()}
                                            </Select>
                                        </td>
                                        <td className="ant-table-cell title">Date of Household Registration/户口登记日期</td>
                                        <td className="ant-table-cell">
                                            <DatePicker name="hkaddtime" style={{ width: '100%' }} onChange={this.ontimeChange.bind(this, 'hkaddtime')} value={this.state.hkaddtime ? moment(this.state.hkaddtime) : null} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ant-table-cell title" >Permanent Residency / 户口详细地址</td>
                                        <td className="ant-table-cell" colSpan="3">
                                            <Input name="hkaddress" onChange={this.onChange.bind(this)} value={this.state.hkaddress} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ant-table-cell title">Are you a fresh graduate？/是否应届毕业生</td>
                                        <td className="ant-table-cell">
                                            <Select style={{ width: '100%' }} value={this.state.isyingjie == 0 ? null : parseInt(this.state.isyingjie)} placeholder="请选择" name="isyingjie" onChange={this.onselChange.bind(this, 'isyingjie')}>
                                                <Option value={1} key={1}>是</Option>
                                                <Option value={2} key={2}>否</Option>
                                            </Select>
                                        </td>
                                        <td className="ant-table-cell title">Are you a difficult graduate？/是否为困难毕业生</td>
                                        <td className="ant-table-cell">
                                            <Select style={{ width: '100%' }} value={this.state.iskunnan == 0 ? null : parseInt(this.state.iskunnan)} placeholder="请选择" name="iskunnan" onChange={this.onselChange.bind(this, 'iskunnan')}>
                                                <Option value={1} key={1}>是</Option>
                                                <Option value={2} key={2}>否</Option>
                                            </Select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ant-table-cell title">Health Status/健康状态</td>
                                        <td className="ant-table-cell">
                                            <Select style={{ width: '100%' }} value={this.state.jkstatus == 0 ? null : parseInt(this.state.jkstatus)} placeholder="请选择" name="jkstatus" onChange={this.onselChange.bind(this, 'jkstatus')}>
                                                {this.arrjkstatus()}
                                            </Select>
                                        </td>
                                        <td className="ant-table-cell title"></td>
                                        <td className="ant-table-cell"></td>
                                    </tr>
                                </tbody>
                            </table>
                            :
                            null
                        }
                        
                        <table className="ant-table grinfo">
                            <tbody>
                                <tr>
                                    <td className="ant-table-cell th" colSpan="6">Education（Highest Degree） / 教育背景（最高学历）</td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">From /自</td>
                                    <td className="ant-table-cell title" >To /至（请严格按照毕业证所示毕业日期进行日期填写）</td>
                                    <td className="ant-table-cell title" >Name of institution / 学校名称</td>
                                    <td className="ant-table-cell title" >Major /专业</td>
                                    <td className="ant-table-cell title" >Degree / 学位</td>
                                    <td className="ant-table-cell" width="50" ><a onClick={this.addjybj.bind(this)}><PlusCircleOutlined /></a></td>
                                </tr>
                                {
                                    this.state.arrjybj ?
                                        this.state.arrjybj.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td><DatePicker name="from" value={item.from ? moment(item.from) : null} onChange={this.inputChange.bind(this, item, index,'from')} /></td>
                                                    <td><DatePicker name="to" value={item.to ? moment(item.to) : null} onChange={this.inputChange.bind(this, item, index, 'to')} /></td>
                                                    <td><Input value={item.schoolname} onChange={this.inputChange.bind(this, item, index, 'schoolname')} /></td>
                                                    <td><Input value={item.major} onChange={this.inputChange.bind(this, item, index, 'major')} /></td>
                                                    <td><Input value={item.degree} onChange={this.inputChange.bind(this, item, index, 'degree')} /></td>
                                                    <td><MinusCircleOutlined style={{cursor:"pointer"}} onClick={this.deljybj.bind(this,index)} /></td>
                                                </tr>
                                            )
                                        }) : null
                                }
                            </tbody>
                        </table>
                        <table className="ant-table grinfo noborder">
                            <tbody>
                                <tr>
                                    <td className="ant-table-cell">    
                                        是否取得国家职业资格
                                        <Radio.Group onChange={this.fcChange.bind(this, 'iszyzg')} value={this.state.iszyzg} style={{ marginLeft: 15 }}>
                                            <Radio value="1">是</Radio>
                                            <Radio value="2">否</Radio>
                                        </Radio.Group>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {this.state.iszyzg == 1 ?
                            <table className="ant-table grinfo">
                                <tbody>
                                    <tr>
                                        <td className="ant-table-cell th" colSpan="4">National Professional Qualification / 国家职业资格</td>
                                    </tr>
                                    <tr>
                                        <td className="ant-table-cell title" width="25%">Occupation  Qualification/职业(工种)资格</td>
                                        <td className="ant-table-cell" width="25%">
                                            <Select style={{ width: '100%' }} value={this.state.zyzg == 0 ? null : parseInt(this.state.zyzg)} placeholder="请选择" name="zyzg" onChange={this.onselChange.bind(this, 'zyzg')}>
                                                {this.arrzyzg()}
                                            </Select>
                                        </td>
                                        <td className="ant-table-cell title">Qualification Time/取得资格时间</td>
                                        <td className="ant-table-cell">
                                            <DatePicker name="zyzggettime" style={{ width: '100%' }} onChange={this.ontimeChange.bind(this, 'zyzggettime')} value={this.state.zyzggettime ? moment(this.state.zyzggettime) : null} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ant-table-cell title" width="25%">Professional Qualification Level/职业资格等级</td>
                                        <td className="ant-table-cell" width="25%">
                                            <Select style={{ width: '100%' }} value={this.state.zyzglevel == 0 ? null : parseInt(this.state.zyzglevel)} placeholder="请选择" name="zyzglevel" onChange={this.onselChange.bind(this, 'zyzglevel')}>
                                                {this.arrzyzglevel()}
                                            </Select>
                                        </td>
                                        <td className="ant-table-cell title"></td>
                                        <td className="ant-table-cell"></td>
                                    </tr>
                                </tbody>
                            </table>
                        :
                        null
                        }
                        <table className="ant-table grinfo noborder">
                            <tbody>
                                <tr>
                                    <td className="ant-table-cell">
                                        是否取得专业技术职务
                                        <Radio.Group onChange={this.fcChange.bind(this, 'iszyjs')} value={this.state.iszyjs} style={{ marginLeft: 15 }}>
                                            <Radio value="1">是</Radio>
                                            <Radio value="2">否</Radio>
                                        </Radio.Group>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {this.state.iszyjs == 1 ?
                            <table className="ant-table grinfo">
                                <tbody>
                                    <tr>
                                        <td className="ant-table-cell th" colSpan="4">Technical Position/ 专业技术职务</td>
                                    </tr>
                                    <tr>
                                        <td className="ant-table-cell title">Qualification Time/取得资格时间</td>
                                        <td className="ant-table-cell">
                                            <DatePicker name="zyjsgettime" style={{ width: '100%' }} onChange={this.ontimeChange.bind(this, 'zyjsgettime')} value={this.state.zyjsgettime ? moment(this.state.zyjsgettime) : null} />
                                        </td>
                                        <td className="ant-table-cell title" width="25%">Title of Technical Position/专技职务名称</td>
                                        <td className="ant-table-cell" width="25%">
                                            <Input name="zyjsname" onChange={this.onChange.bind(this)} value={this.state.zyjsname} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            :
                            null
                        }
                        <table className="ant-table grinfo">
                            <tbody>
                                <tr>
                                    <td className="ant-table-cell th" colSpan="6">Career History outside / 加入我公司之前工作经历</td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">From /自</td>
                                    <td className="ant-table-cell title" >To /至</td>
                                    <td className="ant-table-cell title" >Company, Location / 公司名称，地点</td>
                                    <td className="ant-table-cell title" >Dept. /部门</td>
                                    <td className="ant-table-cell title" >Position / 职位</td>
                                    <td className="ant-table-cell" width="50" ><a onClick={this.addgzjl.bind(this)}><PlusCircleOutlined /></a></td>
                                </tr>
                                {
                                    this.state.arrgzjl ?
                                        this.state.arrgzjl.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td><DatePicker name="from" value={item.from ? moment(item.from) : null} onChange={this.inputChange2.bind(this, item, index, 'from')} /></td>
                                                    <td><DatePicker name="to" value={item.to ? moment(item.to) : null} onChange={this.inputChange2.bind(this, item, index, 'to')} /></td>
                                                    <td><Input value={item.companyname} onChange={this.inputChange2.bind(this, item, index, 'companyname')} /></td>
                                                    <td><Input value={item.dept} onChange={this.inputChange2.bind(this, item, index, 'dept')} /></td>
                                                    <td><Input value={item.position} onChange={this.inputChange2.bind(this, item, index, 'position')} /></td>
                                                    <td><MinusCircleOutlined style={{ cursor: "pointer" }} onClick={this.delgzjl.bind(this, index)} /></td>
                                                </tr>
                                            )
                                        }) : null
                                }
                            </tbody>
                        </table>
                        <table className="ant-table grinfo">
                            <tbody>
                                <tr>
                                    <td className="ant-table-cell th" colSpan="6">Bank Card Information (receiving salary) / 银行卡信息（接收工资）</td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">Bank/银行</td>
                                    <td className="ant-table-cell" ><Input name="bank" onChange={this.onChange.bind(this)} value={this.state.bank} /></td>
                                    <td className="ant-table-cell title" >Full Name of Opening Bank/ 开户行全称</td>
                                    <td className="ant-table-cell" ><Input name="khbank" onChange={this.onChange.bind(this)} value={this.state.khbank} /></td>
                                    <td className="ant-table-cell title" >Bank Card Number/银行卡号</td>
                                    <td className="ant-table-cell"><Input name="banknum" onChange={this.onChange.bind(this)} value={this.state.banknum} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                    :
                    <>
                        <table className="ant-table grinfo">
                            <tbody>
                                <tr>
                                    <td className="ant-table-cell title">Full Name (Chinese) / 中文姓名</td>
                                    <td className="ant-table-cell" colSpan="3">
                                        {this.state.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell th" colSpan="4">Labor Relations/ 劳动关系</td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title" width="25%">Company/所属公司</td>
                                    <td className="ant-table-cell" width="25%">
                                        {store.getState().data.info.id != 4 ?
                                            this.getarrinfo(this.state.companytype, parseInt(this.state.company))
                                            :
                                            '天津朝夕科技有限公司'
                                        }
                                    </td>
                                    <td className="ant-table-cell title" width="25%">HIREDATE/入职日期</td>
                                    <td className="ant-table-cell" width="25%">
                                        {this.state.addtime ? this.state.addtime : null}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">Type of Contract/合同类型</td>
                                    <td className="ant-table-cell">
                                        {this.getarrinfo(this.state.contracttype, parseInt(this.state.contract))}
                                    </td>
                                    <td className="ant-table-cell title">Contract Start And End Date/合同起止日</td>
                                    <td className="ant-table-cell">
                                        {this.state.constart ? this.state.constart : null} 至 {this.state.conend ? this.state.conend : '无期限'}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                        <table className="ant-table grinfo">
                            <tbody>
                                <tr>
                                    <td className="ant-table-cell th" colSpan="4">Personal Information / 个人信息</td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title" width="25%">ID Card No. / 身份证号码</td>
                                    <td className="ant-table-cell" width="25%">
                                        {this.state.idcard}
                                    </td>
                                    <td className="ant-table-cell title" width="25%">Mobile / 移动电话</td>
                                    <td className="ant-table-cell" width="25%">
                                        {this.state.tel}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">Nationality / 民族</td>
                                    <td className="ant-table-cell">
                                        {this.getarrinfo(this.state.arrmztype, parseInt(this.state.mz))}
                                    </td>
                                    <td className="ant-table-cell title">Political Status/政治面貌</td>
                                    <td className="ant-table-cell">
                                        {this.getarrinfo(this.state.arrzzmmtype, parseInt(this.state.zzmm))}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title" width="25%">Marital Status / 婚姻状况</td>
                                    <td className="ant-table-cell" width="25%">
                                        {this.getarrinfo(this.state.arrhytype, parseInt(this.state.hytype))}
                                    </td>
                                    <td className="ant-table-cell title">年假</td>
                                    <td className="ant-table-cell">
                                        {this.state.nianjia}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">Residential Address (Please use Chinese) / 居住地址 (详细到门牌号的地址)</td>
                                    <td className="ant-table-cell" colSpan="3">
                                        {this.state.address}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">Your highest education is/您的最高学历为</td>
                                    <td className="ant-table-cell">
                                        {this.getarrinfo(this.state.arrxl, parseInt(this.state.xl))}
                                    </td>
                                    <td className="ant-table-cell title">Education type/学历类型</td>
                                    <td className="ant-table-cell">
                                        {this.getarrinfo(this.state.arrxltype, parseInt(this.state.xltype))}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">Mailbox / 邮箱（请填写本人真实有效的邮箱号,用于接收公司重要通知及工资信息）</td>
                                    <td className="ant-table-cell">
                                        {this.state.email}
                                    </td>
                                    <td className="ant-table-cell title">QQ/公司QQ号</td>
                                    <td className="ant-table-cell">
                                        {this.state.qq}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell th" colSpan="4">Emergency Contact / 紧急联系人</td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">Name / 姓名</td>
                                    <td className="ant-table-cell">
                                        {this.state.jinjiname}
                                    </td>
                                    <td className="ant-table-cell title">Mobile phone / 移动电话</td>
                                    <td className="ant-table-cell">
                                        {this.state.jinjitel}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">Emergency contact address / 紧急联系地址</td>
                                    <td className="ant-table-cell" colSpan="3">
                                        {this.state.jinjiaddress}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="ant-table grinfo noborder">
                            <tbody>
                                {this.state.contract==1 ?
                                    <tr>
                                        <td className="ant-table-cell">
                                            是否首次在天津就业（参保） {this.state.tjjy == 1 ? '是' : null}{this.state.tjjy == 2 ? '否' : null}
                                        </td>
                                    </tr>
                                    :
                                    <tr>
                                        <td className="ant-table-cell">
                                            在校情况 {this.state.zxqk == 1 ? '在读' : null}{this.state.zxqk == 2 ? '已毕业' : null}
                                        </td>
                                    </tr>  
                                }
                            </tbody>
                        </table>
                        {this.state.tjjy == 1 && this.state.contract==1 ?
                            <table className="ant-table grinfo">
                                <tbody>
                                    <tr>
                                        <td className="ant-table-cell th" colSpan="4">Please fill in the first employment (insurance) in Tianjin / 首次在天津就业（参保）请填写</td>
                                    </tr>
                                    <tr>
                                        <td className="ant-table-cell title" width="25%">Household Type/户口性质</td>
                                        <td className="ant-table-cell" width="25%">
                                            {this.getarrinfo(this.state.arrhktype, parseInt(this.state.hktype))}
                                        </td>
                                        <td className="ant-table-cell title">Date of Household Registration/户口登记日期</td>
                                        <td className="ant-table-cell">
                                            {this.state.hkaddtime ? this.state.hkaddtime : null}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ant-table-cell title" >Permanent Residency / 户口详细地址</td>
                                        <td className="ant-table-cell" colSpan="3">
                                            {this.state.hkaddress}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ant-table-cell title">Are you a fresh graduate？/是否应届毕业生</td>
                                        <td className="ant-table-cell">
                                            {this.state.isyingjie == 1 ? '是' : null}{this.state.isyingjie == 2 ? '否' : null}
                                        </td>
                                        <td className="ant-table-cell title">Are you a difficult graduate？/是否为困难毕业生</td>
                                        <td className="ant-table-cell">
                                            {this.state.iskunnan == 1 ? '是' : null}{this.state.iskunnan == 2 ? '否' : null}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ant-table-cell title">Health Status/健康状态</td>
                                        <td className="ant-table-cell">
                                            {this.getarrinfo(this.state.arrjkstatus, parseInt(this.state.jkstatus))}
                                        </td>
                                        <td className="ant-table-cell title"></td>
                                        <td className="ant-table-cell"></td>
                                    </tr>
                                </tbody>
                            </table>
                            :
                            null
                        }
                        <table className="ant-table grinfo">
                            <tbody>
                                <tr>
                                    <td className="ant-table-cell th" colSpan="6">Education（Highest Degree） / 教育背景（最高学历）</td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">From /自</td>
                                    <td className="ant-table-cell title" >To /至</td>
                                    <td className="ant-table-cell title" >Name of institution / 学校名称</td>
                                    <td className="ant-table-cell title" >Major /专业</td>
                                    <td className="ant-table-cell title" >Degree / 学位</td>
                                </tr>
                                {
                                    this.state.arrjybj ?
                                        this.state.arrjybj.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.from ? item.from : null}</td>
                                                    <td>{item.to ? item.to : null}</td>
                                                    <td>{item.schoolname}</td>
                                                    <td>{item.major}</td>
                                                    <td>{item.degree}</td>
                                                </tr>
                                            )
                                        }) : null
                                }
                            </tbody>
                        </table>
                        <table className="ant-table grinfo noborder">
                            <tbody>
                                <tr>
                                    <td className="ant-table-cell">
                                        是否取得国家职业资格 {this.state.iszyzg == 1 ? '是' : null}{this.state.iszyzg == 2 ? '否' : null}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {this.state.iszyzg == 1 ?
                            <table className="ant-table grinfo">
                                <tbody>
                                    <tr>
                                        <td className="ant-table-cell th" colSpan="4">National Professional Qualification / 国家职业资格</td>
                                    </tr>
                                    <tr>
                                        <td className="ant-table-cell title" width="25%">Occupation  Qualification/职业(工种)资格</td>
                                        <td className="ant-table-cell" width="25%">
                                            {this.getarrinfo(this.state.arrzyzg, parseInt(this.state.zyzg))}
                                        </td>
                                        <td className="ant-table-cell title">Qualification Time/取得资格时间</td>
                                        <td className="ant-table-cell">
                                            {this.state.zyzggettime ? this.state.zyzggettime : null}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ant-table-cell title" width="25%">Professional Qualification Level/职业资格等级</td>
                                        <td className="ant-table-cell" width="25%">
                                            {this.getarrinfo(this.state.arrzyzglevel, parseInt(this.state.zyzglevel))}
                                        </td>
                                        <td className="ant-table-cell title"></td>
                                        <td className="ant-table-cell"></td>
                                    </tr>
                                </tbody>
                            </table>
                            :
                            null
                        }
                        <table className="ant-table grinfo noborder">
                            <tbody>
                                <tr>
                                    <td className="ant-table-cell">
                                        是否取得专业技术职务 {this.state.iszyjs == 1 ? '是' : null}{this.state.iszyjs == 2 ? '否' : null}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {this.state.iszyjs == 1 ?
                            <table className="ant-table grinfo">
                                <tbody>
                                    <tr>
                                        <td className="ant-table-cell th" colSpan="4">Technical Position/ 专业技术职务</td>
                                    </tr>
                                    <tr>
                                        <td className="ant-table-cell title">Qualification Time/取得资格时间</td>
                                        <td className="ant-table-cell">
                                            {this.state.zyjsgettime ? this.state.zyjsgettime : null}
                                        </td>
                                        <td className="ant-table-cell title" width="25%">Title of Technical Position/专技职务名称</td>
                                        <td className="ant-table-cell" width="25%">
                                            {this.state.zyjsname}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            :
                            null
                        }
                        <table className="ant-table grinfo">
                            <tbody>
                                <tr>
                                    <td className="ant-table-cell th" colSpan="6">Career History outside / 加入我公司之前工作经历</td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">From /自</td>
                                    <td className="ant-table-cell title" >To /至</td>
                                    <td className="ant-table-cell title" >Company, Location / 公司名称，地点</td>
                                    <td className="ant-table-cell title" >Dept. /部门</td>
                                    <td className="ant-table-cell title" >Position / 职位</td>
                                </tr>
                                {
                                    this.state.arrgzjl ?
                                        this.state.arrgzjl.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.from ? item.from : null}</td>
                                                    <td>{item.to ? item.to : null}</td>
                                                    <td>{item.companyname}</td>
                                                    <td>{item.dept}</td>
                                                    <td>{item.position}</td>
                                                </tr>
                                            )
                                        }) : null
                                }
                            </tbody>
                        </table>
                        <table className="ant-table grinfo">
                            <tbody>
                                <tr>
                                    <td className="ant-table-cell th" colSpan="6">Bank Card Information (receiving salary) / 银行卡信息（接收工资）</td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">Bank/银行</td>
                                    <td className="ant-table-cell" >{this.state.bank}</td>
                                    <td className="ant-table-cell title" >Full Name of Opening Bank/ 开户行全称</td>
                                    <td className="ant-table-cell" >{this.state.khbank}</td>
                                    <td className="ant-table-cell title" >Bank Card Number/银行卡号</td>
                                    <td className="ant-table-cell">{this.state.banknum}</td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                }
                
            </>
        )
    }
}
export default Userview;