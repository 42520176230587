import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Form, Space, Tooltip,Popconfirm,message} from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef();
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async (e) => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
                <div
                    className="editable-cell-value-wrap"
                    style={{
                        paddingRight: 24,
                    }}
                    onClick={toggleEdit}
                >
                    {children}
                </div>
            );
    }

    return <td {...restProps}>{childNode}</td>;
};
class List extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            liushui:[],
            type:1,
        }
    }
    // 接收员工列表
    componentWillReceiveProps(nextProps) {
        this.setState({
            liushui: nextProps.liushui,
            type: nextProps.type,
            year: nextProps.year,
            month: nextProps.month,
        })
    }
    handleSave = (row) => {
        const newData = [...this.state.liushui];
        const index = newData.findIndex((item) => row.key === item.key);
        // console.log(row);
        fetch('/Financial/dosm', {
            method: "POST",
            body: JSON.stringify({ 'id': row.id, 'shuoming': row.shuoming, 'type': this.state.type })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data);
            })
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        this.setState({
            liushui: newData,
        });
    };
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`查询条件`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        搜索
          </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        清除
          </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                    text
                ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    dells(type,id,e){
        console.log(type,id,e)
        fetch('/Financial/dells', {
            method: "POST",
            body: JSON.stringify({ 'id': id, 'type': type })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data);
                this.props.refresh(this.state.year,this.state.month,this.state.type)
            })
    }
    render() {
        var columns =[];
        if (this.state.type == 1) {
            columns = [
                { title: '交易类型', width: 120, dataIndex: 'jytype', ellipsis: { showTitle: false }, key: 'jytype', ...this.getColumnSearchProps('jytype'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '业务类型', width: 120, dataIndex: 'btype', ellipsis: { showTitle: false }, key: 'btype', ...this.getColumnSearchProps('btype'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '付款人开户行名', dataIndex: 'fkrkhhm', width: 200, ellipsis: { showTitle: false }, key: 'fkrkhhm', ...this.getColumnSearchProps('fkrkhhm'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '付款人账号', dataIndex: 'fkrzh', width: 200, ellipsis: { showTitle: false }, key: 'fkrzh', ...this.getColumnSearchProps('fkrzh'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '付款人名称', dataIndex: 'fkrname', width: 250, ellipsis: { showTitle: false }, key: 'fkrname', ...this.getColumnSearchProps('fkrname'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '收款人开户行名', dataIndex: 'skrkhhm', width: 200, ellipsis: { showTitle: false }, key: 'skrkhhm', ...this.getColumnSearchProps('skrkhhm'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '收款人账号', dataIndex: 'skrzh', width: 200, ellipsis: { showTitle: false }, key: 'skrzh', ...this.getColumnSearchProps('skrzh'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '收款人名称', dataIndex: 'skrname', width: 200, ellipsis: { showTitle: false }, key: 'skrname', ...this.getColumnSearchProps('skrname'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '交易日期', dataIndex: 'jyriqi', width: 120, ellipsis: { showTitle: false }, key: 'jyriqi', ...this.getColumnSearchProps('jyriqi'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '交易时间', dataIndex: 'jyshijian', width: 120, ellipsis: { showTitle: false }, key: 'jyshijian', ...this.getColumnSearchProps('jyshijian'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '交易货币', dataIndex: 'huobi', width: 120, ellipsis: { showTitle: false }, key: 'huobi', ...this.getColumnSearchProps('huobi'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '交易金额(借)', dataIndex: 'jyjine_j', width: 120, ellipsis: { showTitle: false }, key: 'jyjine_j', ...this.getColumnSearchProps('jyjine_j'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '交易金额(贷)', dataIndex: 'jyjine_d', width: 120, ellipsis: { showTitle: false }, key: 'jyjine_d', ...this.getColumnSearchProps('jyjine_d'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '交易后余额', dataIndex: 'jyyue', width: 120, ellipsis: { showTitle: false }, key: 'jyyue', ...this.getColumnSearchProps('jyyue'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '摘要', dataIndex: 'zhaiyao', width: 230, ellipsis: { showTitle: false }, key: 'zhaiyao', ...this.getColumnSearchProps('zhaiyao'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '用途', dataIndex: 'yongtu', width: 200, ellipsis: { showTitle: false }, key: 'yongtu', ...this.getColumnSearchProps('yongtu'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '交易附言', dataIndex: 'fuyan', width: 200, ellipsis: { showTitle: false }, key: 'fuyan', ...this.getColumnSearchProps('fuyan'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '备注', dataIndex: 'beizhu', width: 200, ellipsis: { showTitle: false }, key: 'beizhu', ...this.getColumnSearchProps('beizhu'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '说明', dataIndex: 'shuoming', width: 200, ellipsis: { showTitle: false }, key: 'shuoming', editable: true, ...this.getColumnSearchProps('shuoming'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '操作', width: 100, key: 'jytype', render: (raw,record) => (<Popconfirm
                    title="Are you sure to delete this task?"
                    onConfirm={this.dells.bind(this,this.state.type,record.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                      <a href='javascript:void(0)'>删除</a>
                  </Popconfirm>)},
            ];
        }
        if (this.state.type == 2) {
            columns = [
                { title: '交易日期', width: 80, ellipsis: { showTitle: false }, dataIndex: 'jyriqi', key: 'jyriqi', ...this.getColumnSearchProps('jyriqi'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '交易时间', width: 50, ellipsis: { showTitle: false }, dataIndex: 'jyshijian', key: 'jyshijian', ...this.getColumnSearchProps('jyshijian') , render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '借方金额', dataIndex: 'jfje', width: 100, ellipsis: { showTitle: false }, key: 'jfje', ...this.getColumnSearchProps('jfje') , render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '贷方金额', dataIndex: 'dfje', width: 100, ellipsis: { showTitle: false }, key: 'dfje', ...this.getColumnSearchProps('dfje') , render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '余额', dataIndex: 'yue', width: 100, ellipsis: { showTitle: false }, key: 'yue', ...this.getColumnSearchProps('yue') , render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '对方账号', dataIndex: 'dfzh', width: 150, ellipsis: { showTitle: false }, key: 'dfzh', ...this.getColumnSearchProps('dfzh') , render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '对方户名', dataIndex: 'dfhum', width: 150, ellipsis: { showTitle: false }, key: 'dfhum', ...this.getColumnSearchProps('dfhum') , render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '对方行名', dataIndex: 'dfham', width: 150, ellipsis: { showTitle: false }, key: 'dfham', ...this.getColumnSearchProps('dfham') , render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '摘要', dataIndex: 'zhaiyao', width: 100, ellipsis: { showTitle: false }, key: 'zhaiyao', ...this.getColumnSearchProps('zhaiyao'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '备注', dataIndex: 'beizhu', width: 100, ellipsis: { showTitle: false }, key: 'beizhu', ...this.getColumnSearchProps('beizhu') , render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '说明', dataIndex: 'shuoming', width: 200, ellipsis: { showTitle: false }, key: 'shuoming', editable: true, ...this.getColumnSearchProps('shuoming'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '操作', width: 100, key: 'jytype', render: (raw,record) => (<Popconfirm
                    title="Are you sure to delete this task?"
                    onConfirm={this.dells.bind(this,this.state.type,record.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                      <a href='javascript:void(0)'>删除</a>
                  </Popconfirm>)},
            ];
        }
        if (this.state.type == 3) {
            columns = [
                { title: '交易日期', width: 80, ellipsis: { showTitle: false }, dataIndex: 'jyriqi', key: 'jyriqi', ...this.getColumnSearchProps('jyriqi') , render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '交易描述', width: 80, ellipsis: { showTitle: false }, dataIndex: 'miaoshu', key: 'miaoshu', ...this.getColumnSearchProps('miaoshu') , render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '交易金额(借)', width: 80, ellipsis: { showTitle: false }, dataIndex: 'jyje_j', key: 'jyje_j', ...this.getColumnSearchProps('jyje_j') , render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '交易金额(贷)', width: 80, ellipsis: { showTitle: false }, dataIndex: 'jyje_d', key: 'jyje_d', ...this.getColumnSearchProps('jyje_d') , render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '账户余额', width: 80, ellipsis: { showTitle: false }, dataIndex: 'zhye', key: 'zhye', ...this.getColumnSearchProps('zhye') , render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '摘要', width: 50, ellipsis: { showTitle: false }, dataIndex: 'zhaoyao', key: 'zhaoyao', ...this.getColumnSearchProps('zhaoyao') , render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '对方账号', dataIndex: 'dfzh', width: 120, ellipsis: { showTitle: false }, key: 'dfzh', ...this.getColumnSearchProps('dfzh') , render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '对方账号名称', dataIndex: 'dfzhmc', width: 150, ellipsis: { showTitle: false }, key: 'dfzhmc', ...this.getColumnSearchProps('dfzhmc') , render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '对方行名', dataIndex: 'dfhm', width: 200, ellipsis: { showTitle: false }, key: 'dfhm', ...this.getColumnSearchProps('dfhm'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '说明', dataIndex: 'shuoming', width: 200, ellipsis: { showTitle: false }, key: 'shuoming', editable: true, ...this.getColumnSearchProps('shuoming'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)},
                { title: '操作', width: 100, key: 'jytype', render: (raw,record) => (<Popconfirm
                    title="Are you sure to delete this task?"
                    onConfirm={this.dells.bind(this,this.state.type,record.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                      <a href='javascript:void(0)'>删除</a>
                  </Popconfirm>)},
            ];
        }
        
        const components = {
            body: {
                row: EditableRow,
                cell: EditableCell,
            },
        };
        const column = columns.map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });
        return (
            <>
                {/* 员工列表 */}
                <Table
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    dataSource={this.state.liushui}
                    columns={column}
                    scroll={{ x: 2500, y: 'calc(100vh - 400px)'}}
                    style={{marginTop:25}}
                    className="ls"
                    // pagination={{ pageSize: 10, showTotal: total => `共${total}条数据` }}
                    pagination={false}
                    summary={
                        this.state.type == 1 ?
                            pageData => {
                                let totaljyjine_j = 0;
                                let totaljyjine_d = 0;
                                pageData.forEach(({ jyjine_j, jyjine_d}) => {
                                    totaljyjine_j += Number(jyjine_j);
                                    totaljyjine_d += Number(jyjine_d);
                                });
                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell>总计</Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                {totaljyjine_j.toFixed(2)}
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                {totaljyjine_d.toFixed(2)}
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }
                        :
                            this.state.type == 2 ?
                                pageData => {
                                    let totaljfje = 0;
                                    let totaldfje = 0;

                                    pageData.forEach(({ jfje, dfje, yue }) => {
                                        totaljfje += Number(jfje);
                                        totaldfje += Number(dfje);
                                    });
                                    return (
                                        <>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell>总计</Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    {totaljfje.toFixed(2)}
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    {totaldfje.toFixed(2)}
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        </>
                                    );
                                }
                                :
                                this.state.type == 3 ?
                                    pageData => {
                                        let totaljyje_j = 0;
                                        let totaljyje_d = 0;

                                        pageData.forEach(({ jyje_j, jyje_d, zhye }) => {
                                            totaljyje_j += Number(jyje_j);
                                            totaljyje_d += Number(jyje_d);
                                        });
                                        return (
                                            <>
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell>总计</Table.Summary.Cell>
                                                    <Table.Summary.Cell></Table.Summary.Cell>
                                                    <Table.Summary.Cell>
                                                        {totaljyje_j.toFixed(2)}
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell>
                                                        {totaljyje_d.toFixed(2)}
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell></Table.Summary.Cell>
                                                    <Table.Summary.Cell></Table.Summary.Cell>
                                                    <Table.Summary.Cell></Table.Summary.Cell>
                                                    <Table.Summary.Cell></Table.Summary.Cell>
                                                    <Table.Summary.Cell></Table.Summary.Cell>
                                                    <Table.Summary.Cell></Table.Summary.Cell>
                                                </Table.Summary.Row>
                                            </>
                                        );
                                    }
                                    :
                                    null
                    }
                />
            </>
        )
    }
}
export default List;