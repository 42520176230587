import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { DatePicker, Space, Select, Input, Button, Table, Modal, Divider, Row, Col, Form, Radio, Upload, message, List } from 'antd';
import store from "../store/store.js";
import { InboxOutlined, CheckCircleOutlined, FormOutlined, PaperClipOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons'
import Gzxview from "../xiangmu/gzxview";
import moment from 'moment';
const { Option } = Select;
const { Dragger } = Upload;
const { TextArea } = Input;
export default class Add extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                uid: store.getState().data.info.id ? store.getState().data.info.id : 0,
                picfile: [],
                ywc: [],
                wwc: []
            },
            visible: false,
            loading: false,
            loading2: false
        }
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.isLogin)
        })
    }
    componentDidMount() {

    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onChange2(index, name, arrname, e) {
        let that = this
        console.log(index, name, arrname)
        let value = e.target.value
        var arr = that.state.condition[arrname].concat()
        arr[index] = Object.assign({}, arr[index], { [name]: value })
        let data = Object.assign({}, this.state.condition, { [arrname]: arr })
        this.setState({
            condition: data
        })
    }
    openChange(index, arrname, e) {
        let that = this
        var arr = that.state.condition[arrname].concat()
        arr[index] = Object.assign({}, arr[index], { isopen: !arr[index]['isopen'] })
        let data = Object.assign({}, this.state.condition, { [arrname]: arr })
        this.setState({
            condition: data
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={Number(item.id)} key={index}>{item.name}</Option>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }
    sub() {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Rizhi/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg);
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }
    getdata = () => {
        let that = this
        fetch('/Gzx/getrw', {
            method: "POST",
            body: JSON.stringify({
                uid: this.state.condition.uid
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    that.setState({
                        condition: data.data,
                    })
                } else {
                    message.error(data.msg)
                    that.mdchange()
                }
                console.log(data)
            })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata()
        } else {
            that.setState({
                condition: {
                    uid: store.getState().data.info.id ? store.getState().data.info.id : 0,
                    picfile: [],
                    ywc: [],
                    wwc: []
                },
                loading: false
            })
        }
    }
    arrfile(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <div className='item' key={index}>
                    <div className='l'><PaperClipOutlined /><a href={item.url} target="_blank">{item.name}</a></div>
                    <div className='r'><CloseOutlined onClick={this.delfile.bind(this, index)} /></div>
                </div>
            )
        }
    }
    delfile(index, e) {
        console.log(index, e)
        var picfile = this.state.condition.picfile
        picfile.splice(index, 1)
        let data = Object.assign({}, this.state.condition, { picfile: picfile })
        this.setState({
            condition: data,
        })
    }
    // 上传图片
    picChange(info) {
        // console.log(info)
        if (info.file.status === 'uploading') {
            this.setState({ loading2: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            // console.log(info.file.response)

            var picfile = this.state.condition.picfile
            picfile = picfile.concat(info.file.response)
            let data = Object.assign({}, this.state.condition, { picfile: picfile })
            this.setState({
                condition: data,
                loading2: false,
            })
        }
    };
    arrwork(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <div className='item' key={index}>
                    <div className='p1'>
                        <div className='l'>
                            <Space size={40}>
                                <div className='a1'>
                                    <Space>
                                        {item.iswc == 1 ? <CheckCircleOutlined className='pic' /> : null}
                                        <div className='name'>
                                            <Gzxview id={item.id} name={item.name} refresh={this.getdata.bind(this)} typename={item.iswc == 1 ? '已完成' : '未完成'} />
                                        </div>
                                        {item.isjj == 1 ? <div className='jj'>紧急</div> : null}
                                    </Space>
                                </div>
                                <div className='a2'>{item.proname}</div>
                                <div className='a3'>{item.time}</div>
                            </Space>
                        </div>
                        <div className='r'>
                            <PlusOutlined onClick={this.openChange.bind(this, index, item.arrname)} />
                        </div>
                    </div>
                    {item.isopen ?
                        <div className='p2'>
                            <TextArea rows={4} placeholder="请输入" value={item.msg ? item.msg : null} onChange={this.onChange2.bind(this, index, 'msg', item.arrname)} disabled={true} />
                        </div>
                        : null}
                </div>
            )
        }
    }
    render() {
        return (
            <>
                <div className='item' onClick={this.mdchange.bind(this)}><FormOutlined />编辑日志</div>
                <Modal title="编辑日志" width={1000} bodyStyle={{ height: 'calc(100vh - 200px)', overflow: 'auto' }} visible={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 8 }}
                        wrapperCol={{ span: 24 }}
                        autoComplete="off"
                        className='addview'

                    >
                        <Row gutter={[20, 0]} justify="space-between">
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="今日完成工作"
                                    required={true}
                                >
                                    <div className='work w1'>
                                        {this.arrwork(this.state.condition.ywc)}
                                    </div>
                                    {/* <TextArea rows={4} placeholder="请输入" value={this.state.condition.ywc_e ? this.state.condition.ywc_e : null} onChange={this.onChange.bind(this, 'ywc_e')} /> */}
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="未完成工作"
                                    required={true}
                                >
                                    <div className='work w2'>
                                        {this.arrwork(this.state.condition.wwc)}
                                    </div>
                                    {/* <TextArea rows={4} placeholder="请输入" value={this.state.condition.wwc_e ? this.state.condition.wwc_e : null} onChange={this.onChange.bind(this, 'wwc_e')} /> */}
                                </Form.Item>
                            </Col>
                            {/* <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="需协调工作"
                                >
                                    <TextArea rows={2} placeholder="请输入" value={this.state.condition.xxtgz ? this.state.condition.xxtgz : null} onChange={this.onChange.bind(this, 'xxtgz')} />
                                </Form.Item>
                            </Col> */}
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="备注"
                                >
                                    <TextArea rows={4} placeholder="请输入" value={this.state.condition.bz ? this.state.condition.bz : null} onChange={this.onChange.bind(this, 'bz')} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="附件"
                                >
                                    <Dragger
                                        disabled={this.state.loading2}
                                        name="file"
                                        listType="picture-card"
                                        className="fj-uploader"
                                        showUploadList={false}
                                        action="/Common/uploadFile"
                                        // beforeUpload={beforeUpload}
                                        onChange={this.picChange.bind(this)}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                                        <p className="ant-upload-hint">
                                            支持扩展名：.rar .zip .doc .docx .pdf .jpg...
                                        </p>
                                    </Dragger>
                                    <div className='fujian'>
                                        {this.arrfile(this.state.condition.picfile)}
                                    </div>
                                </Form.Item>
                            </Col>
                            {/* {store.getState().data.info.type == 1 ?
                                <Col className="gutter-row" span={24}>
                                    <Form.Item
                                        label="状态"
                                    >
                                        <Radio.Group onChange={this.onChange.bind(this, 'status')} value={this.state.condition.status ? this.state.condition.status : []}>
                                            <Radio value={1}>已读</Radio>
                                            <Radio value={2}>未读</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                : null
                            } */}
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    wrapperCol={{ offset: 11 }}
                                >
                                    <Button type="primary" onClick={this.sub.bind(this)} loading={this.state.loading}>提交</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </>
        )
    }
}