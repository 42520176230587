import React from 'react';
import { Modal, Button, Form, Input, message, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import UploadFile from './Upload.js';
const { TextArea } = Input;
const { Option } = Select;
class EditMsg extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            confirmLoading: false,
            id: props.msgid ? props.msgid : '',
            title:'',
            content:'',
            fileid:[],
            fileList: [],
            userlist: [],
            jsid:[]
        }
    }
    componentDidMount() {
        // console.log(this.props)
        // this.setState({
        //     userlist: this.props.userlist,
        // })
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.msgid) {
            this.setState({
                id: nextProps.msgid,
            })
        }
        this.setState({
            userlist: nextProps.userlist,
        })
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
        console.log(this.state.id);
        if (this.state.id){
            fetch('/Message/view', {
                method: "POST",
                body: JSON.stringify({
                    'id': this.state.id,
                })
            })
                .then(res => res.json())
                .then(data => {
                    // console.log(data);
                    this.setState({

                    })
                })
        }
        
    };
    handleOk = () => {
        if (this.state.title === '') {
            message.warning('请填写标题');
            return false;
        }
        this.setState({
            confirmLoading: true,
        });
        fetch('/Message/doview', {
            method: "POST",
            body: JSON.stringify({
                // 'id': this.state.id,
                'title': this.state.title,
                'content': this.state.content,
                'fileid': this.state.fileid,
                'jsid': this.state.jsid,
            })
        })
            .then(res => res.json())
            .then(data => {
                this.props.uplist()
                setTimeout(() => {
                    this.setState({
                        visible: false,
                        confirmLoading: false,
                    });
                }, 1000);
            })
    };
    onchange = e => {
        // console.log(e.target.name, e.target.value);
        this.setState({
            [e.target.name]: e.target.value,
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false,
            id: '',
            title: '',
            content: '',
            fileid: [],
            fileList: [],
            jsid:[]
        });
    };
    getfileIdList = (arr) => {
        console.log(arr);
        this.setState({
            fileid: arr,
        });
    }
    handleChange = (value) => {
        this.setState({
            jsid: value,
        })
    }
    render() {
        const layout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        };
        const { visible, confirmLoading, ModalText } = this.state;
        return (
            <>
                <Button type="primary" style={{ marginLeft: 10 }} icon={<EditOutlined />} onClick={this.showModal}>
                    发送新消息
                </Button>
                <Modal
                    title="站内信"
                    centered
                    visible={visible}
                    onOk={this.handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <div>
                        <Form
                            {...layout}
                            name="msg"
                        >
                            <Form.Item
                                label="标题"
                            >
                                <Input name="title" value={this.state.title} onChange={this.onchange} />
                            </Form.Item>
                            <Form.Item
                                label="内容"
                            >
                                <TextArea
                                    name="content"
                                    value={this.state.content}
                                    onChange={this.onchange}
                                    placeholder="请输入"
                                    autoSize={{ minRows: 5, maxRows: 8 }}
                                />
                            </Form.Item>
                            <Form.Item
                                label="附件"
                            >
                                <UploadFile action="/Common/uploadFile" getfileIdList={this.getfileIdList} defaultlist={this.state.fileList} />
                            </Form.Item>
                            <Form.Item
                                label="收信人"
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                    value={this.state.jsid}
                                    onChange={this.handleChange}
                                >
                                    {
                                        this.state.userlist.map((list, index) =>
                                           {return <Option value={list.id} key={index}>{list.name}</Option>}
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>
            </>
        );
    }
}
export default EditMsg;