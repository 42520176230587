import React from 'react';
import { Divider, Select, Button, Radio, message, Switch, Modal, Badge } from 'antd';
import { ZoomInOutlined, EditOutlined} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import MessageTable from "./messageTable.js";
import EditMsg from './EditMsg.js';
import store from "../store/store.js";
const { Option } = Select;
class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isopen: false,
            userlist:[],
            type: store.getState().data.info.type == 1? 1 : 2,
        }
        store.subscribe(() => {
            // console.log('state状态改变了，新状态如下')
            // console.log(store.getState().data.info.type)
        })
    }
    //筛选全部工资单
    msglist = () => {
        fetch('/Message/index', {
            method: "POST",
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data);
                this.setState({
                    num: data.num,
                    msglist: data.msglist,
                    userlist: data.alluser,
                })
            })
    }
    //获取登录账号的工资单
    usermsglist = () => {
        fetch('/Message/index', {
            method: "POST",
            body: JSON.stringify({ 'id': store.getState().data.info.id })
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    num: data.num,
                    msglist: data.msglist,
                    userlist: data.alluser,
                })
            })
    }
    componentDidMount() {
        if (this.state.type == 1) {
            this.msglist();
        }
        if (this.state.type == 2) {
            this.usermsglist();
        }
    }
    changelist = (type) => {
        if (type == 1){
            this.msglist();
        }
        if (type == 2) {
            this.usermsglist();
        }
        this.setState({ 'type': type });
    }
    render() {
        return (
            <div>
                {
                    store.getState().data.info.type == 1?
                    <>
                        <div style={{ float: "left", marginBottom: 15 }}>
                                <Button type={this.state.type == 1 ?'primary':null} onClick={() => { this.changelist(1);}} style={{marginRight:15}}>全部消息</Button>
                                {/* <Badge count={this.state.num} overflowCount={10} size="small" offset={[-2, 1]}> */}
                                    <Button type={this.state.type == 2 ? 'primary' : null} onClick={() => { this.changelist(2);}}>我的消息</Button>
                                {/* </Badge> */}
                        </div>
                        {this.state.type==1?
                            <div style={{ float: "right", marginBottom: 15 }}>
                                {/* <EditMsg userlist={this.state.userlist} uplist={this.msglist} /> */}
                                    <Link to={{ pathname: '/messageview' }} ><Button type="primary" style={{ marginLeft: 10 }} icon={<EditOutlined />} >发送新消息</Button></Link>
                            </div>
                            :
                            null
                        }
                    </>
                    :
                    null
                }
                <MessageTable msglist={this.state.msglist} type={this.state.type} uplist={this.msglist} uplist2={this.usermsglist} />
            </div>
        )
    }

}
export default Index;