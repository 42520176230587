import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Form, Modal, Checkbox, Space, Tooltip, DatePicker, TimePicker, Select, message, Switch, InputNumber, Upload } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, UploadOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import store from "../store/store.js";
const { Option } = Select;
const { TextArea } = Input;
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
class Baoxiao extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            yearlist: [],
            arrbxstatus: [],
            fileList: [],
        }
        store.subscribe(() => {
            // console.log('state状态改变了，新状态如下')
            // console.log(store.getState().data.info.type)
        })
    }
    componentDidMount() {
        this.getlist()
    }
    getlist() {
        let that = this
        fetch('/Baoxiao/getlist', {
            method: "POST",
            body: JSON.stringify({ 'id': that.props.info.id })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                that.setState({
                    data: data.data,
                    yearlist: data.yearlist,
                    arrbxstatus: data.arrbxstatus
                })
            })
    }
    state = {
        searchText: '',
        searchedColumn: '',
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`请输入`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        搜索
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        重置
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    //发票状态
    arrbxstatus() {
        return (
            this.state.arrbxstatus.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    // 弹出框 开始
    showModal = () => {
        this.setState({
            visible: true,
        });
    };
    //关闭表单
    handleCancel = () => {
        this.setState({
            visible: false,
            fileList: []
        });
    };
    editbx = id => {
        // console.log(id);
        if (id) {
            fetch('/Baoxiao/view', {
                method: "POST",
                body: JSON.stringify({
                    id: id,
                })
            })
                .then(res => res.json())
                .then(data => {
                    // console.log(data.data);
                    if (data.data.status) {
                        this.formRef.current.setFieldsValue({
                            status: parseInt(data.data.status),
                        })
                    }
                    if (data.data.info) {
                        this.formRef.current.setFieldsValue({
                            info: data.data.info
                        })
                    }
                    if (data.data.fileList.length) {
                        this.setState({
                            fileList: data.data.fileList
                        })
                    }
                    this.formRef.current.setFieldsValue({
                        id: id,
                        uid: data.data.uid
                    })
                })

        }
        this.showModal();
    }
    // picChange = ({ fileList }) => this.setState({ fileList });
    picChange = (info) => {
        const { status } = info.file;
        // console.log(status);
        if (status === 'removed') {
            this.setState({
                fileList: info.fileList
            })
        }
        if (status === 'uploading') {
            let fileList = this.state.fileList.concat()
            // console.log(fileList);
            this.setState({ fileList: info.fileList })
        } else if (status === 'done') {
            info.file.response['uid'] = info.file.response['id'];
            info.file.response['status'] = 'done';
            // info.file.response['url']=info.file.response['url'].substr(1)
            let fileList = this.state.fileList.concat()
            let index = this.state.fileList.length - 1
            fileList[index] = info.file.response
            this.setState({ fileList: fileList })
        }
    }
    onFinish = (values) => {
        let that = this
        // console.log(values);
        // return false;
        this.setState({
            confirmLoading: true,
        });
        fetch('/Baoxiao/doview', {
            method: "POST",
            body: JSON.stringify({
                info: values,
                piclist: that.state.fileList
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data);return false;
                if (data.status == 1) {
                    message.success(data.msg);
                    this.getlist()
                    setTimeout(() => {
                        this.setState({
                            visible: false,
                            confirmLoading: false,
                        });
                    }, 1000);
                } else {
                    message.error(data.msg);
                }

            })
    };
    render() {
        var columns = []
        {
            store.getState().data.info.id != 4 ?
            columns = [
                {
                    title: '姓名',
                    dataIndex: 'name',
                    width: 150,
                    fixed: 'left',
                    key: 'name',
                    ...this.getColumnSearchProps('name')
                },
                {
                    title: '公司',
                    dataIndex: 'company',
                    width: 200,
                    key: 'company',
                    ...this.getColumnSearchProps('company')
                },
                {
                    title: '报销总金额（元）',
                    dataIndex: 'jine',
                    editable: true,
                    width: 150,
                    key: 'jine',
                    ...this.getColumnSearchProps('jine')
                },
                {
                    title: '年份',
                    dataIndex: 'year',
                    editable: true,
                    width: 100,
                    key: 'year',
                    filters: this.state.yearlist,
                    // specify the condition of filtering result
                    // here is that finding the name started with `value`
                    onFilter: (value, record) => record.year.indexOf(value) === 0,
                },
                {
                    title: '月份',
                    dataIndex: 'month',
                    editable: true,
                    width: 100,
                    key: 'month',
                    filters: [
                        {
                            text: '一月',
                            value: '01',
                        },
                        {
                            text: '二月',
                            value: '02',
                        },
                        {
                            text: '三月',
                            value: '03',
                        },
                        {
                            text: '四月',
                            value: '04',
                        },
                        {
                            text: '五月',
                            value: '05',
                        },
                        {
                            text: '六月',
                            value: '06',
                        },
                        {
                            text: '七月',
                            value: '07',
                        },
                        {
                            text: '八月',
                            value: '08',
                        },
                        {
                            text: '九月',
                            value: '09',
                        },
                        {
                            text: '十月',
                            value: '10',
                        },
                        {
                            text: '十一月',
                            value: '11',
                        },
                        {
                            text: '十二月',
                            value: '12',
                        },
                    ],
                    onFilter: (value, record) => record.month.indexOf(value) === 0,
                },
                {
                    title: '提交时间',
                    dataIndex: 'addsj',
                    editable: true,
                    width: 200,
                    key: 'addsj',
                    ...this.getColumnSearchProps('addsj')
                },
                {
                    title: '状态',
                    dataIndex: 'zhuangtai',
                    editable: true,
                    width: 100,
                    key: 'zhuangtai',
                    filters: [
                        {
                            text: '通过',
                            value: '通过',
                        },
                        {
                            text: '拒绝',
                            value: '拒绝',
                        },
                        {
                            text: '撤销',
                            value: '撤销',
                        },
                        {
                            text: '已报',
                            value: '已报',
                        },
                    ],
                    onFilter: (value, record) => record.zhuangtai.indexOf(value) === 0,
                },
                {
                    title: '操作',
                    dataIndex: 'operation',
                    width: 100,
                    fixed: 'right',
                    render: (value, record) => {
                        return (<a onClick={() => this.editbx(record.id)}>查看</a>)
                    },
                },
            ]
            :
            columns = [
                {
                    title: '姓名',
                    dataIndex: 'name',
                    // width: 150,
                    fixed: 'left',
                    key: 'name',
                    ...this.getColumnSearchProps('name')
                },
                {
                    title: '报销总金额（元）',
                    dataIndex: 'jine',
                    editable: true,
                    // width: 150,
                    key: 'jine',
                    ...this.getColumnSearchProps('jine')
                },
                {
                    title: '年份',
                    dataIndex: 'year',
                    editable: true,
                    // width: 100,
                    key: 'year',
                    filters: this.state.yearlist,
                    // specify the condition of filtering result
                    // here is that finding the name started with `value`
                    onFilter: (value, record) => record.year.indexOf(value) === 0,
                },
                {
                    title: '月份',
                    dataIndex: 'month',
                    editable: true,
                    // width: 100,
                    key: 'month',
                    filters: [
                        {
                            text: '一月',
                            value: '01',
                        },
                        {
                            text: '二月',
                            value: '02',
                        },
                        {
                            text: '三月',
                            value: '03',
                        },
                        {
                            text: '四月',
                            value: '04',
                        },
                        {
                            text: '五月',
                            value: '05',
                        },
                        {
                            text: '六月',
                            value: '06',
                        },
                        {
                            text: '七月',
                            value: '07',
                        },
                        {
                            text: '八月',
                            value: '08',
                        },
                        {
                            text: '九月',
                            value: '09',
                        },
                        {
                            text: '十月',
                            value: '10',
                        },
                        {
                            text: '十一月',
                            value: '11',
                        },
                        {
                            text: '十二月',
                            value: '12',
                        },
                    ],
                    onFilter: (value, record) => record.month.indexOf(value) === 0,
                },
                {
                    title: '提交时间',
                    dataIndex: 'addsj',
                    editable: true,
                    // width: 200,
                    key: 'addsj',
                    ...this.getColumnSearchProps('addsj')
                },
                {
                    title: '状态',
                    dataIndex: 'zhuangtai',
                    editable: true,
                    // width: 100,
                    key: 'zhuangtai',
                    filters: [
                        {
                            text: '通过',
                            value: '通过',
                        },
                        {
                            text: '拒绝',
                            value: '拒绝',
                        },
                        {
                            text: '撤销',
                            value: '撤销',
                        },
                        {
                            text: '已报',
                            value: '已报',
                        },
                    ],
                    onFilter: (value, record) => record.zhuangtai.indexOf(value) === 0,
                },
                {
                    title: '操作',
                    dataIndex: 'operation',
                    // width: 100,
                    fixed: 'right',
                    render: (value, record) => {
                        return (<a onClick={() => this.editbx(record.id)}>查看</a>)
                    },
                },
            ]
        }
        //弹出框内容 开始
        const { visible, confirmLoading, ModalText } = this.state;
        const layout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        };
        const tailLayout = {
            wrapperCol: { offset: 4, span: 20 },
        };
        //弹出框内容 结束
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}></div>
            </div>
        );
        return <>
            {/* 弹出框 */}
            <Modal
                title="报销信息"
                visible={visible}
                onOk={this.handleOk}
                centered
                confirmLoading={confirmLoading}
                onCancel={this.handleCancel}
                footer={null}
            >
                <div>
                    <Form
                        {...layout}
                        name="bxinfo"
                        ref={this.formRef}
                        initialValues={{
                            status: 1,
                        }}
                        onFinish={this.onFinish}
                    >
                        <Form.Item name="id" label="id" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="uid" label="uid" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="报销明细" >
                            <Form.List name="info"  >
                                {(fields, { add, remove }) => (
                                    <div>
                                        {fields.map(field => (
                                            <div key={field.key} style={{ width: '100%', display: "flex", flexDirection: 'column', }}>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'jine']}
                                                    fieldKey={[field.fieldKey, 'jine']}
                                                    rules={[{ required: true, message: '请输入' }]}
                                                >
                                                    <InputNumber
                                                        style={{ width: '100%' }}
                                                        placeholder="报销金额"
                                                        formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'leibie']}
                                                    fieldKey={[field.fieldKey, 'leibie']}
                                                    rules={[{ required: true, message: '请输入' }]}
                                                >
                                                    <Input placeholder="报销类别" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'mingxi']}
                                                    fieldKey={[field.fieldKey, 'mingxi']}
                                                >
                                                    <TextArea placeholder="费用明细" />
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(field.name)} style={{ marginBottom: 20 }} />
                                            </div>
                                        ))}
                                        <Form.Item style={{ marginBottom: 0 }}>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                添加明细
                                            </Button>
                                        </Form.Item>
                                    </div>
                                )}
                            </Form.List>
                        </Form.Item>

                        <Form.Item label="附件" >
                            <Upload
                                action="/Common/uploadFile"
                                listType="picture-card"
                                showUploadList={{ 'showDownloadIcon': true, 'showRemoveIcon': true, 'showPreviewIcon': false }}
                                fileList={this.state.fileList}
                                onChange={this.picChange}
                            >
                                {uploadButton}
                            </Upload>
                        </Form.Item>
                        {store.getState().data.info.type == 1 ?
                            <Form.Item name="status" label="状态" >
                                <Select placeholder="请选择" >
                                    {this.arrbxstatus()}
                                </Select>
                            </Form.Item>
                            :
                            null
                        }
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                提交
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            <Table
                style={{ marginBottom: 15 }}
                bordered
                dataSource={this.state.data}
                columns={columns}
                pagination={false}
                rowClassName={(record, index) => record.status == 4 ? 'fc' : ''}
                // scroll={{ x: 2500 }}
                summary={
                    pageData => {
                        let totaljine = 0;
                        pageData.forEach(({ jine, status }) => {
                            if (status == 4) {
                                totaljine += Number(jine);
                            }
                        });
                        return (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell>总计</Table.Summary.Cell>
                                    {store.getState().data.info.id != 4 ?
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        :
                                        null
                                    }
                                    <Table.Summary.Cell>
                                        {totaljine.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                </Table.Summary.Row>
                            </>
                        );
                    }
                }
            />
        </>
    }
}
export default Baoxiao;