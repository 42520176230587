import { connect } from 'react-redux';

//=====引入组件=====
import SiderDemo from '../../home/mobileHome.js'


//=====react-redux 封装组件=====

// 哪些 Redux 全局的 state 是我们组件想要通过 props 获取的？
function mapStateToProps(state) {
    return {
        isLogin: state.data.isLogin,
        info: state.data.info,
        nav: state.data.nav,
        navname: state.data.navname,
    }
}

// 哪些 action 创建函数是我们想要通过 props 获取的？
function mapDispatchToProps(dispatch) {
    return {
        OUT_LOGIN: function () {
            setTimeout(function () {
                dispatch({ type: "OUT_LOGIN" })
                // history.push("/login")
                // history.push("/")
            }, 0)

        },
        GET_INFO: function () {
            let action = {
                type: "GET_INFO",
                // isLogin: isLogin,
                // info: info,
            }
            dispatch(action);
        },
        NAV_CHANGE: function (nav, navname) {
            let action = {
                type: "NAV_CHANGE",
                nav: nav,
                navname: navname,
            }
            // console.log(action);
            dispatch(action);
        }
    };
}

//封装传递state和dispatch
var MobileHomeAction = connect(mapStateToProps, mapDispatchToProps)(SiderDemo);

export default MobileHomeAction