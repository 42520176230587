import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { DatePicker, Space, Select, Input, Button, Table, Modal, Divider, Row, Col, Form, Radio, Upload, message, Drawer, Avatar, Tabs, Checkbox, Popconfirm } from 'antd';
import store from "../store/store.js";
import { PaperClipOutlined, UserOutlined, TagsOutlined, CloseOutlined, ClockCircleOutlined, DownloadOutlined, InboxOutlined, DeleteOutlined } from '@ant-design/icons'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import moment from 'moment';
const { Option } = Select;
const { Dragger } = Upload;
const { TextArea } = Input;
const { TabPane } = Tabs;
export default class View extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
                name: props.name ? props.name : '',
                fjlist: [],
                istb: 2
            },
            visible: false,
            loading: false,
            loading2: false
        }
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.isLogin)
        })
    }
    componentDidMount() {
        // console.log(this.props)
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onSelChange2(index, value) {
        let that = this
        console.log(index, value)
        let ulistinfo = this.state.condition.ulistinfo.concat()
        ulistinfo[index] = Object.assign({}, ulistinfo[index], { zw: value })
        let data = Object.assign({}, this.state.condition, { ulistinfo: ulistinfo })
        this.setState({
            condition: data
        })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onCheckboxChange(name, e) {
        let that = this
        let checked = e.target.checked
        // console.log(name, value)
        if (checked) {
            var value = 1
        } else {
            var value = 2
        }
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={Number(item.id)} key={index}>{item.name}</Option>
            )
        }
    }
    arrsel2(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={Number(item.id)} key={index} disabled={this.state.condition.ulist.includes(Number(item.id)) ? true : false}>{item.name}</Option>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }
    sub() {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Gzx/doadd', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg);
                    that.props.refresh()
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }
    getdata = () =>{
        let that = this
        fetch('/Gzx/view', {
            method: "POST",
            body: JSON.stringify({
                id: this.state.condition.id
            })
        })
            .then(res => res.json())
            .then(data => {
                // if (this.state.condition.id) {
                if (data.status == 1) {
                    // data.data.status=Number(data.data.status);
                    that.setState({
                        condition: data.data,
                    })
                } else {
                    message.error(data.msg)
                    that.mdchange()
                }
                // }
                console.log(data)
            })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata()
        } else {
            that.setState({
                condition: {
                    id: this.props.id ? this.props.id : 0,
                    name: this.props.name ? this.props.name : '',
                    fjlist: [],
                    istb: 2,
                },
                visible: false,
                loading: false,
                loading2: false
            })
        }
    }
    delfile(index, e) {
        console.log(index, e)
        var fjlist = this.state.condition.fjlist
        fjlist.splice(index, 1)
        let data = Object.assign({}, this.state.condition, { fjlist: fjlist })
        this.setState({
            condition: data,
        })
    }
    arrfjlist(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <div className='item' key={index}>
                    <div className='l'><PaperClipOutlined /><a href={item.url} target="_blank">{item.name}</a></div>
                    <div className='r'><CloseOutlined onClick={this.delfile.bind(this, index)} /></div>
                </div>
            )
        }
    }
    // 上传图片
    picChange(info) {
        // console.log(info)
        if (info.file.status === 'uploading') {
            this.setState({ loading2: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            console.log(info.file.response)

            var fjlist = this.state.condition.fjlist
            fjlist = fjlist.concat(info.file.response)
            let data = Object.assign({}, this.state.condition, { fjlist: fjlist })
            this.setState({
                condition: data,
                loading2: false,
            })
        }
    };
    arrfile(arr,type) {
        if (arr) {
            return arr.map((item, index) =>
                <div className='item' key={index}>
                    <div className='l'>
                        <div className='pic'>
                            {(item.ext == 'doc' || item.ext == 'docx') ? <i className='iconfont' style={{ color: 'rgb(20,169,218)' }}>&#xe66e;</i> : ''}
                            {(item.ext == 'xls' || item.ext == 'xlsx') ? <i className='iconfont' style={{ color: 'rgb(69,176,88)' }}>&#xe665;</i> : ''}
                            {(item.ext == 'ppt' || item.ext == 'pptx') ? <i className='iconfont' style={{ color: 'rgb(227,66,33)' }}>&#xe66b;</i> : ''}
                            {(item.ext == 'jpg' || item.ext == 'jpge' || item.ext == 'png') ? <i className='iconfont' style={{ color: 'rgb(73,201,167)' }}>&#xe667;</i> : ''}
                            {(item.ext == 'pdf') ? <i className='iconfont' style={{ color: 'rgb(140,24,26)' }}>&#xe66a;</i> : ''}
                            {(item.ext == 'psd') ? <i className='iconfont' style={{ color: 'rgb(12,119,198)' }}>&#xe66c;</i> : ''}
                            {(item.ext == 'txt') ? <i className='iconfont' style={{ color: 'rgb(249,202,6)' }}>&#xe66d;</i> : ''}
                            {(item.ext == 'zip' || item.ext == 'rar') ? <i className='iconfont' style={{ color: 'rgb(129,153,175)' }}>&#xe8b7;</i> : ''}
                        </div>
                        <div className='info'>
                            <div className='p1'>{item.name}</div>
                            <div className='p2'>{item.daxiao} {item.aname ? ('来自 ' + item.aname) : ''}</div>
                        </div>
                    </div>
                    <div className='r r1'>
                        {item.time}
                    </div>
                    <div className='r r2'>
                        <Space>
                            <a href={item.url} target="_blank" download={item.name2}><DownloadOutlined /></a>
                            {item.aid==store.getState().data.info.id?
                                <Popconfirm
                                    title="确定删除?"
                                    onConfirm={this.delfj.bind(this,type,item.id,item.pgid)}
                                    okText="确定"
                                    cancelText="取消"
                                >
                                    <a href='javascript:void(0)' className='del'><DeleteOutlined /></a>
                                </Popconfirm>
                                :null
                            }
                        </Space>
                    </div>
                </div>
            )
        }
    }
    delfj = (type,id,pgid) =>{
        let that = this
        console.log(type,id,pgid)
        fetch('/Common/delfl', {
            method: "POST",
            body: JSON.stringify({
                type: type,
                id: id,
                pgid: pgid,
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                if (data.status == 1) {
                    message.success(data.msg)
                    that.getdata()
                } else {
                    message.error(data.msg)
                }
            })
            
    }
    render() {

        return (
            <>
                <a className='l' onClick={this.mdchange.bind(this)}>{this.state.condition.name}</a>
                <Drawer
                    title={<><span className={'titletype t' + this.props.type}>{this.props.typename}</span></>}
                    width={800}
                    visible={this.state.visible}
                    onClose={this.mdchange.bind(this)}
                // bodyStyle={{ padding: 0 }}
                >
                    <div className='gzxinfo'>
                        <div className='p1'>{this.state.condition.name}</div>
                        <div className='p2'>
                            <TagsOutlined /> {this.state.condition.protitle}
                        </div>
                        <div className='p3'>
                            <ClockCircleOutlined className='biao' />{this.state.condition.time1 ? (this.state.condition.time1 + '~') : ''} {this.state.condition.time2}
                        </div>
                        <div className='p4'>
                            <div className='l'>
                                <Space wrap>
                                    {this.state.condition.ulist ? this.state.condition.ulist.map((item, index) => (
                                        <div className={'bqitem ' + (item.status == 1 ? 'iconfont hover' : '')} key={index}>{item.msg}</div>
                                    )) : ''}
                                </Space>
                            </div>
                            <div className='r'>
                                <Space wrap>
                                    {this.state.condition.ulist ? this.state.condition.ulist.map((item, index) => (
                                        <div className={'txitem ' + (item.status == 1 ? 'iconfont hover' : '')} key={index}>
                                            <Avatar icon={<UserOutlined />} src={item.picurl} />
                                        </div>
                                    )) : ''}

                                </Space>
                            </div>
                        </div>
                        <div className='p5'>描述：
                            <TextArea rows={6} placeholder="请输入" value={this.state.condition.miaoshu ? this.state.condition.miaoshu : null} onChange={this.onChange.bind(this,'miaoshu')} autoComplete="off" />
                            {/* <div dangerouslySetInnerHTML={{ __html: this.state.condition.miaoshu }}></div> */}
                        </div>
                    </div>
                    <Divider />
                    <div className='fjlist fjlist2'>
                        <div className='item'>
                            <div className='fjtitle'><PaperClipOutlined /> 项目附件</div>
                            <div className='list'>
                                {this.arrfile(this.state.condition.fjlist1,1)}
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div className='fjlist fjlist2'>
                        <div className='item'>
                            <div className='fjtitle'><PaperClipOutlined /> 工作项附件</div>
                            <div className='list'>
                                {this.arrfile(this.state.condition.fjlist2,2)}
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <Form
                        // layout="vertical"
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 20 }}
                    >
                        <Form.Item
                            label={<>上传附件</>}
                        >
                            <Dragger
                                disabled={this.state.loading2}
                                name="file"
                                listType="picture-card"
                                className="fj-uploader"
                                showUploadList={false}
                                action="/Common/uploadFile"
                                // beforeUpload={beforeUpload}
                                onChange={this.picChange.bind(this)}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                                <p className="ant-upload-hint">
                                    支持扩展名：.rar .zip .doc .docx .pdf .jpg...
                                </p>
                            </Dragger>
                            <div className='fujian'>
                                {this.arrfjlist(this.state.condition.fjlist)}
                            </div>
                            <Checkbox onChange={this.onCheckboxChange.bind(this, 'istb')} checked={this.state.condition.istb == 1 ? true : false}>将附件同步至项目资料库</Checkbox>
                        </Form.Item>
                        <Form.Item
                            label={<>备注</>}
                        >
                            <TextArea rows={4} placeholder="请输入" value={this.state.condition.beizhu ? this.state.condition.beizhu : null} onChange={this.onChange.bind(this, 'beizhu')} autoComplete="off" />
                        </Form.Item>
                        <Form.Item
                            // label="参与人"
                            wrapperCol={{ offset: 3 }}
                        >
                            <Button type="primary" loading={this.state.loading} onClick={this.sub.bind(this)}>提交</Button>
                        </Form.Item>
                    </Form>
                </Drawer>
            </>
        )
    }
}