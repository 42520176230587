import React from 'react';
import { Modal, Button, Form, Input, message, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Redirect } from 'react-router'
import Editor from './editor.js';
import store from "../store/store.js";
const { TextArea } = Input;
const { Option } = Select;
class Messageview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ok:0,
            id: this.props.match.params.id ? this.props.match.params.id : 0,
            title: '',
            content: '',
            defaultcon: '',
            userlist: [],
            jsid: []
        }
    }
    componentWillMount() {
        this.msgok();
        console.log(this.props.match.params.id);
        fetch('/Message/view', {
            method: "POST",
            body: JSON.stringify({
                'id': this.state.id,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data);
                this.setState({
                    title: data.title,
                    content: data.content,
                    userlist: data.alluser,
                    defaultcon: data.content,
                    jsid: data.jsid,
                    send: data.send,
                })
            })
    }
    componentWillReceiveProps(nextProps) {
        // if (nextProps.msgid) {
        //     this.setState({
        //         id: nextProps.msgid,
        //     })
        // }
        // this.setState({
        //     userlist: nextProps.userlist,
        // })
    }
    handleOk = () => {
        if (this.state.title === '') {
            message.warning('请填写标题');
            return false;
        }
        this.setState({
            confirmLoading: true,
        });
        fetch('/Message/doview', {
            method: "POST",
            body: JSON.stringify({
                'id': this.state.id,
                'title': this.state.title,
                'content': this.state.content,
                'fileid': this.state.fileid,
                'jsid': this.state.jsid,
            })
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    ok: 1,
                })
            })
    };
    msgok = () => {
        fetch('/Message/msgok', {
            method: "POST",
            body: JSON.stringify({
                'id': this.props.match.params.id ? this.props.match.params.id : 0,
            })
        })
            .then(res => res.json())
            .then(data => {
            })
    }
    onchange = e => {
        // console.log(e.target.name, e.target.value);
        this.setState({
            [e.target.name]: e.target.value,
        })
    }
    handleChange = (value) => {
        this.setState({
            jsid: value,
        })
    }
    Getcon(content) {
        this.setState({
            content: content
        })
    }
    render() {
        const layout = {
            labelCol: { span: 1 },
            wrapperCol: { span: 23 },
        };
        const tailLayout = {
            wrapperCol: { offset: 1, span: 23 },
        };
        if(this.state.ok){
            return <Redirect to={'/message'} />;
        }
        return (
            store.getState().data.ismobile===false ? 
            <>
                <Form
                    {...layout}
                    name="msg"
                >
                    <Form.Item
                        label="标题"
                    >
                        <Input name="title" value={this.state.title} onChange={this.onchange} />
                    </Form.Item>
                    <Form.Item
                        label="内容"
                    >
                        <Editor getcon={this.Getcon.bind(this)} pid={this.state.id} mrCon={this.state.defaultcon} disabled={this.state.id ? true : false} /> 
                    </Form.Item>
                    {
                        this.state.id ?
                        <Form.Item
                            label="发送人"
                        >
                            {this.state.send}
                        </Form.Item>
                        :
                        null
                    }
                    {
                        store.getState().data.info.type == 1 ?
                            <Form.Item
                                label="收信人"
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                    value={this.state.jsid}
                                    onChange={this.handleChange}
                                // disabled={this.state.id ? true : false}
                                >
                                    {
                                        this.state.userlist.map((list, index) => { return <Option value={list.id} key={index}>{list.name}</Option> }
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        :
                        null
                    }
                    
                    <Form.Item
                        {...tailLayout}
                    >
                        {this.props.match.params.id ? null : <Button type="primary" onClick={this.handleOk.bind(this)}>提交</Button>}
                    </Form.Item>
                </Form>

            </>
            :
            <>
                <table className="ant-table grinfo" style={{ marginBottom: 0, }}>
                    <tbody>
                        <tr>
                            <td className="ant-table-cell" width="100">
                                标题
                            </td>
                            <td className="ant-table-cell">
                                {this.state.title}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell" colspan="2">
                                内容
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell" colspan="2" dangerouslySetInnerHTML={{ __html: this.state.defaultcon }}>
                                
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell">
                                发送人
                            </td>
                            <td className="ant-table-cell">
                                {this.state.send}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </>

        );
    }
}
export default Messageview;