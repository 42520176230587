import React from 'react';
import { Result, Button } from 'antd';
import {Link} from 'react-router-dom';
class Error extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Result
                status = "404"
                title = "404"
                subTitle= "对不起,您访问的页面不存在。"
                extra={<a href="javascript:history.go(-1)"><Button type="primary" >返回上一页</Button></a>}
            />
        )
    }
}
export default Error;