import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { DatePicker, Space, Select, Input, Button, Table, Pagination, Row, Tag, Modal, Tooltip, Popconfirm, Tabs, Avatar, message } from 'antd';
import View from "../xiangmu/view";
import store from "../store/store.js";
import { UserOutlined } from '@ant-design/icons'
import moment from 'moment';
const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
                listtype: "2",
            },
            oldcondition: {},
            list: [],
            user_react: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.isLogin)
        })
    }
    componentDidMount() {
        this.getdata(1)
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, { [name]: dateString })
        this.setState({
            newcondition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onRangeChange(e, dates, dateStrings) {
        console.log(dates, dateStrings)
        let data = Object.assign({}, this.state.newcondition, { starttime: dateStrings[0], endtime: dateStrings[1] })
        this.setState({
            newcondition: data
        })
    }
    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, { current: page, pagesize: pagesize })
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }
    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    getdata = (subtype = 0, e) => {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, { current: 1, pagesize: old.pagesize })
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('/Xiangmu/xmtj', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count),
                    xm_react: data.xm_react
                })
            })
    }
    delSel() {
        let that = this
        // fetch('/Rizhi/delSel', {
        //     method: "POST",
        //     body: JSON.stringify({
        //         'arrid': this.state.selectedRowKeys,
        //     })
        // })
        //     .then(res => res.json())
        //     .then(data => {
        //         // console.log(data)
        //         this.pagechange(1, this.state.oldcondition.pagesize)
        //         that.setState({
        //             selectedRowKeys: []
        //         })
        //     })
    }
    download() {
        let that = this
        that.setState({
            downloading: true
        })
        var arr = that.state.oldcondition
        arr.download = 1
        fetch('/Xiangmu/xmtj', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg);
                    return
                }
                console.log(data)
                that.setState({
                    downloading: false
                })
                try {
                    var elemIF = document.createElement("iframe");
                    elemIF.src = data.url;
                    elemIF.style.display = "none";
                    document.body.appendChild(elemIF);
                } catch (e) {
                    alert("下载异常！");
                }
            })
    }
    render() {
        const columns = [
            {
                title: '项目名称',
                dataIndex: 'xmmc',
                key: 'xmmc',
                // width: 100,
                ellipsis: {
                    showTitle: false,
                },
                // render: (row, record) => (
                //     <Tooltip placement="topLeft" title={row}>
                //         <View id={record.xmid} listtype="1" text={row} refresh={this.getdata.bind(0,this)} />
                //     </Tooltip>
                // ),
                render: (row, record, index) => {
                    return {
                        children: row,
                        props: {
                            rowSpan: record.xmmcRow ? record.xmmcRow : 0,
                            align: 'center'
                        }
                    }
                },
            },
            {
                title: '时间范围',
                dataIndex: 'during',
                key: 'during',
                // width: 100,
                ellipsis: {
                    showTitle: false,
                },
                // render: (row, record) => (
                //     <Tooltip placement="topLeft" title={row}>
                //         <View id={record.xmid} listtype="1" text={row} refresh={this.getdata.bind(0,this)} />
                //     </Tooltip>
                // ),
                render: (row, record, index) => {
                    return {
                        children: row,
                        props: {
                            rowSpan: record.xmmcRow ? record.xmmcRow : 0,
                            align: 'center'
                        }
                    }
                },
            },
            {
                title: '工作项名称',
                dataIndex: 'gzxmc',
                key: 'gzxmc',
                // width: 40,
                render: (row, record, index) => {
                    return {
                        children: row,
                        props: {
                            rowSpan: record.gzxRow ? record.gzxRow : 0,
                            align: 'center'
                        }
                    }
                },
            },
            {
                title: '参与人',
                dataIndex: 'name',
                key: 'name',
                // width: 40,

            },
            {
                title: '工时',
                dataIndex: 'time',
                key: 'time',
                // width: 50,
            },
        ]
        const { tableloading, selectedRowKeys } = this.state;
        // const rowSelection = {
        //     selectedRowKeys,
        //     onChange: this.onSelectChange.bind(this),
        //     // hideSelectAll: true,
        //     preserveSelectedRowKeys: false,
        // };
        // const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <div className='sellist'>
                    <Row justify="space-between" >
                        <Space wrap>
                            {/* {hasSelected ?
                                <Popconfirm
                                    title="确认删除?"
                                    onConfirm={this.delSel.bind(this)}
                                    okText="是"
                                    cancelText="否"
                                >
                                    <Button type="primary" danger>删除</Button>
                                </Popconfirm>
                                : null} */}
                            <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'setime')} value={[(this.state.newcondition.starttime ? moment(this.state.newcondition.starttime) : null), (this.state.newcondition.endtime ? moment(this.state.newcondition.endtime) : null)]} />
                            <Select
                                mode="multiple"
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                style={{ width: 350 }}
                                placeholder="项目名称"
                                maxTagCount="responsive"
                                value={this.state.newcondition.xmmc ? this.state.newcondition.xmmc : []}
                                onChange={this.onSelChange.bind(this, 'xmmc')}
                            >
                                {this.arrsel(this.state.xm_react)}
                            </Select>
                            {/* <Input type='text' placeholder='项目名称' onChange={this.onChange.bind(this, 'xmmc')} value={this.state.newcondition.xmmc ? this.state.newcondition.xmmc : null} /> */}
                            <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                        </Space>
                        <Space wrap>
                            <Button type="primary" onClick={this.download.bind(this)}>导出</Button>
                        </Space>
                    </Row>
                </div>
                <Table
                    bordered
                    columns={columns}
                    dataSource={this.state.list}
                    pagination={false}
                    loading={tableloading}
                    // rowSelection={rowSelection}
                    className='table'
                // scroll={{x:2000}}
                />
                {/* <div className='page'>
                    <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 10} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />
                </div> */}
            </>
        )
    }
}