import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { DatePicker, Space, Select, Input, Button, Table, Pagination, Row, Tag, Modal, Tooltip, Popconfirm,Tabs,Avatar } from 'antd';
import Add from "./addxm";
import View from "./view";
import store from "../store/store.js";
import { UserOutlined } from '@ant-design/icons'
import moment from 'moment';
const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
                listtype: "2",
            },
            oldcondition: {},
            list: [],
            user_react: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.isLogin)
        })
    }
    componentDidMount() {
        this.getdata(0)
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, { [name]: dateString })
        this.setState({
            newcondition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
        if (name == 'listtype') {
            setTimeout(() => {
                that.getdata(1, null)
            })
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onRangeChange(e, dates, dateStrings) {
        console.log(dates, dateStrings)
        let data = Object.assign({}, this.state.newcondition, { starttime: dateStrings[0], endtime: dateStrings[1] })
        this.setState({
            newcondition: data
        })
    }
    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, { current: page, pagesize: pagesize })
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }
    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    getdata = (subtype = 0, e) => {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, { current: 1, pagesize: old.pagesize })
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('/Xiangmu/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count)
                })
            })
    }
    delSel() {
        let that = this
        // fetch('/Rizhi/delSel', {
        //     method: "POST",
        //     body: JSON.stringify({
        //         'arrid': this.state.selectedRowKeys,
        //     })
        // })
        //     .then(res => res.json())
        //     .then(data => {
        //         // console.log(data)
        //         this.pagechange(1, this.state.oldcondition.pagesize)
        //         that.setState({
        //             selectedRowKeys: []
        //         })
        //     })
    }
    render() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                align: 'center',
                width: 20,
                fixed:'left'
            },
            {
                title: '项目名称',
                dataIndex: 'xmmc',
                key: 'xmmc',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        <View id={record.id} listtype="1" text={row} refresh={this.getdata.bind(0,this)} />
                    </Tooltip>
                ),
                fixed:'left'
            },
            {
                title: '创建人',
                dataIndex: 'aname',
                key: 'aname',
                width: 40,
            },
            {
                title: '状态',
                dataIndex: 'zt',
                key: 'zt',
                width: 30,
                align: 'center',
                render: (row, record) => {
                    return (
                        <>
                            {record.nowstatus == 0 ? <Tag color="green">{row}</Tag> : null}
                            {record.nowstatus == 1 ? <Tag color="blue">{row}</Tag> : null}
                            {record.nowstatus == 2 ? <Tag color="yellow">{row}</Tag> : null}
                            {record.nowstatus == 3 ? <Tag color="orange">{row}</Tag> : null}
                            {record.nowstatus == 4 ? <Tag color="red">{row}</Tag> : null}
                        </>
                    )
                },
            },
            {
                title: '计划开始时间',
                dataIndex: 'starttime_str',
                key: 'starttime_str',
                width: 50,
            },
            {
                title: '预计截止日期',
                dataIndex: 'endtime_str',
                key: 'endtime_str',
                width: 50,
            },
            {
                title: '任务时间',
                dataIndex: 'rwsj',
                key: 'rwsj',
                width: 100,
            },
            {
                title: '最长占比人员',
                dataIndex: 'zczbname',
                key: 'zczbname',
                width: 40,
                render: (row, record) => {
                    return (
                        <>
                            <Space>
                                <Avatar icon={<UserOutlined />} src={record.picurl} /> <span>{row}</span>
                            </Space>
                        </>
                    )
                },
            },
            {
                title: '项目附件',
                dataIndex: 'fjnum',
                key: 'fjnum',
                align: 'center',
                width: 40,
                render: (row, record) => {
                    return (
                        <>
                            <Space>
                                <View id={record.id} listtype="2" text={row} refresh={this.getdata.bind(0,this)} />
                            </Space>
                        </>
                    )
                },
            },
            {
                title: '工作项',
                dataIndex: 'gzxnum',
                key: 'gzxnum',
                align: 'center',
                width: 40,
                render: (row, record) => {
                    return (
                        <>
                            <Space>
                                <View id={record.id} listtype="1" text={row} refresh={this.getdata.bind(0,this)} />
                            </Space>
                        </>
                    )
                },
            },
        ]
        const { tableloading, selectedRowKeys } = this.state;
        // const rowSelection = {
        //     selectedRowKeys,
        //     onChange: this.onSelectChange.bind(this),
        //     // hideSelectAll: true,
        //     preserveSelectedRowKeys: false,
        // };
        // const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
            
                <Tabs activeKey={this.state.newcondition.listtype ? this.state.newcondition.listtype : 2} onChange={this.onSelChange.bind(this, 'listtype')}>
                    <TabPane tab="进行中" key="2">
                    </TabPane>
                    <TabPane tab={<>已完成{this.state.count2 ? <font color="#027DB4">+{this.state.count2}</font> : ''}</>} key="1">
                    </TabPane>
                </Tabs>
                <div className='sellist'>
                    <Row justify="space-between" >
                        <Space wrap>
                            {/* {hasSelected ?
                                <Popconfirm
                                    title="确认删除?"
                                    onConfirm={this.delSel.bind(this)}
                                    okText="是"
                                    cancelText="否"
                                >
                                    <Button type="primary" danger>删除</Button>
                                </Popconfirm>
                                : null} */}
                            <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'setime')} value={[(this.state.newcondition.starttime ? moment(this.state.newcondition.starttime) : null), (this.state.newcondition.endtime ? moment(this.state.newcondition.endtime) : null)]} />
                            <Input type='text' placeholder='项目名称' onChange={this.onChange.bind(this, 'xmmc')} value={this.state.newcondition.xmmc ? this.state.newcondition.xmmc : null}  />
                            <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                            <Add refresh={this.getdata.bind(this)} />
                        </Space>
                    </Row>
                </div>
                <Table
                    bordered
                    columns={columns}
                    dataSource={this.state.list}
                    pagination={false}
                    loading={tableloading}
                    // rowSelection={rowSelection}
                    className='table'
                    scroll={{x:2000}}
                />
                <div className='page'>
                    <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 10} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />
                </div>
            </>
        )
    }
}