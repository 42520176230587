import React from 'react'
import BraftEditor from 'braft-editor'
import { ContentUtils } from 'braft-utils'
import { ImageUtils } from 'braft-finder'
import { Upload } from 'antd'
import { PictureOutlined } from '@ant-design/icons';
import 'braft-editor/dist/index.css'
function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}
export default class Editor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props['pid'],
            editorState: BraftEditor.createEditorState(props.mrCon),
            disabled: props.disabled,
        }
    }
    clearContent = () => {
        this.setState({
            editorState: ContentUtils.clear(this.state.editorState)
        })
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.mrCon != nextProps.mrCon){
            this.setState({
                editorState: BraftEditor.createEditorState(nextProps.mrCon),
            })
        }
    }
    uploadHandler = (param) => {
        if (param.file.status != 'done') {
            return false
        }
        getBase64(param.file.originFileObj, imageUrl =>
            this.setState({
                editorState: ContentUtils.insertMedias(this.state.editorState, [{
                    type: 'IMAGE',
                    url: param.file.response
                }])
            })
        );
    }
    componentWillMount() {

    }
    handleChange = (editorState) => {
        this.setState({ editorState })
        this.props.getcon(editorState.toHTML());
    }

    render() {
        //多媒体上传
        const myUploadFn = (param) => {

            const serverURL = '/Common/uploadFile2'
            const xhr = new XMLHttpRequest
            const fd = new FormData()

            const successFn = (response) => {
                // 假设服务端直接返回文件上传后的地址
                // 上传成功后调用param.success并传入上传后的文件地址
                let reg = /["\\]/g
                var url = xhr.responseText.replace(reg, "");
                param.success({
                    // url: xhr.responseText,
                    url: url,
                    meta: {
                        // id: 'xxx',
                        // title: 'xxx',
                        // alt: 'xxx',
                        loop: false, // 指定音视频是否循环播放
                        autoPlay: false, // 指定音视频是否自动播放
                        controls: true, // 指定音视频是否显示控制栏
                        poster: '', // 指定视频播放器的封面
                    }
                })
            }

            const progressFn = (event) => {
                // 上传进度发生变化时调用param.progress
                param.progress(event.loaded / event.total * 100)
            }

            const errorFn = (response) => {
                // 上传发生错误时调用param.error
                param.error({
                    msg: 'unable to upload.'
                })
            }

            xhr.upload.addEventListener("progress", progressFn, false)
            xhr.addEventListener("load", successFn, false)
            xhr.addEventListener("error", errorFn, false)
            xhr.addEventListener("abort", errorFn, false)

            fd.append('file', param.file)
            xhr.open('POST', serverURL, true)
            xhr.send(fd)

        }
        const extendControls = [
            {
                key: 'clear-editor',
                type: 'button',
                text: '清空编辑器',
                onClick: this.clearContent
            }, {
                key: 'antd-uploader',
                type: 'component',
                component: (
                    <Upload
                        accept="image/*"
                        showUploadList={false}

                        onChange={this.uploadHandler}
                        action="/Common/uploadFile2"
                    >
                        {/* 这里的按钮最好加上type="button"，以避免在表单容器中触发表单提交，用Antd的Button组件则无需如此 */}
                        <button type="button" className="control-item button upload-button" data-title="插入图片">
                            <PictureOutlined />
                        </button>
                    </Upload>
                )
            }
        ]

        return (
            <BraftEditor
                value={this.state.editorState}
                onChange={this.handleChange}
                extendControls={extendControls}
                style={{ border: '1px solid #e8e8e8' }}
                media={{ uploadFn: myUploadFn }}
                // disabled={this.state.disabled}
            />
        )

    }

}