import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { DatePicker, Space, Select, Input, Button, Table, Modal, Divider, Row, Col, Form, Radio, Upload, message, List } from 'antd';
import store from "../store/store.js";
import { InboxOutlined, LinkOutlined, ScanOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons'
import moment from 'moment';
const { Option } = Select;
const { Dragger } = Upload;
const { TextArea } = Input;
export default class Add extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id,
            },
            visible: false,
            loading: false,
            loading2: false
        }
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.isLogin)
        })
    }
    componentDidMount() {

    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={Number(item.id)} key={index}>{item.name}</Option>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }
    sub() {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Qingtiao/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg);
                    this.props.refresh()
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            // if(this.state.condition.id){
            fetch('/Qingtiao/view', {
                method: "POST",
                body: JSON.stringify({
                    id: this.state.condition.id
                })
            })
                .then(res => res.json())
                .then(data => {
                    if (this.state.condition.id) {
                        if (data.status == 1) {
                            data.data.status = Number(data.data.status);
                            that.setState({
                                condition: data.data,
                                nowstatus: data.data.status
                            })
                        } else {
                            message.error(data.msg)
                            that.mdchange()
                        }
                    }
                    console.log(data)
                })
            // }
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                },
                loading: false
            })
        }
    }


    render() {
        return (
            <>
                {this.state.condition.id ? <a href='javascript:void(0)' type="link" onClick={this.mdchange.bind(this)} >编辑</a> : ''}
                <Modal title="编辑" width={800} visible={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 8 }}
                        wrapperCol={{ span: 24 }}
                        autoComplete="off"
                        className='addview'

                    >
                        <Row gutter={[20, 0]} justify="space-between">
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="姓名"
                                >
                                    {this.state.condition.name ? this.state.condition.name : null}
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="类型"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.typename ? this.state.condition.typename : null} onChange={this.onChange.bind(this, 'typename')} disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="日期"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.time ? this.state.condition.time : null} onChange={this.onChange.bind(this, 'time')} disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="请假天数"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.days ? this.state.condition.days : null} onChange={this.onChange.bind(this, 'days')} disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="请假原因"
                                >
                                    <TextArea rows={8} placeholder="请输入" value={this.state.condition.reason ? this.state.condition.reason : null} onChange={this.onChange.bind(this, 'reason')} disabled={true} />
                                </Form.Item>
                            </Col>
                            {this.state.nowstatus == -1 ?
                                <Col className="gutter-row" span={24}>
                                    <Form.Item
                                        label="状态"
                                    >
                                        <Radio.Group onChange={this.onChange.bind(this, 'status')} value={this.state.condition.status ? this.state.condition.status : []}>
                                            <Radio value={1}>通过</Radio>
                                            <Radio value={2}>拒绝</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                : null
                            }
                            {this.state.condition.status == 2 ?
                                <Col className="gutter-row" span={24}>
                                    <Form.Item
                                        label="拒绝原因"
                                    >
                                        <TextArea rows={8} placeholder="请输入" value={this.state.condition.jjreason ? this.state.condition.jjreason : null} onChange={this.onChange.bind(this, 'jjreason')} disabled={this.state.nowstatus == -1?false:true} />
                                    </Form.Item>
                                </Col>
                                : null}

                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    wrapperCol={{ offset: 10 }}
                                >
                                    <Button type="primary" onClick={this.sub.bind(this)} loading={this.state.loading}>提交</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </>
        )
    }
}