import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Divider, Select, Button, Radio, message, Switch, Modal,Upload } from 'antd';
import { ZoomInOutlined, UploadOutlined,AreaChartOutlined,DownloadOutlined,SnippetsOutlined } from '@ant-design/icons';
import List from "./table.js";
import Uploadfile from "./uploadfile.js";
import store from "../store/store.js";
const { Option } = Select;
class Project extends React.Component {
    constructor(props) {
        super(props);
        const myDate = new Date();
        this.state = {
            data:[]
        }
        store.subscribe(() => {
            // console.log('state状态改变了，新状态如下')
            // console.log(store.getState().data.info.type)
        })
    }

    componentDidMount() {
        this.getlist()
    }
    getlist = () =>{
        fetch('/Project/index', {
            method: "POST",
        })
        .then(res => res.json())
        .then(data => {
            console.log(data)
            this.setState({
                data: data,
            })
        })
    }
    render(){
        return (
            <>
                <div className="table_title" >
                    <div className="l"></div>
                    <div className="r">
                        {/* <Button type="primary" icon={<UploadOutlined />} style={{marginRight:15}} >上传</Button> */}
                        <Link to={{ pathname: '/proview' }} style={{marginRight:15}}><Button type="primary">添加</Button></Link>
                        <Uploadfile shuaxin={this.getlist.bind(this)}  action="/Project/uploadFile" />
                    </div>
                </div>
                <List data={this.state.data} shuaxin={this.getlist.bind(this)} />
            </>
        )
    }
        
}
export default Project;