import React from 'react';
import User2 from '../user/newlist';
import store from "../store/store.js";
// store.dispatch({type: 'NAV_CHANGE', nav: '/userlist', navname: '员工管理' });
class Userlist extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            yglist1: [],
            yglist2: [],
            yglist3: [],
            postype: [],
            companytype: [],
            contracttype: [],
            arrhktype:[],
            hktype: [],
        }
        store.subscribe(() => {
            // console.log('state状态改变了，新状态如下')
            // console.log(store.getState().data.info.type)
        })
    }
    componentDidMount() {
        this.sellist();
        // setTimeout(() => {
        //     this.aa()
        // }, 1000);
    }
    aa(){
        this.setState({
            data: store.getState().data
        })
        if (store.getState().data.info.type!=1) {
            this.props.history.push("/userview");
        }
    }
    sellist = () =>{
        fetch('/User/index', {
            method: "POST",
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    yglist: data.userlist,
                    // yglist1: data.userlist1,
                    // yglist2: data.userlist2,
                    // yglist3: data.userlist3,
                    companytype: data.companytype,
                    contracttype: data.contracttype,
                    arrhktype: data.arrhktype,
                })
            })
    }
    render() {
        // if (this.state.data.info === undefined) {
        //     return <Redirect to={'/userview'} />;
        // } else {
        //     if (this.state.data.info.type != 1) {
        //         return <Redirect to={'/userview'} />;
        //     }
        // }
        return (
            <>
                <User2 yglist={this.state.yglist} companytype={this.state.companytype} contracttype={this.state.contracttype} arrhktype={this.state.arrhktype} updatelist={this.sellist} />
            </>
        )
    }
}
export default Userlist;