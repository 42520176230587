import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Form, Modal, Checkbox, Space, Tooltip, DatePicker, TimePicker, Select, message, Switch } from 'antd';
import Highlighter from 'react-highlight-words';
import { RadarChartOutlined } from '@ant-design/icons';
import moment from 'moment';
import store from "../store/store.js";
class Tongji extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible:false,
            ulist:[],
            starttime:'',
            endtime:'',
            data:[],
        }
        store.subscribe(() => {
            // console.log('state状态改变了，新状态如下')
            // console.log(store.getState().data.info.type)
        })
    }
    componentDidMount(){
        
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            ulist:nextProps.ulist,
            starttime:nextProps.starttime,
            endtime:nextProps.endtime,
        })
    }
    openModal = e => {
        let that = this
        this.gettjlist()
        that.setState({
            isModalVisible: true
        })
    }
    handleCancel = () => {
        this.setState({
            isModalVisible:false,
            // data:[],
        })
    }
    gettjlist(){
        let that = this
        fetch('/Daka/gettjlist', {
            method: "POST",
            body: JSON.stringify({
                ulist:that.state.ulist,
                starttime:that.state.starttime,
                endtime:that.state.endtime,
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                that.setState({
                    data:data,
                })
            })
    }
    render() {
        var columns = [
            {
                title: '姓名',
                dataIndex: 'name',
                width: 100,
            },
            {
                title: '加班时长',
                dataIndex: 'jbsc',
                width: 100,
                render: (raw, record) => {
                    if(record.id==12){
                        return null
                    }else{
                        return raw
                    }
                },
            },
            {
                title: '迟到次数',
                dataIndex: 'cdcs',
                width: 100,
            },
            {
                title: '请假天数',
                dataIndex: 'qjts',
                width: 100,
            },
            {
                title: '加班天数',
                dataIndex: 'jbts',
                width: 100,
            },
            {
                title: '倒休天数',
                dataIndex: 'dxts',
                width: 100,
            },
            {
                title: '缺卡次数',
                dataIndex: 'qkcs',
                width: 100,
            },
        ]
        return <>
            <Button type="primary" onClick={this.openModal.bind(this)} icon={<RadarChartOutlined />} style={{marginLeft:15}}>统计</Button>
            <Modal title="统计" footer={null} width={900} visible={this.state.isModalVisible} onCancel={this.handleCancel.bind(this)}>
                <Table
                    bordered
                    dataSource={this.state.data}
                    columns={columns}
                    pagination={false}
                />
            </Modal>
        </>
    }
}
export default Tongji;