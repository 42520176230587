import React from 'react';
import { Divider, Select, Button, Radio, message, Switch, Modal,Upload,Input,DatePicker } from 'antd';
import { ZoomInOutlined, UploadOutlined,AreaChartOutlined,DownloadOutlined,SnippetsOutlined } from '@ant-design/icons';
import moment from 'moment';
import store from "../store/store.js";
const { Option } = Select;
class ProView extends React.Component {
    constructor(props) {
        super(props);
        const myDate = new Date();
        this.state = {
            id:this.props.match.params.id?this.props.match.params.id:'',
            isedit:false,
            arrprostatus:[],
            arrbastatus:[],
            isqd:0,
            status:0,
            bazt:0,
            data:[]
        }
        store.subscribe(() => {
            // console.log('state状态改变了，新状态如下')
            // console.log(store.getState().data.info.type)
        })
    }

    componentDidMount() {
        this.getpro();
    }
    editchange(){
        let isedit = !this.state.isedit;
        this.setState({
            isedit
        })
    }
    arrset(arr) {
        if(arr){
            return (
                arr.map((list, index) =>
                    <Option value={list.id} key={index}>{list.name}</Option>
                )
            )
        }
    }
    getarrinfo(arr, val) {
        if(arr){
            return (
                arr.map((list, index) =>
                    list.id == val ? list.name : null
                )
            )
        }
    }
    getpro = () =>{
        var id=this.state.id
        // if(id){
            fetch('/Project/getpro', {
                method: "POST",
                body: JSON.stringify({ 'id': id, })
            })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                if(data.status==1){
                    this.setState({
                        title: data.data.title,
                        jianshe: data.data.jianshe,
                        status: data.data.status,
                        isqd: data.data.isqd,
                        qdtime: data.data.qdtime,
                        zhouqi: data.data.zhouqi,
                        zaojia: data.data.zaojia,
                        city: data.data.city,
                        fwstart: data.data.fwstart,
                        fwend: data.data.fwend,
                        wcbl: data.data.wcbl,
                        xinghao: data.data.xinghao,
                        yfk: data.data.yfk,
                        yfbl: data.data.yfbl,
                        syje: data.data.syje,
                        jszc: data.data.jszc,
                        fwq: data.data.fwq,
                        ym: data.data.ym,
                        beizhu: data.data.beizhu,
                        wzym: data.data.wzym,
                        bazt: data.data.bazt,
                        xm: data.data.xm,
                        tel: data.data.tel,
                        email: data.data.email,
                    })
                }else{
                    if(id){
                        message.error(data.msg);
                        this.props.history.push('/proview')
                    }
                }
                this.setState({
                    arrprostatus:data.arrprostatus,
                    arrbastatus:data.arrbastatus,
                })
            })
        // }
    }
    sub = () =>{
        var id=this.state.id
        fetch('/Project/doadd', {
            method: "POST",
            body: JSON.stringify({ 
                'id': id?id:'', 
                'title': this.state.title,
                'jianshe': this.state.jianshe,
                'status': this.state.status,
                'isqd': this.state.isqd,
                'qdtime': this.state.qdtime,
                'zhouqi': this.state.zhouqi,
                'zaojia': this.state.zaojia,
                'city': this.state.city,
                'fwstart': this.state.fwstart,
                'fwend': this.state.fwend,
                'wcbl': this.state.wcbl,
                'xinghao': this.state.xinghao,
                'yfk': this.state.yfk,
                'yfbl': this.state.yfbl,
                'syje': this.state.syje,
                'jszc': this.state.jszc,
                'fwq': this.state.fwq,
                'ym': this.state.ym,
                'beizhu': this.state.beizhu,
                'wzym': this.state.wzym,
                'bazt': this.state.bazt,
                'xm': this.state.xm,
                'tel': this.state.tel,
                'email': this.state.email,
            })
        })
        .then(res => res.json())
        .then(data => {
            console.log(data)
            if(id){
                message.success('提交成功');
            }else{
                message.success('添加成功');
                this.props.history.push('/project')
            }
        })
        this.editchange()
    }
    onChange(e){
        console.log(e.target.name,e.target.value)
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    onselChange(name,value){
        this.setState({
            [name]: value
        })
    }
    ontimeChange(name,date, dateString) {
        this.setState({
            [name]: dateString
        })
    }
    render(){
        return (
            <>
                <div className="table_title">
                    <div className="l"></div>
                    <div className="r"><Button type="primary" onClick={this.state.isedit?this.sub.bind(this):this.editchange.bind(this)}>{this.state.isedit?'提交':'编辑'}</Button></div>
                </div>
                {this.state.isedit ? 
                    <>
                    <table className="ant-table grinfo">
                        <tbody>
                            <tr>
                                <td className="ant-table-cell title">项目名称</td>
                                <td className="ant-table-cell">
                                    <Input name="title" onChange={this.onChange.bind(this)} value={this.state.title} />
                                </td>
                                <td className="ant-table-cell title">建设单位/个人</td>
                                <td className="ant-table-cell">
                                    <Input name="jianshe" onChange={this.onChange.bind(this)} value={this.state.jianshe} />
                                </td>
                                <td className="ant-table-cell title">状态</td>
                                <td className="ant-table-cell">
                                    <Select name="status" style={{ width: '100%' }} onChange={this.onselChange.bind(this, 'status')} placeholder="请选择" value={this.state.status == 0 ? null : parseInt(this.state.status)}>
                                        {this.arrset(this.state.arrprostatus)}
                                    </Select>
                                </td>
                            </tr>
                            <tr>
                                <td className="ant-table-cell title">合同是否签订</td>
                                <td className="ant-table-cell">
                                    <Select name="isqd" style={{ width: '100%' }} onChange={this.onselChange.bind(this, 'isqd')} placeholder="请选择" value={this.state.isqd == 0 ? null : parseInt(this.state.isqd)}>
                                        <Option value={1} key="1">是</Option>
                                        <Option value={2} key="2">否</Option>
                                    </Select>
                                </td>
                                <td className="ant-table-cell title">项目签订时间</td>
                                <td className="ant-table-cell">
                                    <DatePicker name="qdtime" onChange={this.ontimeChange.bind(this, 'qdtime')} value={this.state.qdtime ? moment(this.state.qdtime) : null}  />
                                </td>
                                <td className="ant-table-cell title">服务周期</td>
                                <td className="ant-table-cell">
                                    <Input name="zhouqi" onChange={this.onChange.bind(this)} value={this.state.zhouqi} />
                                </td>
                            </tr>
                            <tr>
                                <td className="ant-table-cell title">项目造价（元）</td>
                                <td className="ant-table-cell">
                                    <Input name="zaojia" onChange={this.onChange.bind(this)} value={this.state.zaojia} />
                                </td>
                                <td className="ant-table-cell title">本市/外埠</td>
                                <td className="ant-table-cell">
                                    <Input name="city" onChange={this.onChange.bind(this)} value={this.state.city} />
                                </td>
                                <td className="ant-table-cell title">服务器型号</td>
                                <td className="ant-table-cell">
                                    <Input name="xinghao" onChange={this.onChange.bind(this)} value={this.state.xinghao} />
                                </td>
                            </tr>
                            <tr>
                                <td className="ant-table-cell title">技术维护服务时间(开始)</td>
                                <td className="ant-table-cell">
                                    <DatePicker name="fwstart" onChange={this.ontimeChange.bind(this, 'fwstart')} value={this.state.fwstart ? moment(this.state.fwstart) : null}  />
                                </td>
                                <td className="ant-table-cell title">技术维护服务时间(结束)</td>
                                <td className="ant-table-cell">
                                    <DatePicker name="fwend" onChange={this.ontimeChange.bind(this, 'fwend')} value={this.state.fwend ? moment(this.state.fwend) : null}  />
                                </td>
                                <td className="ant-table-cell title">项目完成比例%</td>
                                <td className="ant-table-cell">
                                    <Input name="wcbl" onChange={this.onChange.bind(this)} value={this.state.wcbl} />
                                </td>
                            </tr>
                            <tr>
                                <td className="ant-table-cell title">已付款（元）</td>
                                <td className="ant-table-cell">
                                    <Input name="yfk" onChange={this.onChange.bind(this)} value={this.state.yfk} />
                                </td>
                                <td className="ant-table-cell title">已付比例%</td>
                                <td className="ant-table-cell">
                                    <Input name="yfbl" onChange={this.onChange.bind(this)} value={this.state.yfbl} />
                                </td>
                                <td className="ant-table-cell title">剩余金额（元）</td>
                                <td className="ant-table-cell">
                                    <Input name="syje" onChange={this.onChange.bind(this)} value={this.state.syje} />
                                </td>
                            </tr>
                            <tr>
                                <td className="ant-table-cell title">维护费用-技术支持（元）</td>
                                <td className="ant-table-cell">
                                    <Input name="jszc" onChange={this.onChange.bind(this)} value={this.state.jszc} />
                                </td>
                                <td className="ant-table-cell title">维护费用-服务器（元）</td>
                                <td className="ant-table-cell">
                                    <Input name="fwq" onChange={this.onChange.bind(this)} value={this.state.fwq} />
                                </td>
                                <td className="ant-table-cell title">维护费用-域名（元）</td>
                                <td className="ant-table-cell">
                                    <Input name="ym" onChange={this.onChange.bind(this)} value={this.state.ym} />
                                </td>
                            </tr>
                            <tr>
                                <td className="ant-table-cell title">备注</td>
                                <td className="ant-table-cell">
                                    <Input name="beizhu" onChange={this.onChange.bind(this)} value={this.state.beizhu} />
                                </td>
                                <td className="ant-table-cell title">网站域名</td>
                                <td className="ant-table-cell">
                                    <Input name="wzym" onChange={this.onChange.bind(this)} value={this.state.wzym} />
                                </td>
                                <td className="ant-table-cell title">备案状态</td>
                                <td className="ant-table-cell">
                                    <Select name="bazt" style={{ width: '100%' }} onChange={this.onselChange.bind(this, 'bazt')} placeholder="请选择" value={this.state.bazt == 0 ? null : parseInt(this.state.bazt)}>
                                        {this.arrset(this.state.arrbastatus)}
                                    </Select>
                                </td>
                            </tr>
                            <tr>
                                <td className="ant-table-cell title">联系人姓名</td>
                                <td className="ant-table-cell">
                                    <Input name="xm" onChange={this.onChange.bind(this)} value={this.state.xm} />
                                </td>
                                <td className="ant-table-cell title">联系人电话</td>
                                <td className="ant-table-cell">
                                    <Input name="tel" onChange={this.onChange.bind(this)} value={this.state.tel} />
                                </td>
                                <td className="ant-table-cell title">联系人邮箱</td>
                                <td className="ant-table-cell">
                                    <Input name="email" onChange={this.onChange.bind(this)} value={this.state.email} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </>
                    :
                    <>
                    <table className="ant-table grinfo">
                        <tbody>
                            <tr>
                                <td className="ant-table-cell title">项目名称</td>
                                <td className="ant-table-cell">
                                    {this.state.title}
                                </td>
                                <td className="ant-table-cell title">建设单位/个人</td>
                                <td className="ant-table-cell">
                                    {this.state.jianshe}
                                </td>
                                <td className="ant-table-cell title">状态</td>
                                <td className="ant-table-cell">
                                    {this.getarrinfo(this.state.arrprostatus,this.state.status)}
                                </td>
                            </tr>
                            <tr>
                                <td className="ant-table-cell title">合同是否签订</td>
                                <td className="ant-table-cell">
                                    {this.state.isqd==1?'是':null}
                                    {this.state.isqd==2?'否':null}
                                </td>
                                <td className="ant-table-cell title">项目签订时间</td>
                                <td className="ant-table-cell">
                                    {this.state.qdtime}
                                </td>
                                <td className="ant-table-cell title">服务周期</td>
                                <td className="ant-table-cell">
                                    {this.state.zhouqi}
                                </td>
                            </tr>
                            <tr>
                                <td className="ant-table-cell title">项目造价（元）</td>
                                <td className="ant-table-cell">
                                    {this.state.zaojia}
                                </td>
                                <td className="ant-table-cell title">本市/外埠</td>
                                <td className="ant-table-cell">
                                    {this.state.city}
                                </td>
                                <td className="ant-table-cell title">服务器型号</td>
                                <td className="ant-table-cell">
                                    {this.state.xinghao}
                                </td>
                            </tr>
                            <tr>
                                <td className="ant-table-cell title">技术维护服务时间(开始)</td>
                                <td className="ant-table-cell">
                                    {this.state.fwstart}
                                </td>
                                <td className="ant-table-cell title">技术维护服务时间(结束)</td>
                                <td className="ant-table-cell">
                                    {this.state.fwend}
                                </td>
                                <td className="ant-table-cell title">项目完成比例%</td>
                                <td className="ant-table-cell">
                                    {this.state.wcbl}
                                </td>
                            </tr>
                            <tr>
                                <td className="ant-table-cell title">已付款（元）</td>
                                <td className="ant-table-cell">
                                    {this.state.yfk}
                                </td>
                                <td className="ant-table-cell title">已付比例%</td>
                                <td className="ant-table-cell">
                                    {this.state.yfbl}
                                </td>
                                <td className="ant-table-cell title">剩余金额（元）</td>
                                <td className="ant-table-cell">
                                    {this.state.syje}
                                </td>
                            </tr>
                            <tr>
                                <td className="ant-table-cell title">维护费用-技术支持（元）</td>
                                <td className="ant-table-cell">
                                    {this.state.jszc}
                                </td>
                                <td className="ant-table-cell title">维护费用-服务器（元）</td>
                                <td className="ant-table-cell">
                                    {this.state.fwq}
                                </td>
                                <td className="ant-table-cell title">维护费用-域名（元）</td>
                                <td className="ant-table-cell">
                                    {this.state.ym}
                                </td>
                            </tr>
                            <tr>
                                <td className="ant-table-cell title">备注</td>
                                <td className="ant-table-cell">
                                    {this.state.beizhu}
                                </td>
                                <td className="ant-table-cell title">网站域名</td>
                                <td className="ant-table-cell">
                                    {this.state.wzym}
                                </td>
                                <td className="ant-table-cell title">备案状态</td>
                                <td className="ant-table-cell">
                                    {this.getarrinfo(this.state.arrbastatus,this.state.bazt)}
                                </td>
                            </tr>
                            <tr>
                                <td className="ant-table-cell title">联系人姓名</td>
                                <td className="ant-table-cell">
                                    {this.state.xm}
                                </td>
                                <td className="ant-table-cell title">联系人电话</td>
                                <td className="ant-table-cell">
                                    {this.state.tel}
                                </td>
                                <td className="ant-table-cell title">联系人邮箱</td>
                                <td className="ant-table-cell">
                                    {this.state.email}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </>
                }
            </>
        )
    }
        
}
export default ProView;