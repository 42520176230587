import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { DatePicker, Space, Select, Input, Button, Table, Modal, Divider, Row, Col, Form, Radio, Upload, message, Drawer, Avatar, Tabs, Checkbox } from 'antd';
import store from "../store/store.js";
import { PaperClipOutlined, UserOutlined, TagsOutlined, CloseOutlined, ClockCircleOutlined, DownloadOutlined, InboxOutlined } from '@ant-design/icons'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import moment from 'moment';
const { Option } = Select;
const { Dragger } = Upload;
const { TextArea } = Input;
const { TabPane } = Tabs;
export default class View extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id:props.id?props.id:0,
                fjlist: [],
            },
            visible: false,
            loading: false,
            loading2: false
        }
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.isLogin)
        })
    }
    componentDidMount() {

    }
    componentWillReceiveProps(nextProps){

    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onSelChange2(index, value) {
        let that = this
        console.log(index, value)
        let ulistinfo = this.state.condition.ulistinfo.concat()
        ulistinfo[index] = Object.assign({}, ulistinfo[index], { zw: value })
        let data = Object.assign({}, this.state.condition, { ulistinfo: ulistinfo })
        this.setState({
            condition: data
        })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onCheckboxChange(name, e) {
        let that = this
        let checked = e.target.checked
        // console.log(name, value)
        if (checked) {
            var value = 1
        } else {
            var value = 2
        }
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={Number(item.id)} key={index}>{item.name}</Option>
            )
        }
    }
    arrsel2(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={Number(item.id)} key={index} disabled={this.state.condition.ulist.includes(Number(item.id)) ? true : false}>{item.name}</Option>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }
    sub() {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Xiangmu/doadd', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg);
                    this.props.refresh()
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            fetch('/Xiangmu/view1', {
                method: "POST",
                body: JSON.stringify({
                    id: this.state.condition.id
                })
            })
                .then(res => res.json())
                .then(data => {
                    // if (this.state.condition.id) {
                        if(data.status==1){
                            data.data.xmfzr=Number(data.data.xmfzr);
                            that.setState({
                                condition: data.data,
                                xmfzr_react:data.xmfzr_react
                            })
                        }else{
                            message.error(data.msg)
                            that.mdchange()
                        }
                    // }
                    console.log(data)
                })
        } else {
            that.setState({
                condition: {
                    id:this.props.id?this.props.id:0,
                    fjlist: [],
                },
                visible: false,
                loading: false,
                loading2: false
            })
        }
    }
    delfile(index, e) {
        console.log(index, e)
        var fjlist = this.state.condition.fjlist
        fjlist.splice(index, 1)
        let data = Object.assign({}, this.state.condition, { fjlist: fjlist })
        this.setState({
            condition: data,
        })
    }
    arrfjlist(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <div className='item' key={index}>
                    <div className='l'><PaperClipOutlined /><a href={item.url} target="_blank">{item.name}</a></div>
                    <div className='r'><CloseOutlined onClick={this.delfile.bind(this, index)} /></div>
                </div>
            )
        }
    }
    // 上传图片
    picChange(info) {
        // console.log(info)
        if (info.file.status === 'uploading') {
            this.setState({ loading2: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            // console.log(info.file.response)

            var fjlist = this.state.condition.fjlist
            fjlist = fjlist.concat(info.file.response)
            let data = Object.assign({}, this.state.condition, { fjlist: fjlist })
            this.setState({
                condition: data,
                loading2: false,
            })
        }
    };
    render() {

        return (
            <>  
                {this.props.id?
                    <a href='javascript:void(0)' onClick={this.mdchange.bind(this)}>{this.props.title}</a>
                :
                    <Button type="primary" onClick={this.mdchange.bind(this)}>新建项目</Button>
                }
                
                <Modal
                    title={this.props.id?'编辑项目':'新建项目'}
                    width={800}
                    footer={null}
                    visible={this.state.visible}
                    onCancel={this.mdchange.bind(this)}
                // bodyStyle={{ padding: 0 }}
                >
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 3 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Row gutter={[20, 0]} justify="space-between">
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="项目名称"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.xmmc ? this.state.condition.xmmc : null} onChange={this.onChange.bind(this, 'xmmc')} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="客户名称"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.khmc ? this.state.condition.khmc : null} onChange={this.onChange.bind(this, 'khmc')} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="项目负责人"
                                    required={true}
                                >
                                    <Select className='select' style={{ width: '100%' }} placeholder="请选择" value={this.state.condition.xmfzr ? this.state.condition.xmfzr : null} onChange={this.onSelChange.bind(this, 'xmfzr')} >
                                        {this.arrsel(this.state.xmfzr_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="计划开始时间"
                                    required={true}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'starttime')} value={this.state.condition.starttime ? moment(this.state.condition.starttime) : null} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="截止日期"
                                    // required={true}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'endtime')} value={this.state.condition.endtime ? moment(this.state.condition.endtime) : null} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label={<>上传附件</>}
                                >
                                    <Dragger
                                        disabled={this.state.loading2}
                                        name="file"
                                        listType="picture-card"
                                        className="fj-uploader"
                                        showUploadList={false}
                                        action="/Common/uploadFile"
                                        // beforeUpload={beforeUpload}
                                        onChange={this.picChange.bind(this)}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                                        <p className="ant-upload-hint">
                                            支持扩展名：.rar .zip .doc .docx .pdf .jpg...
                                        </p>
                                    </Dragger>
                                    <div className='fujian'>
                                        {this.arrfjlist(this.state.condition.fjlist)}
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    wrapperCol={{ offset: 11 }}
                                >
                                    <Button type="primary" onClick={this.sub.bind(this)} loading={this.state.loading}>提交</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </>
        )
    }
}