import React from 'react';
import { Input, Select, DatePicker, Button, Radio, message } from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined, DeleteOutlined} from '@ant-design/icons';
import moment from 'moment';
import store from "../store/store.js";
const { Option } = Select;
class Userview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openid:'',
            edit: 0,
            id: '',
            name: '',
            sex: 1,
            jijin: 1,
            company: 0,
            hktype: 0,
            contract: '',
            constart: '',
            conend: '',
            idcard: '',
            khbank: '',
            bank: '',
            banknum: '',
            address: '',
            tel: '',
            addtime: '',
            // fctime: '',
            // status: '',
            mz: 0,
            zzmm: 0,
            hkaddress: '',
            hkaddtime: '',
            hytype: '',
            xl: 0,
            xltype: 0,
            email: '',
            qq: '',
            jinjiname: '',
            jinjitel: '',
            jinjiaddress: '',
            companytype: [],
            contracttype: [],
            arrhktype: [],
            arrzzmmtype: [],
            arrmztype: [],
            arrhytype: [],
            arrxl: [],
            arrxltype: [],
            hktype: [],
            arrjybj: [
                {
                    from: '',
                    to: '',
                    schoolname: '',
                    major: '',
                    degree: '',
                }
            ],
            arrgzjl: [
                {
                    from: '',
                    to: '',
                    cpmpanyname: '',
                    dept: '',
                    position: '',
                }
            ],
            jkstatus: 0,
            arrjkstatus: [],
            tjjy: 0,
            isyingjie: 0,
            iskunnan: 0,
            iszyzg: 0,
            zyzg: 0,
            arrzyzg: [],
            zyzggettime: '',
            zyzglevel: 0,
            arrzyzglevel: [],
            iszyjs: 0,
            zyjsgettime: '',
            zyjsname: '',
            arrzyjsname: [],
            // data: store.getState().data.info
        }
        store.subscribe(() => {
            // console.log('state状态改变了，新状态如下')
            // console.log(store.getState().data.info.type)
            // this.getuser();
        })
    }
    componentDidMount() {
        this.setState({
            openid: this.props.match.params.openid,
        })
        if(this.props.match.params.openid){
          this.getuser();
        }
    }
    getuser = () => {
        var openid = this.props.match.params.openid;
        // if (!id) {
        //     id = store.getState().data.info.id;
        // }
        fetch('/Email/view', {
            method: "POST",
            body: JSON.stringify({ 'openid': openid, })
        })
            .then(res => res.json())
            .then(data => {
              console.log(data)
                this.setState({
                    openid: data.openid,
                    id: data.id,
                    // password: data.password,
                    name: data.name,
                    sex: data.sex,
                    idcard: data.idcard,
                    company: data.company,
                    khbank: data.khbank,
                    bank: data.bank,
                    banknum: data.banknum,
                    address: data.address,
                    tel: data.tel,
                    fctime: data.fctime,
                    addtime: data.addtime,
                    status: data.status,
                    contract: data.contract,
                    constart: data.constart,
                    conend: data.conend,
                    hktype: data.hktype,
                    fctime: data.fctime,
                    status: data.status,

                    mz: data.mz?data.mz:0,
                    zzmm: data.zzmm?data.zzmm:0,
                    hkaddress: data.hkaddress,
                    hkaddtime: data.hkaddtime,
                    hytype: data.hytype?data.hytype:0,
                    xl: data.xl?data.xl:0,
                    xltype: data.xltype?data.xltype:0,
                    email: data.email,
                    qq: data.qq,
                    jinjiname: data.jinjiname,
                    jinjitel: data.jinjitel,
                    jinjiaddress: data.jinjiaddress,

                    jkstatus: data.jkstatus?data.jkstatus:0,
                    zxqk: data.zxqk?data.zxqk:0,
                    tjjy: data.tjjy,
                    isyingjie: data.isyingjie?data.isyingjie:0,
                    iskunnan: data.iskunnan?data.iskunnan:0,
                    iszyzg: data.iszyzg,
                    zyzg: data.zyzg?data.zyzg:0,
                    zyzggettime: data.zyzggettime,
                    zyzglevel: data.zyzglevel?data.zyzglevel:0,
                    iszyjs: data.iszyjs,
                    zyjsgettime: data.zyjsgettime,
                    zyjsname: data.zyjsname,

                    companytype: data.companytype,
                    contracttype: data.contracttype,
                    arrhktype: data.arrhktype,
                    arrzzmmtype: data.arrzzmmtype,
                    arrmztype: data.arrmztype,
                    arrhytype: data.arrhytype,
                    arrxl: data.arrxl,
                    arrxltype: data.arrxltype,

                    arrjkstatus: data.arrjkstatus,
                    arrzyzg: data.arrzyzg,
                    arrzyzglevel: data.arrzyzglevel,
                    arrzyjsname: data.arrzyjsname,
                    isok: data.isok,
                })
                if (data.arrjybj) {
                    this.setState({
                        arrjybj: data.arrjybj,
                    })
                }
                if (data.arrgzjl) {
                    this.setState({
                        arrgzjl: data.arrgzjl,
                    })
                }
            })
    }
    getarrinfo(arr, val) {
        return (
            arr.map((list, index) =>
                // <Option value={list.id} key={index}>{list.name}</Option>
                list.id == val ? list.name : null
            )
        )
    }
    //公司分类
    arrcom() {
        return (
            this.state.companytype.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    //合同分类
    arrcontract() {
        return (
            this.state.contracttype.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    //户口分类
    arrhk() {
        return (
            this.state.arrhktype.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    //政治面貌
    arrzzmm() {
        return (
            this.state.arrzzmmtype.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    //民族
    arrmz() {
        return (
            this.state.arrmztype.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    //婚姻状况
    arrhy() {
        return (
            this.state.arrhytype.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    //学历
    arrxl() {
        return (
            this.state.arrxl.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    //学历类型
    arrxltype() {
        return (
            this.state.arrxltype.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    //健康状态
    arrjkstatus() {
        return (
            this.state.arrjkstatus.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    //职业(工种)资格
    arrzyzg() {
        return (
            this.state.arrzyzg.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    //职业资格等级
    arrzyzglevel() {
        return (
            this.state.arrzyzglevel.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    onselChange(name, value) {
        this.setState({
            [name]: value
        })
    }
    ontimeChange(name, date, dateString) {
        this.setState({
            [name]: dateString
        })
    }
    ontimeChange2(name, date, dateString) {
        this.setState({
            [name]: dateString
        })
    }
    inputChange(item, index, name, e) {
        // console.log("修改后的值为：", moment(e).format('YYYY-MM-DD HH:mm:ss'));
        // console.log("name：", name);
        var value = '';
        if (name == 'from' || name == 'to') {
            value = moment(e).format('YYYY-MM-DD');
            // console.log("修改后的值为：", value);
        }
        if (name != 'from' && name != 'to') {
            value = e.target.value
        }
        const { arrjybj } = this.state;
        arrjybj[index][name] = value;
        let newArr = arrjybj;
        this.setState({
            arrjybj: newArr
        })
    }
    deljybj(num, e) {
        const { arrjybj } = this.state;
        try {
            arrjybj.forEach((item, index) => {
                if (index == num) {
                    arrjybj[num] = null;
                    throw new Error(num);
                }
            });
        } catch (e) {
            // throw e;
            // console.log(arrjybj.filter(Boolean));
            this.setState({
                arrjybj: arrjybj.filter(Boolean)
            })
        }
    }
    addjybj() {
        const { arrjybj } = this.state;
        const newinfo = {
            from: '',
            to: '',
            schoolname: '',
            major: '',
            degree: '',
        }
        const newArr = arrjybj.concat(newinfo);
        // console.log(newArr);
        this.setState({
            arrjybj: newArr
        })
    }
    inputChange2(item, index, name, e) {
        // console.log("修改后的值为：", moment(e).format('YYYY-MM-DD HH:mm:ss'));
        // console.log("name：", name);
        var value = '';
        if (name == 'from' || name == 'to') {
            value = moment(e).format('YYYY-MM-DD');
            // console.log("修改后的值为：", value);
        }
        if (name != 'from' && name != 'to') {
            value = e.target.value
        }
        const { arrgzjl } = this.state;
        arrgzjl[index][name] = value;
        let newArr = arrgzjl;
        this.setState({
            arrgzjl: newArr
        })
    }
    delgzjl(num, e) {
        const { arrgzjl } = this.state;
        try {
            arrgzjl.forEach((item, index) => {
                if (index == num) {
                    arrgzjl[num] = null;
                    throw new Error(num);
                }
            });
        } catch (e) {
            // throw e;
            // console.log(arrgzjl.filter(Boolean));
            this.setState({
                arrgzjl: arrgzjl.filter(Boolean)
            })
        }
    }
    addgzjl() {
        const { arrgzjl } = this.state;
        const newinfo = {
            from: '',
            to: '',
            cpmpanyname: '',
            dept: '',
            position: '',
        }
        const newArr = arrgzjl.concat(newinfo);
        // console.log(newArr);
        this.setState({
            arrgzjl: newArr
        })
    }
    subinfo() {
        if(!this.state.openid){
            message.error('参数错误');return;
        }
        if(!this.state.name){
            message.error('请输入姓名');return;
        }
        if(!this.state.password){
            message.error('请输入密码');return;
        }
        if(!this.state.idcard){
            message.error('请输入身份证号');return;
        }
        if(!this.state.tel){
            message.error('请输入移动电话');return;
        }
        if(!this.state.mz){
            message.error('请选择民族');return;
        }
        if(!this.state.zzmm){
            message.error('请选择政治面貌');return;
        }
        if(!this.state.hytype){
            message.error('请选择婚姻状况');return;
        }
        if(!this.state.address){
            message.error('请输入居住地址 (详细到门牌号的地址)');return;
        }
        if(!this.state.xl){
            message.error('请选择最高学历');return;
        }
        if(!this.state.xltype){
            message.error('请选择学历类型');return;
        }
        if(!this.state.email){
            message.error('请输入邮箱');return;
        }
        if(!this.state.jinjiname){
            message.error('请输入紧急联系人姓名');return;
        }
        if(!this.state.jinjitel){
            message.error('请输入紧急联系人移动电话');return;
        }
        if(!this.state.jinjiaddress){
            message.error('请输入紧急联系地址');return;
        }
        if(this.state.tjjy==1){
            if(this.state.hktype==0){
                message.error('请选择户口性质');return;
            }
            if(!this.state.hkaddtime){
                message.error('请选择户口登记日期');return;
            }
            if(!this.state.hkaddress){
                message.error('请输入户口详细地址');return;
            }
            if(this.state.isyingjie==0){
                message.error('请选择是否应届毕业生');return;
            }
            if(this.state.iskunnan==0){
                message.error('请选择是否为困难毕业生');return;
            }
            if(this.state.jkstatus==0){
                message.error('请选择健康状态');return;
            }
        }
        let jybj = this.state.arrjybj
        for (const key in jybj) {
            const item = jybj[key];
            if(!item.from){
                message.error('请选择教育背景开始时间');return;
            }
            if(!item.to){
                message.error('请选择教育背景结束时间');return;
            }
            if(!item.schoolname){
                message.error('请输入学校名称');return;
            }
            if(!item.major){
                message.error('请输入专业');return;
            }
        }
        if(this.state.contract==2){
            if(!this.state.zxqk){
                message.error('请选择在校情况');return;
            }
        }
        if(!this.state.bank){
            message.error('请输入银行');return;
        }
        if(!this.state.khbank){
            message.error('请输入开户行全称');return;
        }
        if(!this.state.banknum){
            message.error('请输入银行卡号');return;
        }
        fetch('/Email/doview', {
            method: "POST",
            body: JSON.stringify({
                id: this.state.id,
                openid: this.state.openid,
                password: this.state.password,
                name: this.state.name,
                sex: this.state.sex,
                idcard: this.state.idcard,
                company: this.state.company,
                khbank: this.state.khbank,
                bank: this.state.bank,
                banknum: this.state.banknum,
                address: this.state.address,
                tel: this.state.tel,
                fctime: this.state.fctime,
                addtime: this.state.addtime,
                status: this.state.status,
                contract: this.state.contract,
                constart: this.state.constart,
                conend: this.state.conend,
                hktype: this.state.hktype,
                fctime: this.state.fctime,
                status: this.state.status,

                mz: this.state.mz,
                zzmm: this.state.zzmm,
                hkaddress: this.state.hkaddress,
                hkaddtime: this.state.hkaddtime,
                hytype: this.state.hytype,
                xl: this.state.xl,
                xltype: this.state.xltype,
                email: this.state.email,
                qq: this.state.qq,
                jinjiname: this.state.jinjiname,
                jinjitel: this.state.jinjitel,
                jinjiaddress: this.state.jinjiaddress,
                arrjybj: this.state.arrjybj,
                arrgzjl: this.state.arrgzjl,

                jkstatus: this.state.jkstatus,
                zxqk: this.state.zxqk,
                tjjy: this.state.tjjy,
                isyingjie: this.state.isyingjie,
                iskunnan: this.state.iskunnan,
                iszyzg: this.state.iszyzg,
                zyzg: this.state.zyzg,
                zyzggettime: this.state.zyzggettime,
                zyzglevel: this.state.zyzglevel,
                iszyjs: this.state.iszyjs,
                zyjsgettime: this.state.zyjsgettime,
                zyjsname: this.state.zyjsname,
                isok: 1,
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.msg) {
                    message.warning(data.msg);
                }else{
                    this.setState({
                        isok:1
                    })
                }
            })
    }
    fcChange = (name, e) => {
        // console.log(value);
        this.setState({
            [name]: e.target.value,
        })
    }
    render() {
        // this.getuser()
        return (
          <>
          {this.props.match.params.openid?
                this.state.isok==2?
                <div className="mail">
                <div style={{ float: "right", marginBottom: 24,marginRight:10}}>
                    <Button type="primary" onClick={this.subinfo.bind(this)}>提交</Button>
                </div>
                <table className="ant-table grinfo" style={{ marginBottom: 0}}>
                    <tbody>
                        <tr>
                            <td className="ant-table-cell title">中文姓名</td>
                            <td className="ant-table-cell">
                                <Input name="name" onChange={this.onChange.bind(this)} value={this.state.name} />
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">密码</td>
                            <td className="ant-table-cell">
                                <Input name="password" type="password" onChange={this.onChange.bind(this)} value={this.state.password} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="ant-table grinfo" style={{ marginBottom: 0}}>
                    <tbody>
                        <tr>
                            <td className="ant-table-cell th" colSpan="2">个人信息</td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">身份证号码</td>
                            <td className="ant-table-cell">
                                <Input name="idcard" onChange={this.onChange.bind(this)} value={this.state.idcard} />
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">移动电话</td>
                            <td className="ant-table-cell" width="25%">
                                <Input name="tel" onChange={this.onChange.bind(this)} value={this.state.tel} />
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">民族</td>
                            <td className="ant-table-cell">
                                <Select name="mz" style={{ width: '100%' }} onChange={this.onselChange.bind(this, 'mz')} placeholder="请选择" value={this.state.mz == 0 ? null : parseInt(this.state.mz)}>
                                    {this.arrmz()}
                                </Select>
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">政治面貌</td>
                            <td className="ant-table-cell">
                                <Select name="zzmm" style={{ width: '100%' }} onChange={this.onselChange.bind(this, 'zzmm')} placeholder="请选择" value={this.state.zzmm == 0 ? null : parseInt(this.state.zzmm)}>
                                    {this.arrzzmm()}
                                </Select>
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title" width="25%">婚姻状况</td>
                            <td className="ant-table-cell" width="25%">
                                <Select style={{ width: '100%' }} value={this.state.hytype == 0 ? null : parseInt(this.state.hytype)} placeholder="请选择" name="hytype" onChange={this.onselChange.bind(this, 'hytype')}>
                                    {this.arrhy()}
                                </Select>
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">居住地址 (详细到门牌号的地址)</td>
                            <td className="ant-table-cell">
                                <Input name="address" onChange={this.onChange.bind(this)} value={this.state.address} />
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">您的最高学历为</td>
                            <td className="ant-table-cell">
                                <Select style={{ width: '100%' }} value={this.state.xl == 0 ? null : parseInt(this.state.xl)} placeholder="请选择" name="xl" onChange={this.onselChange.bind(this, 'xl')}>
                                    {this.arrxl()}
                                </Select>
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">学历类型</td>
                            <td className="ant-table-cell">
                                <Select style={{ width: '100%' }} value={this.state.xltype == 0 ? null : parseInt(this.state.xltype)} placeholder="请选择" name="xltype" onChange={this.onselChange.bind(this, 'xltype')}>
                                    {this.arrxltype()}
                                </Select>
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">邮箱</td>
                            <td className="ant-table-cell">
                                <Input name="email" onChange={this.onChange.bind(this)} value={this.state.email} />
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">公司QQ号</td>
                            <td className="ant-table-cell">
                                <Input name="qq" onChange={this.onChange.bind(this)} value={this.state.qq} />
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell th" colSpan="2">紧急联系人</td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">姓名</td>
                            <td className="ant-table-cell">
                                <Input name="jinjiname" onChange={this.onChange.bind(this)} value={this.state.jinjiname} />
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">移动电话</td>
                            <td className="ant-table-cell">
                                <Input name="jinjitel" onChange={this.onChange.bind(this)} value={this.state.jinjitel} />
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">紧急联系地址</td>
                            <td className="ant-table-cell" colSpan="3">
                                <Input name="jinjiaddress" onChange={this.onChange.bind(this)} value={this.state.jinjiaddress} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="ant-table grinfo" style={{marginBottom:0,border:0}}>
                    <tbody>
                      {this.state.contract==1 ?
                        <tr>
                            <td className="ant-table-cell">
                                是否首次在天津就业（参保）
                                <Radio.Group onChange={this.fcChange.bind(this, 'tjjy')} value={this.state.tjjy} style={{ marginLeft: 15 }}>
                                    <Radio value="1">是</Radio>
                                    <Radio value="2">否</Radio>
                                </Radio.Group>
                            </td>
                        </tr>
                      :
                      <tr>
                            <td className="ant-table-cell">
                                在校情况
                                <Radio.Group onChange={this.fcChange.bind(this, 'zxqk')} value={this.state.zxqk} style={{ marginLeft: 15 }}>
                                    <Radio value="1">在读</Radio>
                                    <Radio value="2">已毕业</Radio>
                                </Radio.Group>
                            </td>
                        </tr>  
                      }
                    </tbody>
                </table>
                {this.state.tjjy == 1 && this.state.contract==1 ?
                    <table className="ant-table grinfo">
                        <tbody>
                            {/* <tr>
                                <td className="ant-table-cell th" colSpan="2">首次在天津就业（参保）请填写</td>
                            </tr> */}
                            <tr>
                                <td className="ant-table-cell title" width="25%">户口性质</td>
                                <td className="ant-table-cell" width="25%">
                                    <Select style={{ width: '100%' }} value={this.state.hktype == 0 ? null : parseInt(this.state.hktype)} placeholder="请选择" name="hktype" onChange={this.onselChange.bind(this, 'hktype')}>
                                        {this.arrhk()}
                                    </Select>
                                </td>
                            </tr>
                            <tr>
                                <td className="ant-table-cell title">户口登记日期</td>
                                <td className="ant-table-cell">
                                    <DatePicker name="hkaddtime" style={{ width: '100%' }} onChange={this.ontimeChange.bind(this, 'hkaddtime')} value={this.state.hkaddtime ? moment(this.state.hkaddtime) : null} />
                                </td>
                            </tr>
                            <tr>
                                <td className="ant-table-cell title" >户口详细地址</td>
                                <td className="ant-table-cell">
                                    <Input name="hkaddress" onChange={this.onChange.bind(this)} value={this.state.hkaddress} />
                                </td>
                            </tr>
                            <tr>
                                <td className="ant-table-cell title">是否应届毕业生</td>
                                <td className="ant-table-cell">
                                    <Select style={{ width: '100%' }} value={this.state.isyingjie == 0 ? null : parseInt(this.state.isyingjie)} placeholder="请选择" name="isyingjie" onChange={this.onselChange.bind(this, 'isyingjie')}>
                                        <Option value={1} key={1}>是</Option>
                                        <Option value={2} key={2}>否</Option>
                                    </Select>
                                </td>
                            </tr>
                            <tr>
                                <td className="ant-table-cell title">是否为困难毕业生</td>
                                <td className="ant-table-cell">
                                    <Select style={{ width: '100%' }} value={this.state.iskunnan == 0 ? null : parseInt(this.state.iskunnan)} placeholder="请选择" name="iskunnan" onChange={this.onselChange.bind(this, 'iskunnan')}>
                                        <Option value={1} key={1}>是</Option>
                                        <Option value={2} key={2}>否</Option>
                                    </Select>
                                </td>
                            </tr>
                            <tr>
                                <td className="ant-table-cell title">健康状态</td>
                                <td className="ant-table-cell">
                                    <Select style={{ width: '100%' }} value={this.state.jkstatus == 0 ? null : parseInt(this.state.jkstatus)} placeholder="请选择" name="jkstatus" onChange={this.onselChange.bind(this, 'jkstatus')}>
                                        {this.arrjkstatus()}
                                    </Select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    :
                    null
                }
                <table className="ant-table grinfo" style={{ marginBottom: 0 }}>
                    <tbody>
                        <tr>
                            <td className="ant-table-cell th" >教育背景</td>
                            <td className="ant-table-cell th" style={{textAlign:"right"}} ><a onClick={this.addjybj.bind(this)}><PlusCircleOutlined /></a></td>
                        </tr>
                        {
                            this.state.arrjybj ?
                                this.state.arrjybj.map((item, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td className="ant-table-cell title">自</td>
                                                <td><DatePicker name="from" value={item.from ? moment(item.from) : null} onChange={this.inputChange.bind(this, item, index, 'from')} /></td>
                                            </tr>
                                            <tr>
                                                <td className="ant-table-cell title" >至</td>
                                                <td><DatePicker name="to" value={item.to ? moment(item.to) : null} onChange={this.inputChange.bind(this, item, index, 'to')} /></td>
                                            </tr>
                                            <tr>
                                                <td className="ant-table-cell title" >学校名称</td>
                                                <td><Input value={item.schoolname} onChange={this.inputChange.bind(this, item, index, 'schoolname')} /></td>
                                            </tr>
                                            <tr>
                                                <td className="ant-table-cell title" >专业</td>
                                                <td><Input value={item.major} onChange={this.inputChange.bind(this, item, index, 'major')} /></td>
                                            </tr>
                                            <tr>
                                                <td className="ant-table-cell title" >学位</td>
                                                <td><Input value={item.degree} onChange={this.inputChange.bind(this, item, index, 'degree')} /></td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" style={{textAlign:"center"}}><DeleteOutlined style={{ cursor: "pointer",color:"#f00" }} onClick={this.deljybj.bind(this, index)} /></td>
                                            </tr>
                                        </>
                                    )
                                }) : null
                        }
                    </tbody>
                </table>
                <table className="ant-table grinfo" style={{ marginBottom: 0, border: 0 }}>
                    <tbody>
                        <tr>
                            <td className="ant-table-cell">
                                是否取得国家职业资格
                                <Radio.Group onChange={this.fcChange.bind(this, 'iszyzg')} value={this.state.iszyzg} style={{ marginLeft: 15 }}>
                                    <Radio value="1">是</Radio>
                                    <Radio value="2">否</Radio>
                                </Radio.Group>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {this.state.iszyzg == 1 ?
                    <table className="ant-table grinfo" style={{ marginBottom: 0 }}>
                        <tbody>
                            {/* <tr>
                                <td className="ant-table-cell th" colSpan="4">National Professional Qualification / 国家职业资格</td>
                            </tr> */}
                            <tr>
                                <td className="ant-table-cell title" width="25%">职业(工种)资格</td>
                                <td className="ant-table-cell" width="25%">
                                    <Select style={{ width: '100%' }} value={this.state.zyzg == 0 ? null : parseInt(this.state.zyzg)} placeholder="请选择" name="zyzg" onChange={this.onselChange.bind(this, 'zyzg')}>
                                        {this.arrzyzg()}
                                    </Select>
                                </td>
                            </tr>
                            <tr>
                                <td className="ant-table-cell title">取得资格时间</td>
                                <td className="ant-table-cell">
                                    <DatePicker name="zyzggettime" style={{ width: '100%' }} onChange={this.ontimeChange.bind(this, 'zyzggettime')} value={this.state.zyzggettime ? moment(this.state.zyzggettime) : null} />
                                </td>
                            </tr>
                            <tr>
                                <td className="ant-table-cell title" width="25%">职业资格等级</td>
                                <td className="ant-table-cell" width="25%">
                                    <Select style={{ width: '100%' }} value={this.state.zyzglevel == 0 ? null : parseInt(this.state.zyzglevel)} placeholder="请选择" name="zyzglevel" onChange={this.onselChange.bind(this, 'zyzglevel')}>
                                        {this.arrzyzglevel()}
                                    </Select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    :
                    null
                }
                <table className="ant-table grinfo" style={{ marginBottom: 0, border: 0 }}>
                    <tbody>
                        <tr>
                            <td className="ant-table-cell">
                                是否取得专业技术职务
                                <Radio.Group onChange={this.fcChange.bind(this, 'iszyjs')} value={this.state.iszyjs} style={{ marginLeft: 15 }}>
                                    <Radio value="1">是</Radio>
                                    <Radio value="2">否</Radio>
                                </Radio.Group>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {this.state.iszyjs == 1 ?
                    <table className="ant-table grinfo" style={{ marginBottom: 0 }}>
                        <tbody>
                            {/* <tr>
                                <td className="ant-table-cell th" colSpan="4">Technical Position/ 专业技术职务</td>
                            </tr> */}
                            <tr>
                                <td className="ant-table-cell title">取得资格时间</td>
                                <td className="ant-table-cell">
                                    <DatePicker name="zyjsgettime" style={{ width: '100%' }} onChange={this.ontimeChange.bind(this, 'zyjsgettime')} value={this.state.zyjsgettime ? moment(this.state.zyjsgettime) : null} />
                                </td>
                            </tr>
                            <tr>
                                <td className="ant-table-cell title" width="25%">专技职务名称</td>
                                <td className="ant-table-cell" width="25%">
                                    <Input name="zyjsname" onChange={this.onChange.bind(this)} value={this.state.zyjsname} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    :
                    null
                }
                <table className="ant-table grinfo">
                    <tbody>
                        <tr>
                            <td className="ant-table-cell th">加入我公司之前工作经历</td>
                            <td className="ant-table-cell th" style={{ textAlign: "right" }}><a onClick={this.addgzjl.bind(this)}><PlusCircleOutlined /></a></td>
                        </tr>
                        {
                            this.state.arrgzjl ?
                                this.state.arrgzjl.map((item, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td className="ant-table-cell title">自</td>
                                                <td><DatePicker name="from" value={item.from ? moment(item.from) : null} onChange={this.inputChange2.bind(this, item, index, 'from')} /></td>
                                            </tr>
                                            <tr>
                                                <td className="ant-table-cell title" >至</td>
                                                <td><DatePicker name="to" value={item.to ? moment(item.to) : null} onChange={this.inputChange2.bind(this, item, index, 'to')} /></td>
                                            </tr>
                                            <tr>
                                                <td className="ant-table-cell title" >公司名称，地点</td>
                                                <td><Input value={item.companyname} onChange={this.inputChange2.bind(this, item, index, 'companyname')} /></td>
                                            </tr>
                                            <tr>
                                                <td className="ant-table-cell title" >部门</td>
                                                <td><Input value={item.dept} onChange={this.inputChange2.bind(this, item, index, 'dept')} /></td>
                                            </tr>
                                            <tr>
                                                <td className="ant-table-cell title" >职位</td>
                                                <td><Input value={item.position} onChange={this.inputChange2.bind(this, item, index, 'position')} /></td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" style={{ textAlign: "center" }}><DeleteOutlined style={{ cursor: "pointer", color: "#f00" }} onClick={this.delgzjl.bind(this, index)} /></td>
                                            </tr>
                                        </>
                                    )
                                }) : null
                        }
                    </tbody>
                </table>
                <table className="ant-table grinfo">
                            <tbody>
                                <tr>
                                    <td className="ant-table-cell th" colSpan="6">银行卡信息（接收工资）</td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title">银行</td>
                                    <td className="ant-table-cell" ><Input name="bank" onChange={this.onChange.bind(this)} value={this.state.bank} /></td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title" >开户行全称</td>
                                    <td className="ant-table-cell" ><Input name="khbank" onChange={this.onChange.bind(this)} value={this.state.khbank} /></td>
                                </tr>
                                <tr>
                                    <td className="ant-table-cell title" >银行卡号</td>
                                    <td className="ant-table-cell"><Input name="banknum" onChange={this.onChange.bind(this)} value={this.state.banknum} /></td>
                                </tr>
                            </tbody>
                        </table>
            </div>
                :
                <div style={{width:"100%",height:'100%',display:'flex',justifyContent:'center',alignItems:'center',fontSize:30,fontWeight:'bold',color:'#1890ff'}}>已提交</div>
            :
            <>参数错误</>
          }
          </>
        )
    }
}
export default Userview;