import React from 'react';
import { Table, Input, Button, Space } from 'antd';
import ExportExcel from '../exportjs/xlsx2.js';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import store from "../store/store.js";
class EditableTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gzlist:props.gzlist?props.gzlist:[],
            istj:props.istj,
            year:props.year,
            month:props.istj,
            searchText: '',
            searchedColumn: '',
        }
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            gzlist:nextProps.gzlist,
            year:nextProps.year,
            month:nextProps.month,
            istj:nextProps.istj,
        })
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`请输入`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                搜索
              </Button>
              <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                重置
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
    
      handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };
    render(){
        var columns = [];
        {this.props.type==1?
            this.props.istj!=13?
            columns = [
                {
                    title: '序号',
                    dataIndex: 'xuhao',
                    width: 150,
                    fixed: 'left',
                },
                {
                    title: '姓名',
                    dataIndex: 'name',
                    width: 150,
                    fixed: 'left',
                    ...this.getColumnSearchProps('name'),
                },
                {
                    title: '身份证',
                    dataIndex: 'idcard',
                    width: 220
                },
                {
                    title: '入职日期',
                    dataIndex: 'rzrq_str',
                    width: 220
                },
                {
                    title: '工资统计',
                    dataIndex: 'gztj',
                    children: [
                        {
                            title: '基本工资',
                            dataIndex: 'xzbj',
                            width: 150,
                        },
                        {
                            title: '岗位工资',
                            dataIndex: 'gwgz',
                            width: 150,
                        },
                        {
                            title: '绩效工资',
                            dataIndex: 'jxgz',
                            width: 150,
                        },
                    ]
                },
                {
                    title: '加班统计',
                    dataIndex: 'jbtj',
                    children: [
                        {
                            title: '工作日加班时长（小时）',
                            dataIndex: 'gzrjbsc',
                            width: 200,
                        },
                        {
                            title: '工作日加班工资（元）',
                            dataIndex: 'gzrjbgz',
                            width: 180,
                        },
                        {
                            title: '出勤天数（天）',
                            dataIndex: 'cqts',
                            width: 180,
                        },
                        {
                            title: '休息日加班天数（天）',
                            dataIndex: 'xxrjbts',
                            width: 180,
                        },
                    ]
                },
                {
                    title: '出勤统计',
                    dataIndex: 'cqtj',
                    children: [
                        {
                            title: '请假/倒休天数（天）',
                            dataIndex: 'qjdxts',
                            width: 180,
                        },
                        {
                            title: '迟到次数（次）',
                            dataIndex: 'cdcs',
                            width: 180,
                        },
                        {
                            title: '迟到时间（分钟）',
                            dataIndex: 'cdsj',
                            width: 180,
                        },
                    ]
                },
                {
                    title: '余项统计',
                    dataIndex: 'yxtj',
                    children: [
                        {
                            title: '报销',
                            dataIndex: 'bx',
                            width: 150,
                        },
                        
                        {
                            title: '全勤奖',
                            dataIndex: 'qqj',
                            width: 150,
                        },
                        {
                            title: '餐补',
                            dataIndex: 'cb',
                            width: 150,
                        },
                        {
                            title: '补贴',
                            dataIndex: 'bt',
                            width: 150,
                        },
                        {
                            title: '扣款',
                            dataIndex: 'kk',
                            width: 150,
                        },
                        {
                            title: '备注',
                            dataIndex: 'beizhu',
                            width: 150,
                        },
                    ]
                },
                {
                    title: '应发工资总计（元）',
                    dataIndex: 'yfgz',
                    width: 150,
                },
                {
                    title: '个人承担社保/公积金',
                    dataIndex: 'grcdsbgjj',
                    children: [
                        {
                            title: '社保基数（元）',
                            dataIndex: 'jfjs',
                            width: 150,
                        },
                        {
                            title: '公积金基数（元）',
                            dataIndex: 'gjjjs',
                            width: 150,
                        },
                        {
                            title: '企业基本养老保险(8%)',
                            dataIndex: 'gryangl',
                            width: 150,
                        },
                        {
                            title: '基本医疗保险(2%)',
                            dataIndex: 'gryil',
                            width: 150,
                        },
                        {
                            title: '失业保险(0.5%)',
                            dataIndex: 'grsy',
                            width: 150,
                        },
                        {
                            title: '大额医疗救助金',
                            dataIndex: 'grde',
                            width: 150,
                        },
                        {
                            title: '公积金',
                            dataIndex: 'grjj',
                            width: 150,
                        },
                    ]
                },
                {
                    title: '个税',
                    dataIndex: 'grsds',
                    width: 150,
                },
                {
                    title: '实发工资（元）总计',
                    dataIndex: 'sfgz',
                    width: 150,
                },
                {
                    title: '公司承担社保/公积金',
                    dataIndex: 'gscdsbgjj',
                    children: [
                        {
                            title: '企业基本养老保险（16%）',
                            dataIndex: 'gsyangl',
                            width: 150,
                        },
                        {
                            title: '失业保险（0.5%）',
                            dataIndex: 'gssy',
                            width: 150,
                        },
                        {
                            title: '基本医疗保险(10%)',
                            dataIndex: 'gsyil',
                            width: 150,
                        },
                        {
                            title: '工伤保险（0.2%）',
                            dataIndex: 'gsgs',
                            width: 150,
                        },
                        {
                            title: '职工基本医疗(生育)保险（0.5%）',
                            dataIndex: 'gsshengy',
                            width: 150,
                        },
                        {
                            title: '公积金',
                            dataIndex: 'gsjj',
                            width: 150,
                        },
                    ]
                },
                {
                    title: '总年假（天）',
                    dataIndex: 'znj',
                    width: 150,
                },
                {
                    title: '请假天数（天）',
                    dataIndex: 'qjts',
                    width: 150,
                },
                {
                    title: '剩余年假（天）',
                    dataIndex: 'synj',
                    width: 150,
                },
                {
                    title: '年假折抵',
                    dataIndex: 'njzd',
                    width: 150,
                },
                {
                    title: '年终奖',
                    dataIndex: 'nzj',
                    width: 150,
                },
            ]
            :
            columns = [
                {
                    title: '工资统计',
                    dataIndex: 'gztj',
                    children: [
                        {
                            title: '基本工资',
                            dataIndex: 'xzbj',
                            width: 150,
                        },
                        {
                            title: '岗位工资',
                            dataIndex: 'gwgz',
                            width: 150,
                        },
                        {
                            title: '绩效工资',
                            dataIndex: 'jxgz',
                            width: 150,
                        },
                    ]
                },
                {
                    title: '加班统计',
                    dataIndex: 'jbtj',
                    children: [
                        {
                            title: '工作日加班时长（小时）',
                            dataIndex: 'gzrjbsc',
                            width: 200,
                        },
                        {
                            title: '工作日加班工资（元）',
                            dataIndex: 'gzrjbgz',
                            width: 180,
                        },
                        {
                            title: '出勤天数（天）',
                            dataIndex: 'cqts',
                            width: 180,
                        },
                        {
                            title: '休息日加班天数（天）',
                            dataIndex: 'xxrjbts',
                            width: 180,
                        },
                    ]
                },
                {
                    title: '出勤统计',
                    dataIndex: 'cqtj',
                    children: [
                        {
                            title: '请假/倒休天数（天）',
                            dataIndex: 'qjdxts',
                            width: 180,
                        },
                        {
                            title: '迟到次数（次）',
                            dataIndex: 'cdcs',
                            width: 180,
                        },
                        {
                            title: '迟到时间（分钟）',
                            dataIndex: 'cdsj',
                            width: 180,
                        },
                    ]
                },
                {
                    title: '余项统计',
                    dataIndex: 'yxtj',
                    children: [
                        {
                            title: '报销',
                            dataIndex: 'bx',
                            width: 150,
                        },
                        
                        {
                            title: '全勤奖',
                            dataIndex: 'qqj',
                            width: 150,
                        },
                        {
                            title: '餐补',
                            dataIndex: 'cb',
                            width: 150,
                        },
                        {
                            title: '补贴',
                            dataIndex: 'bt',
                            width: 150,
                        },
                        {
                            title: '扣款',
                            dataIndex: 'kk',
                            width: 150,
                        },
                        {
                            title: '备注',
                            dataIndex: 'beizhu',
                            width: 150,
                        },
                    ]
                },
                {
                    title: '应发工资总计（元）',
                    dataIndex: 'yfgz',
                    width: 150,
                },
                {
                    title: '个人承担社保/公积金',
                    dataIndex: 'grcdsbgjj',
                    children: [
                        {
                            title: '社保基数（元）',
                            dataIndex: 'jfjs',
                            width: 150,
                        },
                        {
                            title: '公积金基数（元）',
                            dataIndex: 'gjjjs',
                            width: 150,
                        },
                        {
                            title: '企业基本养老保险(8%)',
                            dataIndex: 'gryangl',
                            width: 150,
                        },
                        {
                            title: '基本医疗保险(2%)',
                            dataIndex: 'gryil',
                            width: 150,
                        },
                        {
                            title: '失业保险(0.5%)',
                            dataIndex: 'grsy',
                            width: 150,
                        },
                        {
                            title: '大额医疗救助金',
                            dataIndex: 'grde',
                            width: 150,
                        },
                        {
                            title: '公积金',
                            dataIndex: 'grjj',
                            width: 150,
                        },
                    ]
                },
                {
                    title: '个税',
                    dataIndex: 'grsds',
                    width: 150,
                },
                {
                    title: '实发工资（元）总计',
                    dataIndex: 'sfgz',
                    width: 150,
                },
                {
                    title: '公司承担社保/公积金',
                    dataIndex: 'gscdsbgjj',
                    children: [
                        {
                            title: '企业基本养老保险（16%）',
                            dataIndex: 'gsyangl',
                            width: 150,
                        },
                        {
                            title: '失业保险（0.5%）',
                            dataIndex: 'gssy',
                            width: 150,
                        },
                        {
                            title: '基本医疗保险(10%)',
                            dataIndex: 'gsyil',
                            width: 150,
                        },
                        {
                            title: '工伤保险（0.2%）',
                            dataIndex: 'gsgs',
                            width: 150,
                        },
                        {
                            title: '职工基本医疗(生育)保险（0.5%）',
                            dataIndex: 'gsshengy',
                            width: 150,
                        },
                        {
                            title: '公积金',
                            dataIndex: 'gsjj',
                            width: 150,
                        },
                    ]
                },
                {
                    title: '总年假（天）',
                    dataIndex: 'znj',
                    width: 150,
                },
                {
                    title: '请假天数（天）',
                    dataIndex: 'qjts',
                    width: 150,
                },
                {
                    title: '剩余年假（天）',
                    dataIndex: 'synj',
                    width: 150,
                },
                {
                    title: '年假折抵',
                    dataIndex: 'njzd',
                    width: 150,
                },
                {
                    title: '年终奖',
                    dataIndex: 'nzj',
                    width: 150,
                },
            ]
        :
        columns = [
            {
                title: '发放时间',
                dataIndex: 'time',
                width: 150,
                fixed: 'left',
            },
            {
                title: '薪资标准',
                dataIndex: 'xzbj',
                width: 150,
            },
            {
                title: '岗位工资',
                dataIndex: 'gwgz',
                width: 150,
            },
            {
                title: '绩效工资',
                dataIndex: 'jxgz',
                width: 150,
            },
            {
                title: '加班工资',
                dataIndex: 'jbgz',
                width: 150,
            },
            {
                title: '报销',
                dataIndex: 'bx',
                width: 150,
            },
            {
                title: '补贴',
                dataIndex: 'bt',
                width: 150,
            },
            {
                title: '全勤奖',
                dataIndex: 'qqj',
                width: 150,
            },
            {
                title: '餐补',
                dataIndex: 'cb',
                width: 150,
            },
            {
                title: '年假统计',
                dataIndex: 'njtj',
                children: [
                    {
                        title: '总年假',
                        dataIndex: 'znj',
                        width: 150,
                    },
                    {
                        title: '请假天数',
                        dataIndex: 'qjts',
                        width: 150,
                    },
                    {
                        title: '剩余年假',
                        dataIndex: 'synj',
                        width: 150,
                    }
                ]
            },
            {
                title: '年假折抵',
                dataIndex: 'njzd',
                width: 150,
            },
            {
                title: '年终奖',
                dataIndex: 'nzj',
                width: 150,
            },
            {
                title: '扣款',
                dataIndex: 'kk',
                width: 150,
            },
            {
                title: '应发工资',
                dataIndex: 'yfgz',
                width: 150,
            },
            {
                title: '代扣个人社保',
                dataIndex: 'grsbtitle',
                children: [
                    {
                        title: '缴费基数',
                        dataIndex: 'jfjs',
                        width: 150,
                    },
                    {
                        title: '养老(8%)',
                        dataIndex: 'gryangl',
                        width: 150,
                    },
                    {
                        title: '医疗(2%)',
                        dataIndex: 'gryil',
                        width: 150,
                    },
                    {
                        title: '失业(0.5%)',
                        dataIndex: 'grsy',
                        width: 150,
                    },
                    {
                        title: '大额',
                        dataIndex: 'grde',
                        width: 150,
                    },
                    {
                        title: '小计',
                        dataIndex: 'grxj',
                        width: 150,
                    },
                ]
            },
            {
                title: '个人',
                dataIndex: 'grgjj',
                children: [
                    {
                        title: '公积金',
                        dataIndex: 'grjj',
                        width: 150,
                    },
                ]
            },
            {
                title: '计税工资',
                dataIndex: 'jsgz',
                width: 150,
            },
            {
                title: '个人所得税',
                dataIndex: 'grsds',
                width: 150,
            },
            {
                title: '实发工资',
                dataIndex: 'sfgz',
                width: 150,
            },
            {
                title: '公司承担社保',
                dataIndex: 'gssb',
                width: 150,
                children: [
                    {
                        title: '养老保险（16%）',
                        dataIndex: 'gsyangl',
                        width: 150,
                    },
                    {
                        title: '失业保险（0.5%）',
                        dataIndex: 'gssy',
                        width: 150,
                    },
                    {
                        title: '医疗保险（10%）',
                        dataIndex: 'gsyil',
                        width: 150,
                    },
                    {
                        title: '工伤保险（0.4%）',
                        dataIndex: 'gsgs',
                        width: 150,
                    },
                    {
                        title: '生育保险（0.5%）',
                        dataIndex: 'gsshengy',
                        width: 150,
                    },
                    {
                        title: '小计',
                        dataIndex: 'gsxj',
                        width: 150,
                    },
                ]
            },
            {
                title: '公司承担公积金',
                dataIndex: 'gsjj',
                width: 150,
            },
            {
                title: '备注',
                dataIndex: 'beizhu',
                width: 150,
            },
        ]}
        // var columns2 = [
        //     {
        //         title: '员工编号',
        //         dataIndex: 'ygbh',
        //         width: 150,
        //         fixed: 'left',
        //         ...this.getColumnSearchProps('ygbh'),
        //     },
        //     {
        //         title: '发薪主体',
        //         dataIndex: 'fxzt',
        //         width: 150,
        //         fixed: 'left',
        //         ...this.getColumnSearchProps('fxzt'),
        //     },
        //     {
        //         title: '姓名',
        //         dataIndex: 'xm',
        //         width: 150,
        //         fixed: 'left',
        //         ...this.getColumnSearchProps('xm'),
        //     },
        //     {
        //         title: '账号',
        //         dataIndex: 'zh',
        //         width: 150,
        //         ...this.getColumnSearchProps('zh'),
        //     },
        //     {
        //         title: '身份证号',
        //         dataIndex: 'idcard',
        //         width: 250,
        //         ...this.getColumnSearchProps('idcard'),
        //     },
        //     {
        //         title: '开户银行',
        //         dataIndex: 'khyh',
        //         width: 250,
        //         ...this.getColumnSearchProps('khyh'),
        //     },
        //     {
        //         title: '基本工资',
        //         dataIndex: 'jbgz',
        //         width: 150,
        //         render: (raw, record) => {
        //             return (
        //                 Number(raw).toFixed(2)
        //             )
        //         },
        //     },
        //     {
        //         title: '当月缺勤天数',
        //         dataIndex: 'dyqqts',
        //         width: 150,
        //     },
        //     {
        //         title: '缺勤扣款',
        //         dataIndex: 'qqkk',
        //         width: 150,
        //         render: (raw, record) => {
        //             return (
        //                 Number(raw).toFixed(2)
        //             )
        //         },
        //     },
        //     {
        //         title: '打卡扣费',
        //         dataIndex: 'dkkf',
        //         width: 150,
        //         render: (raw, record) => {
        //             return (
        //                 Number(raw).toFixed(2)
        //             )
        //         },
        //     },
        //     {
        //         title: '其他扣费',
        //         dataIndex: 'qtkf',
        //         width: 150,
        //         render: (raw, record) => {
        //             return (
        //                 Number(raw).toFixed(2)
        //             )
        //         },
        //     },
        //     {
        //         title: '扣款合计',
        //         dataIndex: 'kkhj',
        //         width: 150,
        //         render: (raw, record) => {
        //             return (
        //                 Number(raw).toFixed(2)
        //             )
        //         },
        //     },

        //     {
        //         title: '扣款后工资',
        //         dataIndex: 'kkhgz',
        //         width: 150,
        //         render: (raw, record) => {
        //             return (
        //                 Number(raw).toFixed(2)
        //             )
        //         },
        //     },
        //     {
        //         title: '绩效工资',
        //         dataIndex: 'jxgz',
        //         width: 150,
        //         render: (raw, record) => {
        //             return (
        //                 Number(raw).toFixed(2)
        //             )
        //         },
        //     },
        //     {
        //         title: '业务提成',
        //         dataIndex: 'ywtc',
        //         width: 150,
        //         render: (raw, record) => {
        //             return (
        //                 Number(raw).toFixed(2)
        //             )
        //         },
        //     },
        //     {
        //         title: '工资合计',
        //         dataIndex: 'gzhj',
        //         width: 150,
        //         render: (raw, record) => {
        //             return (
        //                 Number(raw).toFixed(2)
        //             )
        //         },
        //     },
        //     {
        //         title: '社保缴费基数',
        //         dataIndex: 'sbjfjs',
        //         width: 150,
        //         render: (raw, record) => {
        //             return (
        //                 Number(raw).toFixed(2)
        //             )
        //         },
        //     },
        //     {
        //         title: '公积金缴费基数',
        //         dataIndex: 'jjjjfjs',
        //         width: 150,
        //         render: (raw, record) => {
        //             return (
        //                 Number(raw).toFixed(2)
        //             )
        //         },
        //     },
        //     {
        //         title: '社保单位缴存',
        //         dataIndex: 'sbdwjc',
        //         width: 150,
        //         render: (raw, record) => {
        //             return (
        //                 Number(raw).toFixed(2)
        //             )
        //         },
        //     },
        //     {
        //         title: '社保个人缴存',
        //         dataIndex: 'sbgrjc',
        //         width: 150,
        //         render: (raw, record) => {
        //             return (
        //                 Number(raw).toFixed(2)
        //             )
        //         },
        //     },
        //     {
        //         title: '公积金单位缴存',
        //         dataIndex: 'gjjdwjc',
        //         width: 150,
        //         render: (raw, record) => {
        //             return (
        //                 Number(raw).toFixed(2)
        //             )
        //         },
        //     },
        //     {
        //         title: '公积金个人缴存',
        //         dataIndex: 'jjjgrjs',
        //         width: 150,
        //         render: (raw, record) => {
        //             return (
        //                 Number(raw).toFixed(2)
        //             )
        //         },
        //     },
        //     {
        //         title: '税前工资',
        //         dataIndex: 'sqgz',
        //         width: 150,
        //         render: (raw, record) => {
        //             return (
        //                 Number(raw).toFixed(2)
        //             )
        //         },
        //     },
        //     {
        //         title: '个人所得税',
        //         dataIndex: 'grsds',
        //         width: 150,
        //         render: (raw, record) => {
        //             return (
        //                 Number(raw).toFixed(2)
        //             )
        //         },
        //     },
        //     {
        //         title: '实发工资',
        //         dataIndex: 'sfgz',
        //         width: 150,
        //         render: (raw, record) => {
        //             return (
        //                 Number(raw).toFixed(2)
        //             )
        //         },
        //     },
        //     {
        //         title: '备注',
        //         dataIndex: 'bx',
        //         width: 150,
        //     },
        //     // {
        //     //     title: '备注',
        //     //     dataIndex: 'bz',
        //     //     width: 150,
        //     // },
        // ]
        return (
            <div>
                <Table
                    style={{marginBottom:15}}
                    bordered
                    dataSource={this.state.gzlist?this.state.gzlist[0]:null}
                    columns={columns}
                    pagination={false}
                    scroll={{ x: 2700 }}
                    summary={
                        pageData => {
                            let total_xzbj = 0;
                            let total_gwgz = 0;
                            let total_jxgz = 0;
                            let total_jbgz = 0;
                            let total_gzrjbgz = 0;
                            let total_bx = 0;
                            let total_bt = 0;
                            let total_qqj = 0;
                            let total_cb = 0;
                            let total_kk = 0;
                            let total_yfgz = 0;
                            let total_jfjs = 0;
                            let total_gryangl = 0;
                            let total_gryil = 0;
                            let total_grsy = 0;
                            let total_grde = 0;
                            let total_grxj = 0;
                            let total_grjj = 0;
                            let total_sfgz = 0;
                            let total_gsyangl = 0;
                            let total_gssy = 0;
                            let total_gsyil = 0;
                            let total_gsgs = 0;
                            let total_gsshengy = 0;
                            let total_gsxj = 0;
                            let total_gsjj = 0;
                            let total_grsds = 0;
                            pageData.forEach(({ xzbj,gwgz,grsds,jxgz,gzrjbgz,jbgz,bx,bt,qqj,cb,kk,yfgz,jfjs,gryangl,gryil,grsy,grde,grxj,grjj,sfgz,gsyangl,gssy,gsyil,gsgs,gsshengy,gsxj,gsjj}) => {
                                total_xzbj += Number(xzbj);
                                total_gwgz += Number(gwgz);
                                total_jxgz += Number(jxgz);
                                total_jbgz += Number(jbgz);
                                total_gzrjbgz += Number(gzrjbgz);
                                total_bx += Number(bx);
                                total_bt += Number(bt);
                                total_qqj += Number(qqj);
                                total_cb += Number(cb);
                                total_kk += Number(kk);
                                total_yfgz += Number(yfgz);
                                total_jfjs += Number(jfjs);
                                total_gryangl += Number(gryangl);
                                total_gryil += Number(gryil);
                                total_grsy += Number(grsy);
                                total_grde += Number(grde);
                                total_grxj += Number(grxj);
                                total_grjj += Number(grjj);
                                total_sfgz += Number(sfgz);
                                total_gsyangl += Number(gsyangl);
                                total_gssy += Number(gssy);
                                total_gsyil += Number(gsyil);
                                total_gsgs += Number(gsgs);
                                total_gsshengy += Number(gsshengy);
                                total_gsxj += Number(gsxj);
                                total_gsjj += Number(gsjj);
                                total_grsds += Number(gsjj);
                            });
                            return (
                                this.props.type==1?
                                    this.props.istj!=13?
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell>总计</Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell>{total_xzbj.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell>{total_gwgz.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell>{total_jxgz.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell>{total_gzrjbgz.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell>{total_bx.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell>{total_qqj.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell>{total_cb.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell>{total_bt.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell>{total_kk.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell>{total_yfgz.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell>{total_gryangl.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell>{total_gryil.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell>{total_grsy.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell>{total_grde.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell>{total_grjj.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell>{total_grsds.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell>{total_sfgz.toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>

                                    </Table.Summary.Row>
                                    :
                                    null
                                :
                                <Table.Summary.Row>
                                    <Table.Summary.Cell>总计</Table.Summary.Cell>
                                    <Table.Summary.Cell>{total_xzbj.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{total_gwgz.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{total_jxgz.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{total_jbgz.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{total_bx.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{total_bt.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{total_qqj.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{total_cb.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell>{total_kk.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{total_yfgz.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{total_jfjs.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{total_gryangl.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{total_gryil.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{total_grsy.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{total_grde.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{total_grxj.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{total_grjj.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell>{total_sfgz.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{total_gsyangl.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{total_gssy.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{total_gsyil.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{total_gsgs.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{total_gsshengy.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{total_gsxj.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{total_gsjj.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                </Table.Summary.Row>
                                
                            );
                        }
                    }
                />
                {/* {this.props.type!=1 || (Number(this.state.year)==2021 && Number(this.state.month)<6)?
                    null
                    :
                    <Table
                        style={{marginBottom:15}}
                        bordered
                        dataSource={this.state.gzlist?this.state.gzlist[1]:null}
                        columns={columns2}
                        pagination={false}
                        scroll={{ x: 2700 }}
                    />
                } */}
            </div>
        )
    }
}
export default EditableTable;