import { connect } from 'react-redux';

//=====引入组件=====
import Messageview from '../../message/messageview.js'


//=====react-redux 封装组件=====

// 哪些 Redux 全局的 state 是我们组件想要通过 props 获取的？
function mapStateToProps(state) {
    return {
        isLogin: state.data.isLogin,
        info: state.data.info,
        nav: state.data.nav,
        navname: state.data.navname,
    }
}

// 哪些 action 创建函数是我们想要通过 props 获取的？
function mapDispatchToProps(dispatch) {
    return {
        GET_INFO: function () {
            let action = {
                type: "GET_INFO",
                // isLogin: isLogin,
                // info: info,
            }
            dispatch(action);
        },
    };
}

//封装传递state和dispatch
var MessageViewAction = connect(mapStateToProps, mapDispatchToProps)(Messageview);

export default MessageViewAction