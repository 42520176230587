import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Layout, Menu, Breadcrumb, Dropdown, message, Badge, Alert } from 'antd';
import {
	UserOutlined,
	AreaChartOutlined,
	PoweroffOutlined,
	DollarCircleOutlined,
	BellOutlined,
	FileTextOutlined,
	ClusterOutlined,
	DingtalkOutlined,
	DollarOutlined,
	AppstoreAddOutlined,
	SolutionOutlined,
	FundProjectionScreenOutlined,
	DotChartOutlined
} from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/zh-cn';
import '../index.css';
import store from "../store/store.js";
moment.locale('zh-cn');
const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

class SiderDemo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			breadcrumb: [store.getState().data.navname ? store.getState().data.navname : '首页'],
			defaultnav: '1',
			current: '/',
			isLogin: store.getState().data.isLogin,
			type: 1,
			tips: [],
			name: store.getState().data.info.name
		}
		// props.GET_INFO();
		store.subscribe(() => {
			// console.log('state状态改变了，新状态如下')
			// console.log(store.getState().data)
			this.setState({
				current: store.getState().data.nav,
				breadcrumb: [store.getState().data.navname],
			});
		})
	}
	state = {
		collapsed: false,
	};
	breadcrumbItem() {
		return (
			this.state.breadcrumb.map((list, index) =>
				<Breadcrumb.Item key={index}>{list}</Breadcrumb.Item>
			)
		)
	}
	onCollapse = collapsed => {
		this.setState({ collapsed });
	};
	handleClick = e => {
		this.setState({
			current: store.getState().data.nav,
			// breadcrumb:[e.item.props.title],
			breadcrumb: [store.getState().data.navname],
		});
	};
	componentWillMount() {
		// this.props.GET_INFO();
		this.setState(store.getState())
		fetch('/Login/checklogin', {
			method: "POST",
		})
			.then(res => res.json())
			.then(data => {
				if (data.state == 1) {
					this.setState({
						isLogin: true,
						// name:data.name,
					})
					if (data.status == 1) {
						this.logout();
					}
				} else {
					this.props.OUT_LOGIN();
					// console.log(store.getState().data.isLogin );
					this.setState({
						isLogin: false
					})
				}
			})
	}
	componentDidMount() {
		setTimeout(() => {
			this.getupuser();
			// console.log(store.getState().data.nav, store.getState().data.navname)
		}, 1000);
	}
	aa(nav, navname, e) {
		// console.log(nav,navname);
		this.props.NAV_CHANGE(nav, navname)
		// store.dispatch({ type: 'NAV_CHANGE', nav:nav, navname:navname });
	}
	logout() {
		fetch('/Login/logout', {
			method: "POST",
		})
			.then(res => res.json())
			.then(data => {
				if (data.status == 1) {
					message.success(data.msg);
					this.props.OUT_LOGIN()
					setTimeout(
						() => {
							this.setState({ isLogin: store.getState().data.isLogin })
						}
						, 1000);
				} else {
					this.setState({ isLogin: store.getState().data.isLogin })
				}
			})
	}
	getupuser() {
		fetch('/User/getupuser', {
			method: "POST",
			body: JSON.stringify({ 'id': store.getState().data.info.id })
		})
			.then(res => res.json())
			.then(data => {
				// if (data.status == 1) {
				this.setState({
					tips: data.uplist
				})
				setTimeout(
					() => {
						this.getupuser();
					}
					, 50000);
				// }
			})
	}
	uptips(id, e) {
		fetch('/User/updateuser', {
			method: "POST",
			body: JSON.stringify({ 'id': id })
		})
			.then(res => res.json())
			.then(data => {
				console.log(data)
			})
		const arr = this.state.tips;
		try {
			arr.forEach((item, index) => {
				if (item['id'] == id) {
					arr[index] = null;
					throw new Error(id);
				}
			});
		} catch (e) {
			// throw e;
			this.setState({
				tips: arr.filter(Boolean)
			})
		}
	}
	arrtips() {
		if (this.state.tips) {

			return (
				this.state.tips.map((list, index) =>
					<Menu.Item key={index}>
						{list.type == 1 ?
							<Link to={{ pathname: '/messageview/' + list.id }} onClick={this.uptips.bind(this, list.id)}>
								<Alert message={list.content} type="warning" />
							</Link>
							:
							null
						}
						{list.type == 2 ?
							// <Link to={{ pathname: '/userview/' + list.id }} onClick={this.uptips.bind(this, list.id), this.aa.bind(this, "/userlist", '员工管理')} >
							//   <Alert message={list.content} type="warning" />
							// </Link>
							<Alert message={list.content} type="warning" closable onClose={this.uptips.bind(this, list.id)} />
							:
							null
						}
						{list.type == 3 ?
							<div>
								<Alert message={<div dangerouslySetInnerHTML={{ __html: list.content }}></div>} type="warning" />
							</div>
							:
							null
						}
						{list.type == 4 ?
							<div>
								<Alert message={<div dangerouslySetInnerHTML={{ __html: list.content }}></div>} type="error" />
							</div>
							:
							null
						}
					</Menu.Item>
				)
			)
		}
	}
	render() {
		if (this.state.isLogin == false) {
			return <Redirect to={'/login'} />;
		}
		const tips = (
			<Menu>
				{this.arrtips()}
			</Menu>
		);
		// console.log(store.getState().isLogin);
		return (
			<Layout style={{ minHeight: '100vh' }}>
				<Sider
					collapsible
					collapsed={this.state.collapsed}
					onCollapse={this.onCollapse}
				>
					<div className="logo"></div>
					<Menu theme="dark" onClick={this.handleClick} selectedKeys={[store.getState().data.nav ? store.getState().data.nav : this.props.location.pathname]} mode="inline">
						{store.getState().data.info.id == 44 ? null : <>
							{store.getState().data.info.type == 1 ?
								null
								:
								<Menu.Item key="/" title="工作安排" >
									<Link to={{ pathname: '/' }} onClick={this.aa.bind(this, "/", '工作安排')}>
										<AppstoreAddOutlined />
										<span>工作安排</span>
									</Link>
								</Menu.Item>
							}
							{store.getState().data.info.type != 1 ?
								null
								:
								<Menu.SubMenu title="项目管理系统" icon={<AppstoreAddOutlined />}>
									<Menu.Item key="/" title="工作安排" >
										<Link to={{ pathname: '/' }} onClick={this.aa.bind(this, "/", '工作安排')}>
											{/* <AppstoreAddOutlined /> */}
											<span>工作安排</span>
										</Link>
									</Menu.Item>
									<Menu.Item key="/xiangmu" title="开发项目管理" >
										<Link to={{ pathname: '/xiangmu' }} onClick={this.aa.bind(this, "/xiangmu", '开发项目管理')}>
											{/* <AppstoreAddOutlined /> */}
											<span>开发项目管理</span>
										</Link>
									</Menu.Item>
									<Menu.Item key="/xmtj" title="项目统计" >
										<Link to={{ pathname: '/xmtj' }} onClick={this.aa.bind(this, "/xmtj", '项目统计')}>
											{/* <AppstoreAddOutlined /> */}
											<span>项目统计</span>
										</Link>
									</Menu.Item>
								</Menu.SubMenu>
							}
							<Menu.Item key="/rizhi" title="日志管理" >
								<Link to={{ pathname: '/rizhi' }} onClick={this.aa.bind(this, "/rizhi", '日志管理')}>
									<FundProjectionScreenOutlined />
									<span>日志管理</span>
								</Link>
							</Menu.Item>
							<Menu.Item key="/gzgl" title="工资管理" >
								<Link to={{ pathname: '/gzgl' }} onClick={this.aa.bind(this, "/gzgl", '工资管理')}>
									<AreaChartOutlined />
									<span>工资管理</span>
								</Link>
							</Menu.Item>
							{store.getState().data.info.type != 1 ?
								null
								:
								<Menu.Item key="/project" title="项目管理" >
									<Link to={{ pathname: '/project' }} onClick={this.aa.bind(this, "/project", '项目管理')}>
										<AppstoreAddOutlined />
										<span>项目管理</span>
									</Link>
								</Menu.Item>
							}
						</>}
						{store.getState().data.info.type != 1 && store.getState().data.info.id != 44 ?
							null
							:
							<Menu.Item key="/daka" title="打卡记录">
								<Link to={{ pathname: '/daka' }} onClick={this.aa.bind(this, "/daka", '打卡记录')} >
									<DingtalkOutlined />
									<span>打卡记录</span>
								</Link>
							</Menu.Item>
						}
						{store.getState().data.info.id == 44 ? <>
							<Menu.Item key="/baoxiao" title="报销管理">
								<Link to={{ pathname: '/baoxiao' }} onClick={this.aa.bind(this, "/baoxiao", '报销管理')} >
									<DollarOutlined />
									<span>报销管理</span>
								</Link>
							</Menu.Item>
							<Menu.Item key="/qingtiao" title="请假信息" >
								<Link to={{ pathname: '/qingtiao' }} onClick={this.aa.bind(this, "/qingtiao", '请假信息')}>
									<SolutionOutlined />
									<span>请假信息</span>
								</Link>
							</Menu.Item>
							<Menu.Item key="/jiaban" title="加班管理" >
								<Link to={{ pathname: '/jiaban' }} onClick={this.aa.bind(this, "/jiaban", '加班管理')}>
									<DotChartOutlined />
									<span>加班管理</span>
								</Link>
							</Menu.Item>
						</> : <>
							{store.getState().data.info.type == 1 ?
								<Menu.Item key="/cominfo" title="公司信息" >
									<Link to={{ pathname: '/cominfo' }} onClick={this.aa.bind(this, "/cominfo", '公司信息')}>
										<ClusterOutlined />
										<span>公司信息</span>
									</Link>
								</Menu.Item>
								:
								null
							}
							{store.getState().data.info.type != 1 ?
								null
								:
								<Menu.Item key="/financial1" title="财务管理">
									<Link to={{ pathname: '/financial1' }} onClick={this.aa.bind(this, "/financial1", '财务管理')} >
										<DollarCircleOutlined />
										<span>财务管理</span>
									</Link>
								</Menu.Item>
							}

							<Menu.Item key="/baoxiao" title="报销管理">
								<Link to={{ pathname: '/baoxiao' }} onClick={this.aa.bind(this, "/baoxiao", '报销管理')} >
									<DollarOutlined />
									<span>报销管理</span>
								</Link>
							</Menu.Item>
							<Menu.Item key="/message" title="站内信" >
								<Link to={{ pathname: '/message' }} onClick={this.aa.bind(this, "/message", '站内信')}>
									<FileTextOutlined />
									<span>站内信</span>
									{/* <Badge count={this.state.tips.length} overflowCount={10} size="small" offset={[5, -4]}></Badge> */}
								</Link>
							</Menu.Item>
							{store.getState().data.info.type == 1 ?
								<>
									<Menu.Item key="/qingtiao" title="请假信息" >
										<Link to={{ pathname: '/qingtiao' }} onClick={this.aa.bind(this, "/qingtiao", '请假信息')}>
											<SolutionOutlined />
											<span>请假信息</span>
										</Link>
									</Menu.Item>
									<Menu.Item key="/jiaban" title="加班管理" >
										<Link to={{ pathname: '/jiaban' }} onClick={this.aa.bind(this, "/jiaban", '加班管理')}>
											<DotChartOutlined />
											<span>加班管理</span>
										</Link>
									</Menu.Item>
								</>
								:
								null
							}

							{store.getState().data.info.type != 1 ?
								<Menu.Item key="/userview" title="个人信息">
									<Link to={{ pathname: '/userview' }} onClick={this.aa.bind(this, "/userview", '个人信息')} >
										<UserOutlined />
										<span>个人信息</span>
									</Link>
								</Menu.Item>
								:
								<Menu.Item key="/userlist" title="员工管理">
									<Link to={{ pathname: '/userlist' }} onClick={this.aa.bind(this, "/userlist", '员工管理')} >
										<UserOutlined />
										<span>员工管理</span>
									</Link>
								</Menu.Item>
							}
						</>}
					</Menu>
				</Sider>
				<Layout className="site-layout">
					<Header className="site-layout-background" style={{ padding: 0, textAlign: 'right' }} >
						<div style={{ marginRight: 25 }}>
							<span style={{ marginRight: '15px', cursor: 'pointer', display: 'inline-block' }}>
								<Dropdown overlay={tips} placement="bottomRight" trigger="click" arrow >
									{this.state.tips != 0 ?
										<Badge dot offset={[-2, 1]}>
											<BellOutlined />
											<a href="#" className="head-example" />
										</Badge>
										:
										<Badge offset={[-2, 1]}>
											<BellOutlined />
											<a href="#" className="head-example" />
										</Badge>
									}
								</Dropdown>
							</span>
							欢迎登陆，{this.state.name}
							<PoweroffOutlined style={{ marginLeft: 15 }} onClick={this.logout.bind(this)} />
						</div>
					</Header>
					<Content style={{ margin: '0 16px' }}>
						<Breadcrumb style={{ margin: '16px 0' }}>
							{this.breadcrumbItem()}
						</Breadcrumb>
						{store.getState().data.nav === '/xiangmu' ?
							<div className="site-layout-background" style={{ padding: '0 24px 24px 24px', height: 'calc(100vh - 138px)', overflowY: 'auto' }}>
								{this.props.children}
							</div>
							:
							<div className="site-layout-background" style={{ padding: 24, height: 'calc(100vh - 138px)', overflowY: 'auto' }}>
								{this.props.children}
							</div>
						}
						{/* <div className="site-layout-background" style={{ padding: 24, height: 'calc(100vh - 138px)', overflowY: 'auto' }}>
								{this.props.children}
							</div> */}
					</Content>
				</Layout>
			</Layout>
		);
	}
}
export default withRouter(SiderDemo);