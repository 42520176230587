import React from 'react';
import { Route, Switch, HashRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import './index.css';
// 404
import Error from './Error/error404.js';
// pc版
import HomeAction from './store/action/HomeAction.js';
import LoginAction from './store/action/LoginAction.js';
import SalaryAction from './store/action/SalaryAction.js';
import UserAction from './store/action/UserAction.js';
import UserviewAction from './store/action/UserviewAction.js';
import FinancialAction from './store/action/FinancialAction.js';
import MessageAction from './store/action/MessageAction.js';
import MessageViewAction from './store/action/MessageViewAction.js';
import CominfoAction from './store/action/CominfoAction.js';
import DakaAction from './store/action/DakaAction.js';
import BaoxiaoAction from './store/action/BaoxiaoAction.js';
import ProjectAction from './store/action/ProjectAction.js';
import ProViewAction from './store/action/ProViewAction.js';
import QingtiaoAction from './store/action/QingtiaoAction.js';
import RizhiAction from './store/action/RizhiAction.js';
import XiangmuAction from './store/action/XiangmuAction.js';
import GzxAction from './store/action/GzxAction.js';
import JiabanAction from './store/action/JiabanAction.js';
import XmtjAction from './store/action/XmtjAction.js';

// 手机版
import MobileHomeAction from './store/mbaction/MobileHomeAction.js';
import MobileSalaryAction from './store/mbaction/MobileSalaryAction.js';
import MobileUserviewAction from './store/mbaction/MobileUserviewAction.js';

//邮箱验证 完善信息
import MailAction from './store/action/MailAction.js';

import store from "./store/store.js";
class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 1,
        }
        store.subscribe(() => {
            // console.log('state状态改变了，新状态如下')
            // console.log(store.getState().data)
        })
    }
    componentDidMount() {
        this.props.IS_MOBILE();
    }
    render() {
        return (
            <ConfigProvider locale={zhCN}>
                <HashRouter>
                    <Switch>
                        <Route exact path="/login" component={LoginAction} />
                        <Route exact path="/mail" component={MailAction} />
                        <Route exact path="/mail/:openid" component={MailAction} />
                        {/* PC版 */}
                        {store.getState().data.ismobile == false ?
                            <HomeAction>
                                {store.getState().data.info.id == 44 ? //针对刘萌单独判断
                                    <Switch>
                                        <Route exact path="/" component={DakaAction} />
                                        <Route exact path="/daka" component={DakaAction} />
                                        <Route exact path="/baoxiao" component={BaoxiaoAction} />
                                        <Route exact path="/qingtiao" component={QingtiaoAction} />
                                        <Route exact path="/jiaban" component={JiabanAction} />
                                    </Switch>
                                    :

                                    <Switch>
                                        <Route exact path="/" component={GzxAction} />
                                        <Route exact path="/message" component={MessageAction} />
                                        <Route exact path="/Messageview/:id" component={MessageViewAction} />
                                        <Route exact path="/baoxiao" component={BaoxiaoAction} />
                                        <Route exact path="/rizhi" component={RizhiAction} />
                                        <Route exact path="/xiangmu" component={XiangmuAction} />
                                        <Route exact path="/gzgl" component={SalaryAction} />
                                        {
                                            store.getState().data.info.type != 1 ?
                                                <Switch>
                                                    <Route exact path="/userview" component={UserviewAction} />
                                                    <Route path="*" component={Error} />
                                                </Switch>
                                                :
                                                <Switch>
                                                    <Route exact path="/userview/:id" component={UserviewAction} />
                                                    <Route exact path="/userlist" component={UserAction} />
                                                    <Route exact path="/financial1" component={FinancialAction} />
                                                    <Route exact path="/financial2" component={FinancialAction} />
                                                    <Route exact path="/financial3" component={FinancialAction} />
                                                    <Route exact path="/Messageview" component={MessageViewAction} />
                                                    <Route exact path="/cominfo" component={CominfoAction} />
                                                    <Route exact path="/daka" component={DakaAction} />
                                                    <Route exact path="/project" component={ProjectAction} />
                                                    <Route exact path="/proview" component={ProViewAction} />
                                                    <Route exact path="/proview/:id" component={ProViewAction} />
                                                    <Route exact path="/qingtiao" component={QingtiaoAction} />
                                                    <Route exact path="/jiaban" component={JiabanAction} />
                                                    <Route exact path="/xmtj" component={XmtjAction} />
                                                    <Route path="*" component={Error} />
                                                </Switch>
                                        }
                                    </Switch>
                                }
                            </HomeAction>
                            :
                            // 手机版
                            store.getState().data.info.type != 1 ?
                                <MobileHomeAction>
                                    <Switch>
                                        <Route exact path="/" component={MobileSalaryAction} />
                                        <Route exact path="/userview" component={MobileUserviewAction} />
                                        <Route exact path="/message" component={MessageAction} />
                                        <Route exact path="/Messageview/:id" component={MessageViewAction} />
                                        <Route path="*" component={Error} />
                                    </Switch>
                                </MobileHomeAction>
                                :
                                null
                        }
                    </Switch>
                </HashRouter>
            </ConfigProvider>
        );
    }
}

export default App;