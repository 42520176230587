import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Layout, Menu, Breadcrumb, Dropdown, message } from 'antd';
import {
    MenuOutlined,
    CloseOutlined,
    LeftOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/zh-cn';
import '../index.css';
import store from "../store/store.js";
moment.locale('zh-cn');

class SiderDemo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: store.getState().data.isLogin,
            current: store.getState().data.navname ? store.getState().data.navname : '/',
            type: 1,
            name: store.getState().data.info.name,
            isopen:false,
        }
        store.subscribe(() => {
            // console.log('state状态改变了，新状态如下')
            // console.log(store.getState().data)
            this.setState({
                current: store.getState().data.nav,
                breadcrumb: [store.getState().data.navname],
            });
        })
    }
    componentWillMount() {
        // this.props.GET_INFO();
        this.setState(store.getState())
        fetch('/Login/checklogin', {
            method: "POST",
        })
            .then(res => res.json())
            .then(data => {
                if (data.state == 1) {
                    this.setState({
                        isLogin: true,
                        // name:data.name,
                    })
                    if (data.status == 1) {
                        this.logout();
                    }
                } else {
                    this.props.OUT_LOGIN();
                    // console.log(store.getState().data.isLogin );
                    this.setState({
                        isLogin: false
                    })
                }
            })
    }
    componentDidMount() {
        
    }
    logout() {
        fetch('/Login/logout', {
            method: "POST",
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg);
                    this.props.OUT_LOGIN()
                    setTimeout(
                        () => {
                            this.setState({ isLogin: store.getState().data.isLogin })
                        }
                        , 1000);
                } else {
                    this.setState({ isLogin: store.getState().data.isLogin })
                }
            })
    }
    openChange(){
        const openState = this.state.isopen==true?false:true;
        this.setState({
            isopen: openState,
        })
    }
    aa(nav, navname, e) {
        // console.log(nav,navname);
        this.props.NAV_CHANGE(nav, navname)
        this.openChange();
        // store.dispatch({ type: 'NAV_CHANGE', nav:nav, navname:navname });
    }
    render() {
        if (this.state.isLogin == false) {
            return <Redirect to={'/login'} />;
        }
        return (
           <>
                <div className="mb-header">
                    <span className="mb-menu" onClick={this.openChange.bind(this)}>
                        {this.state.isopen == false ? <MenuOutlined /> : <CloseOutlined />}
                    </span>
                    {/* <span className="back"><a href="javascript:history.go(-1)"><LeftOutlined /></a></span> */}
                    <p className="title">{store.getState().data.navname ? store.getState().data.navname : '工资管理'}</p>
                    <div className={this.state.isopen == true ? 'menulist hover' :'menulist'}>
                        <ul>
                            <li className={this.state.current == '/' ? 'hover' : null}><Link to={{ pathname: '/' }} onClick={this.aa.bind(this, "/", '工资管理')}><span>工资管理</span></Link></li>
                            <li className={this.state.current =='/userview'?'hover':null}><Link to={{ pathname: '/userview' }} onClick={this.aa.bind(this, "/userview", '个人信息')} ><span>个人信息</span></Link></li>
                            <li className={this.state.current == '/message' ? 'hover' : null}><Link to={{ pathname: '/message' }} onClick={this.aa.bind(this, "/message", '站内信')} ><span>站内信</span></Link></li>
                        </ul>
                    </div>
                </div>
                <div style={{padding:'0 0 15px 0'}}>
                    {this.props.children}
                </div>
           </>  
        );
    }
}
export default withRouter(SiderDemo);