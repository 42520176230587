import React, { useContext, useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Table, Input, Button, Form, Modal, Checkbox, Space, Tooltip, DatePicker, TimePicker, Select, message, Tag, Pagination, Popconfirm, Row } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, UploadOutlined, SnippetsOutlined } from '@ant-design/icons';
import Add from "./add";
import moment from 'moment';
import store from "../store/store.js";
const { Option } = Select;
const { RangePicker } = DatePicker;
class Index extends React.Component {
    constructor(props) {
        super(props);
        const myDate = new Date();
        this.state = {
            newcondition: {},
            oldcondition: {},
            list: [],
            status_react: [],
            user_react: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0
        }
        store.subscribe(() => {
            // console.log('state状态改变了，新状态如下')
            // console.log(store.getState().data.info.type)
        })
    }
    componentDidMount() {
        let that = this
        this.getdata(0)
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, { [name]: dateString })
        this.setState({
            newcondition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onRangeChange(e, dates, dateStrings) {
        console.log(dates, dateStrings)
        let data = Object.assign({}, this.state.newcondition, { starttime: dateStrings[0], endtime: dateStrings[1] })
        this.setState({
            newcondition: data
        })
    }
    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, { current: page, pagesize: pagesize })
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }
    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, { current: 1, pagesize: old.pagesize })
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('/Jiaban/index', {
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                that.setState({
                    list: data.data,
                    status_react: data.status_react,
                    user_react: data.user_react,
                    tableloading: false,
                    count: Number(data.count)
                })
                console.log(data);
            })
    }
    delSel() {
        let that = this
        fetch('/Jiaban/delSel', {
            method: "POST",
            body: JSON.stringify({
                'arrid': this.state.selectedRowKeys,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }
    reset() {
        let that = this
        that.setState({
            newcondition: {},
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }

    render() {
        let columns = [
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
                // ...this.getColumnSearchProps('name'),
                width: 150
            },
            {
                title: '申请时间',
                dataIndex: 'addtime_str',
                key: 'addtime_str',
                // ...this.getColumnSearchProps('time'),
                width: 250
            },
            {
                title: '加班日期',
                dataIndex: 'riqi_str',
                key: 'riqi_str',
                // ...this.getColumnSearchProps('time'),
                width: 150
            },
            {
                title: '加班时间',
                dataIndex: 'shijian_str',
                key: 'shijian_str',
                // ...this.getColumnSearchProps('time'),
                width: 150
            },
            {
                title: '原因',
                dataIndex: 'reason',
                key: 'reason',
            },
            {
                title: '状态',
                dataIndex: 'zt',
                key: 'zt',
                // ...this.getColumnSearchProps('zt'),
                width: 100,
                render: (row, record) => {
                    return (
                        <>
                            {record.status == -1 ? <Tag color="orange">{row}</Tag> : null}
                            {record.status == 1 ? <Tag color="green">{row}</Tag> : null}
                            {record.status == 2 ? <Tag color="red">{row}</Tag> : null}
                            {record.status == 3 ? <Tag color="grey">{row}</Tag> : null}
                        </>
                    )
                },
            },
            {
                title: '操作',
                dataIndex: 'bj',
                key: 'bj',
                // ...this.getColumnSearchProps('zt'),
                width: 100,
                render: (row, record) => {
                    return (
                        <>
                            <Space>
                                <Add id={record.id} refresh={this.getdata.bind(this)} />
                            </Space>
                        </>
                    )
                },
            },
        ]
        const { tableloading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            // hideSelectAll: true,
            preserveSelectedRowKeys: false,
        };
        const hasSelected = selectedRowKeys.length > 0;
        return <>
            <div className='sellist'>
                <Row justify="space-between" >
                    <Space wrap>
                        {hasSelected ?
                            <Popconfirm
                                title="确认删除?"
                                onConfirm={this.delSel.bind(this)}
                                okText="是"
                                cancelText="否"
                            >
                                <Button type="primary" danger>删除</Button>
                            </Popconfirm>
                            : null}
                        <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'setime')} value={[(this.state.newcondition.starttime ? moment(this.state.newcondition.starttime) : null), (this.state.newcondition.endtime ? moment(this.state.newcondition.endtime) : null)]} />
                        <Select
                            className='select'
                            style={{ width: 100 }}
                            placeholder="添加人"
                            value={this.state.newcondition.uid ? this.state.newcondition.uid : null}
                            onChange={this.onSelChange.bind(this, 'uid')}
                            allowClear
                            showSearch
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        >
                            {this.arrsel(this.state.user_react)}
                        </Select>
                        <Select className='select' style={{ width: 100 }} placeholder="状态" value={this.state.newcondition.status ? this.state.newcondition.status : null} onChange={this.onSelChange.bind(this, 'status')} allowClear>
                            {this.arrsel(this.state.status_react)}
                        </Select>
                        <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                        <Button type="primary" onClick={this.reset.bind(this)}>重置</Button>
                    </Space>
                </Row>
            </div>
            <Table
                bordered
                columns={columns}
                dataSource={this.state.list}
                pagination={false}
                loading={tableloading}
                // rowSelection={rowSelection}
                className='table'
            />
            <div className='page'>
                <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 10} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />
            </div>
        </>
    }
}
export default withRouter(Index);