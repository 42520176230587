import React from 'react'
import { Layout,Form, Input, Button, message } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { Redirect } from 'react-router'
import store from "../store/store.js";
import WxLoginCode from "../login/Wxlogin.js";
const { Header } = Layout;

class Formlogin extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      isLogin: store.getState().data.isLogin,
      type:true
    }
    store.subscribe(() => {
      // console.log('state状态改变了，新状态如下')
      // console.log(store.getState().data.isLogin)
    })
  }
  componentDidMount(){
    this.props.doLoginRedux.OUT_LOGIN();
  }
  onFinish(values){
    fetch('/Login/dologin',{
          // post提交
          method:"POST",
          body:JSON.stringify(values)
      })
      .then(res =>res.json())
      .then(data =>{
          if (data.status==1) {
            message.success(data.msg);
            this.props.doLoginRedux.GOLOGIN(data.user , this.props.doLoginRedux.history);
            this.setState({ isLogin: store.getState().data.isLogin })
          }else{
            message.error(data.msg);
          }
      })
  };
  qiehuan(){
    this.setState({
      type:!this.state.type
    })
  }
  render(){
    if (this.state.isLogin == true) {
      return <Redirect to={'/'} />;
    }
    return (
      <div>
        {this.state.type == true?
        <div>
            <Header style={{ 'backgroundColor': '#fff', 'textAlign': 'center', 'lineHeight': '64px', 'fontSize': '25px' ,'padding':'0'}}>OA系统</Header>
            {/* <div className="qrcode-button" onClick={this.qiehuan.bind(this)}></div> */}
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  // remember: true,
                }}
                onFinish={this.onFinish.bind(this)}
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: '请输入账号!',
                    },
                  ]}
                >
                  <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="账号" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: '请输入密码!',
                    },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="密码"
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    登录
                  </Button>
                </Form.Item>
              </Form>
        </div>
        :
        <div>
          <div className="qrcode-button" onClick={this.qiehuan.bind(this)}></div>
          <WxLoginCode />
        </div>
      }
      
      </div>
    );
  }
};
export default class Login extends React.Component {
  constructor(props) {
    super(props);
  }
  render(){
    return (
        <div style={{'backgroundColor':'#40a9ff','width':'100%','height':'100%','float':'left'}}>
          <div className="loginpost">
            <Formlogin doLoginRedux={this.props} />
          </div>
        </div>
      )
  }
}
