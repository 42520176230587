import React, { useState, useEffect } from 'react';
import { File } from 'better-xlsx';
import { saveAs } from 'file-saver';

function ExportExcel(datasource=[], fileName = 'example',status=0,Jishu) {
    var dataSource = datasource.concat();//复制一份数组，否则会影响原来的数组
    // console.log(dataSource)
    dataSource.map((list, index) => {
        list.xuhao = index + 1
        if (list.type == 1) {
            list.company = '天津炎汉科技有限公司' 
        }
        if (list.type == 2) {
            list.company = '天津朝夕科技有限公司'
        }
        if (list.type == 3) {
            list.company = '天津度尺科技有限公司'
        }
        // if (list.butiexq) {
        //     // console.log(list.butiexq)
        //     var butiexq = '';
        //     list.butiexq.map((item1, index1) => {
        //         var key = item1.key
        //         var val = item1.val
        //         butiexq += key + '      ￥' + val + "\n"
        //     })
        //     list.aaa = butiexq
        // } else {
        //     list.aaa = 0
        // }
    })
    if (status==1) {
        fileName = dataSource[0]['company'] + '工资单(' + dataSource[0]['addtime'] + ')';
        var zongjie = Array();
        zongjie['jbgz'] = 0
        zongjie['jiabangz'] = 0
        zongjie['butie'] = 0
        zongjie['qqbt'] = 0
        zongjie['bx'] = 0
        zongjie['yfgz'] = 0
        zongjie['yanglao'] = 0
        zongjie['yiliao'] = 0
        zongjie['shiye'] = 0
        zongjie['grdae'] = 0
        zongjie['grsb'] = 0
        zongjie['grjj'] = 0
        zongjie['gssb'] = 0
        zongjie['gsjj'] = 0
        zongjie['jsgz'] = 0
        zongjie['grsds'] = 0
        zongjie['sjgz'] = 0
        dataSource.map((list, index) => {
            zongjie['xuhao'] = index + 1
            zongjie['jbgz'] = Number(zongjie['jbgz']) + Number(list.jbgz)
            zongjie['jiabangz'] = Number(zongjie['jiabangz']) + Number(list.jiabangz)
            zongjie['butie'] = Number(zongjie['butie']) + Number(list.butie)
            zongjie['qqbt'] = Number(zongjie['qqbt']) + Number(list.qqbt)
            zongjie['bx'] = Number(zongjie['bx']) + Number(list.bx)
            zongjie['yfgz'] = Number(zongjie['yfgz']) + Number(list.yfgz)
            zongjie['yanglao'] = Number(zongjie['yanglao']) + Number(list.yanglao)
            zongjie['yiliao'] = Number(zongjie['yiliao']) + Number(list.yiliao)
            zongjie['shiye'] = Number(zongjie['shiye']) + Number(list.shiye)
            zongjie['grdae'] = Number(zongjie['grdae']) + Number(list.grdae)
            zongjie['grsb'] = Number(zongjie['grsb']) + Number(list.grsb)
            zongjie['grjj'] = Number(zongjie['grjj']) + Number(list.grjj)
            zongjie['gssb'] = Number(zongjie['gssb']) + Number(list.gssb)
            zongjie['gsjj'] = Number(zongjie['gsjj']) + Number(list.gsjj)
            zongjie['jsgz'] = Number(zongjie['jsgz']) + Number(list.jsgz)
            zongjie['grsds'] = Number(zongjie['grsds']) + Number(list.grsds)
            zongjie['sjgz'] = Number(zongjie['sjgz']) + Number(list.sjgz)
            // zongjie.company = '公司' + list.type
        })
        zongjie['jbgz'] = zongjie['jbgz'].toFixed(2)
        zongjie['jiabangz'] = zongjie['jiabangz'].toFixed(2)
        zongjie['aaa'] = zongjie['butie'].toFixed(2)
        zongjie['qqbt'] = zongjie['qqbt'].toFixed(2)
        zongjie['bx'] = zongjie['bx'].toFixed(2)
        zongjie['yfgz'] = zongjie['yfgz'].toFixed(2)
        zongjie['yanglao'] = zongjie['yanglao'].toFixed(2)
        zongjie['yiliao'] = zongjie['yiliao'].toFixed(2)
        zongjie['shiye'] = zongjie['shiye'].toFixed(2)
        zongjie['grdae'] = zongjie['grdae'].toFixed(2)
        zongjie['grsb'] = zongjie['grsb'].toFixed(2)
        zongjie['grjj'] = zongjie['grjj'].toFixed(2)
        zongjie['gssb'] = zongjie['gssb'].toFixed(2)
        zongjie['gsjj'] = zongjie['gsjj'].toFixed(2)
        zongjie['jsgz'] = zongjie['jsgz'].toFixed(2)
        zongjie['grsds'] = zongjie['grsds'].toFixed(2)
        zongjie['sjgz'] = zongjie['sjgz'].toFixed(2)
        zongjie['xuhao'] ='共计' + zongjie['xuhao']+'人'
        dataSource.push(zongjie);
    }else{
        var zongjie = Array();
        zongjie['jbgz'] = ''
        zongjie['jiabangz'] = ''
        zongjie['aaa'] = ''
        zongjie['qqbt'] = ''
        zongjie['bx'] = ''
        zongjie['yfgz'] = ''
        zongjie['yanglao'] = ''
        zongjie['yiliao'] = ''
        zongjie['shiye'] = ''
        zongjie['dae'] = ''
        zongjie['grsb'] = ''
        zongjie['grjj'] = ''
        zongjie['gssb'] = ''
        zongjie['gsjj'] = ''
        zongjie['jsgz'] = ''
        zongjie['grsds'] = ''
        zongjie['sjgz'] = ''
        zongjie['xuhao'] = '收到工资条后如未收到工资，或实际收到工资与工资条实发工资不符，及时与我联系！'
        dataSource.push(zongjie);
    }
    const column = [
        {
            title: dataSource[0]['company'],
            dataIndex: 'company',
            children: [{
                title: '发放日期:' + dataSource[0]['addtime'],
                dataIndex: 'ffrq',
                children: [
                    {
                        title: '序号',
                        dataIndex: 'xuhao',
                        width: 100,
                    },
                    {
                        title: '姓名',
                        dataIndex: 'name',
                        width: 100,
                    },
                    {
                        title: '身份证',
                        dataIndex: 'idcard',
                        width: 200,
                    },
                    {
                        title: '薪资标准',
                        dataIndex: 'jbgz',
                        editable: true,
                        width: 100,
                    },
                    {
                        title: '加班工资',
                        dataIndex: 'jiabangz',
                        editable: true,
                        width: 100,
                    },
                    {
                        title: '补贴',
                        dataIndex: 'butie',
                        editable: true,
                        width: 100,
                    },
                    {
                        title: '全勤奖',
                        dataIndex: 'qqbt',
                        editable: true,
                        width: 100,
                    },
                    {
                        title: '报销',
                        dataIndex: 'bx',
                        editable: true,
                        width: 100,
                    },
                    {
                        title: '应发工资',
                        dataIndex: 'yfgz',
                        width: 100,
                    },
                    {
                        title: '代扣个人社保',
                        dataIndex: 'grsbtitle',
                        children: [
                            {
                                title: '养老(' + (Jishu || {}).gryanglao + '%)',
                                dataIndex: 'yanglao',
                                width: 100,
                            },
                            {
                                title: '医疗(' + (Jishu || {}).gryiliao + '%)',
                                dataIndex: 'yiliao',
                                width: 100,
                            },
                            {
                                title: '失业(' + (Jishu || {}).grshiye + '%)',
                                dataIndex: 'shiye',
                                width: 100,
                            },
                            {
                                title: '大额',
                                dataIndex: 'grdae',
                                width: 100,
                            },
                            {
                                title: '小计',
                                dataIndex: 'grsb',
                                width: 100,
                            },
                        ]
                    },
                    {
                        title: '个人',
                        dataIndex: 'grgjj',
                        children: [
                            {
                                title: '公积金',
                                dataIndex: 'grjj',
                                width: 100,
                            },
                        ]
                    },
                    {
                        title: '计税工资',
                        dataIndex: 'jsgz',
                        width: 100,
                    },
                    {
                        title: '个人所得税',
                        dataIndex: 'grsds',
                        width: 100,
                    },
                    {
                        title: '实发工资',
                        dataIndex: 'sjgz',
                        width: 100,
                    },
                    {
                        title: '公司承担社保',
                        dataIndex: 'gssb',
                        width: 100,
                    },
                    {
                        title: '公司承担公积金',
                        dataIndex: 'gsjj',
                        width: 100,
                    },
                    {
                        title: '入职日期',
                        dataIndex: 'rztime',
                        width: 120,
                    },
                    {
                        title: '备注',
                        dataIndex: 'beizhu',
                        editable: true,
                        width: 150,
                    },
                    {
                        title: '电话',
                        dataIndex: 'tel',
                        width: 150,
                    },
                ]
            }]
        },

    ];
    // 新建工作谱
    const file = new File();
    // 新建表
    let sheet = file.addSheet('sheet-test');
    // 获取表头行数
    let depth = getDepth(column);
    // 获取表头的列数
    let columnNum = getColumns(column);
    // 新建表头行数
    let rowArr = [];
    for (let k = 0; k < depth; k++) {
        rowArr.push(sheet.addRow());
    }
    // 根据列数填充单元格
    rowArr.map(ele => {
        for (let j = 0; j < columnNum; j++) {
            let cell = ele.addCell();
            cell.value = j;
        }
    });
    // 初始化表头
    init(column, 0, 0);
    // 按顺序展平column
    let columnLineArr = [];
    columnLine(column);
    // 根据column,将dataSource里面的数据排序，并且转化为二维数组
    let dataSourceArr = [];
    dataSource.map(ele => {
        let dataTemp = [];
        columnLineArr.map(item => {
            dataTemp.push({
                [item.dataIndex]: ele[item.dataIndex],
                value: ele[item.dataIndex],
            });
        });
        dataSourceArr.push(dataTemp);
    });
    // debugger;
    // 绘画表格数据
    dataSourceArr.forEach((item, index) => {
        //根据数据,创建对应个数的行
        let row = sheet.addRow();
        row.setHeightCM(0.8);
        //创建对应个数的单元格
        item.map(ele => {
            let cell = row.addCell();
            if (ele.hasOwnProperty('num')) {
                cell.value = index + 1;
            } else {
                cell.value = ele.value;
            }
            // cell.style.fill.patternType = 'solid';
            // cell.style.fill.fgColor = 'DDDDDD';
            // cell.style.fill.bgColor = 'DDDDDD';
            // console.log(cell);
            if (cell.value =='收到工资条后如未收到工资，或实际收到工资与工资条实发工资不符，及时与我联系！') {
                cell.style.font.color = 'FF0000';
            }
            cell.style.applyBorder = true;
            cell.style.align.v = 'center';
            cell.style.align.h = 'center';
            cell.style.border.left = 'thin';
            cell.style.border.right = 'thin';
            cell.style.border.top = 'thin';
            cell.style.border.bottom = 'thin';
            cell.style.border.leftColor = "000000";
            cell.style.border.rightColor = "000000";
            cell.style.border.topColor = "000000";
            cell.style.border.bottomColor = "000000";
        });
    });
    //设置每列的宽度
    // for (var i = 0; i < 4; i++) {
    //     sheet.col(i).width = 20;
    // }
    sheet.col(2).width = 25;
    sheet.col(5).width = 25;
    sheet.col(19).width = 15;
    sheet.col(18).width = 15;
    sheet.col(20).width = 15;
    sheet.col(22).width = 15;
    file.saveAs('blob').then(function (content) {
        saveAs(content, fileName + '.xlsx');
    });

    // 按顺序展平column
    function columnLine(column) {
        column.map(ele => {
            if (ele.children === undefined || ele.children.length === 0) {
                columnLineArr.push(ele);
            } else {
                columnLine(ele.children);
            }
        });
    }
    // 初始化表头
    function init(column, rowIndex, columnIndex) {
        column.map((item, index) => {
            let hCell = sheet.cell(rowIndex, columnIndex);
            // 如果没有子元素, 撑满列
            if (item.title === '操作') {
                hCell.value = '';
                return;
            } else if (item.children === undefined || item.children.length === 0) {
                // 第一行加一个单元格
                hCell.value = item.title;
                hCell.vMerge = depth - rowIndex - 1;
                hCell.style.align.h = 'center';
                hCell.style.align.v = 'center';
                // hCell.style.fill.patternType = 'solid';
                // hCell.style.fill.fgColor = 'DDDDDD';
                // hCell.style.fill.bgColor = 'DDDDDD';
                hCell.style.applyBorder = true;
                hCell.style.align.v = 'center';
                hCell.style.align.h = 'center';
                hCell.style.border.left = 'thin';
                hCell.style.border.right = 'thin';
                hCell.style.border.top = 'thin';
                hCell.style.border.bottom = 'thin';
                hCell.style.border.leftColor = "000000";
                hCell.style.border.rightColor = "000000";
                hCell.style.border.topColor = "000000";
                hCell.style.border.bottomColor = "000000";
                columnIndex++;
                // rowIndex++
            } else {
                let childrenNum = 0;
                function getColumns(arr) {
                    arr.map(ele => {
                        if (ele.children) {
                            getColumns(ele.children);
                        } else {
                            childrenNum++;
                        }
                    });
                }
                getColumns(item.children);
                hCell.hMerge = childrenNum - 1;
                hCell.value = item.title;
                hCell.style.align.h = 'center';
                hCell.style.align.v = 'center';
                // hCell.style.fill.patternType = 'solid';
                // hCell.style.fill.fgColor = 'DDDDDD';
                // hCell.style.fill.bgColor = 'DDDDDD';
                // hCell.style.applyBorder = true;
                hCell.style.align.v = 'center';
                hCell.style.align.h = 'center';
                hCell.style.border.left = 'thin';
                hCell.style.border.right = 'thin';
                hCell.style.border.top = 'thin';
                hCell.style.border.bottom = 'thin';
                hCell.style.border.leftColor = "000000";
                hCell.style.border.rightColor = "000000";
                hCell.style.border.topColor = "000000";
                hCell.style.border.bottomColor = "000000";
                let rowCopy = rowIndex;
                rowCopy++;
                init(item.children, rowCopy, columnIndex);
                // 下次单元格起点
                columnIndex = columnIndex + childrenNum;
            }
        });
    }
    // 获取表头rows
    function getDepth(arr) {
        const eleDepths = [];
        arr.forEach(ele => {
            let depth = 0;
            if (Array.isArray(ele.children)) {
                depth = getDepth(ele.children);
            }
            eleDepths.push(depth);
        });
        return 1 + max(eleDepths);
    }

    function max(arr) {
        return arr.reduce((accu, curr) => {
            if (curr > accu) return curr;
            return accu;
        });
    }
    // 计算表头列数
    function getColumns(arr) {
        let columnNum = 0;
        arr.map(ele => {
            if (ele.children) {
                getColumns(ele.children);
            } else {
                columnNum++;
            }
        });
        return columnNum;
    }
}

export default ExportExcel;