import React, { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Card, Modal, Form, Input, Switch, Select, DatePicker, Table, Radio, Button,message } from 'antd';
import { EditOutlined, EyeOutlined} from '@ant-design/icons';
import moment from 'moment';
import store from "../store/store.js";
const { Meta } = Card;
const { Option } = Select;
const { TextArea } = Input;
class User2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            yglist: [],
            visible: false,
            confirmLoading: false,
            id: '',
            name: '',
            sex: '1',
            company: 0,
            hktype:0,
            contract: '',
            constart: '',
            addtime: '',
            ismail:'2',
            email:'',
            emailinfo:''
        }
        store.subscribe(() => {
            // console.log('state状态改变了，新状态如下')
            // console.log(store.getState().data.info.type)
        })
    }
    // 弹出框 开始
    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        if(!this.state.tel){
            message.error('请输入手机号');return;
        }
        this.setState({
            confirmLoading: true,
        });
        fetch('/User/doview', {
            method: "POST",
            body: JSON.stringify({
                id: this.state.id,
                name: this.state.name,
                sex: this.state.sex,
                idcard: this.state.idcard,
                company: this.state.company,
                khbank: this.state.khbank,
                bank: this.state.bank,
                banknum: this.state.banknum,
                address: this.state.address,
                tel: this.state.tel,
                fctime: this.state.fctime,
                addtime: this.state.addtime,
                status: this.state.status,
                contract: this.state.contract,
                constart: this.state.constart,
                conend: this.state.conend,
                hktype: this.state.hktype,
                jijin: this.state.jijin,
                nianjia: this.state.nianjia,
                ismail: this.state.ismail,
                email: this.state.email,
                emailinfo: this.state.emailinfo,
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                this.props.updatelist();
                if(data.sendstate==1){
                    message.success('发送成功');
                }else if(data.sendstate==-1){
                    message.error('发送失败');
                    this.setState({
                        confirmLoading: false,
                    })
                    return ;
                }
                setTimeout(() => {
                    this.handleCancel()
                }, 1000);
            })
    };
    //关闭时清空表单
    handleCancel = () => {
        this.setState({
            visible: false,
            confirmLoading: false,
            id: '',
            name: '',
            sex: '1',
            company: 0,
            hktype: 0,
            contract: '',
            constart: '',
            conend: '',
            idcard: '',
            khbank: '',
            bank: '',
            banknum: '',
            address: '',
            tel: '',
            addtime: '',
            fctime: '',
            nianjia: '',
            status: '',
            email:'',
            ismail:'2',
            emailinfo:''
        });
    };
    //弹出框 结束
    onchange = e => {
        // console.log(e)
        this.setState({
            [e.target.name]: e.target.value,
        })
    }
    //选择公司
    handleChange2 = value => {
        this.setState({
            company: value,
        })
    }
    //选择合同类型
    handleChange3 = value => {
        this.setState({
            contract: value,
        })
    }
    //选择户口类型
    handleChange4 = value => {
        this.setState({
            hktype: value,
        })
    }
    //选择性别
    sexChange = e => {
        // console.log(value);
        this.setState({
            sex: e.target.value,
        })
    }
    //选择基金
    jijinChange = e => {
        // console.log(value);
        this.setState({
            jijin: e.target.value,
        })
    }
    fcChange = e => {
        // console.log(value);
        this.setState({
            status: e.target.value,
        })
    }
    mailChange = e => {
        // console.log(value);
        this.setState({
            ismail: e.target.value,
        })
    }
    //封存
    // fcchange = (id, status) => {
    //     fetch('/User/fengcun', {
    //         method: "POST",
    //         body: JSON.stringify({ 'id': id, 'status': status })
    //     })
    //         .then(res => res.json())
    //         .then(data => {

    //         })
    // }
    // 接收员工列表
    componentWillReceiveProps(nextProps) {
        this.setState({
            yglist: nextProps.yglist
        })
    }
    fctimeonChange(date, dateString) {
        this.setState({
            fctime: dateString
        })
    }
    addtimeonChange(date, dateString) {
        this.setState({
            addtime: dateString
        })
    }
    constartonChange(date, dateString) {
        this.setState({
            constart: dateString
        })
    }
    conendonChange(date, dateString) {
        this.setState({
            conend: dateString
        })
    }
    //公司分类
    arrcom() {
        return (
            this.props.companytype.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    //合同分类
    arrcontract() {
        return (
            this.props.contracttype.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    //户口分类
    arrhk() {
        return (
            this.props.arrhktype.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    fctimeonChange2 = (id, dateString) =>{
        // console.log(id);
    }
    //点击编辑后查询当前员工的信息
    edituser = id => {
        // console.log(id);
        if (id) {
            fetch('/User/view', {
                method: "POST",
                body: JSON.stringify({ 'id': id })
            })
                .then(res => res.json())
                .then(data => {
                    // console.log(data);
                    this.setState({
                        id: data.id,
                        name: data.name,
                        sex: data.sex,
                        idcard: data.idcard,
                        company: data.company,
                        khbank: data.khbank,
                        bank: data.bank,
                        banknum: data.banknum,
                        address: data.address,
                        tel: data.tel,
                        fctime: data.fctime,
                        addtime: data.addtime,
                        status: data.status,
                        contract: data.contract,
                        constart: data.constart,
                        conend: data.conend,
                        hktype: data.hktype,
                        nianjia: data.nianjia,
                        jijin: data.jijin,
                    })
                })
        }
        this.showModal();
    }
    oachange(id,checked){
        console.log(id,checked)
        fetch('/User/oachange', {
            method: "POST",
            body: JSON.stringify({
                id:id,
                checked:checked
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
            })
    }
    render() {
        const { visible, confirmLoading, ModalText } = this.state;
        //弹出框内容 开始
        const layout = {
            labelCol: { span: 5 },
            wrapperCol: { span: 19 },
        };
        //弹出框内容 结束
        var columns = [
            {
                title: '姓名',
                width: 50,
                dataIndex: 'name',
                key: 'name',
                fixed: 'left',
            },
            {
                title: '项目权限',
                width: 40,
                dataIndex: 'xmqx',
                key: 'xmqx',
                fixed: 'left',
                render: (row, record) => 
                <>
                    <Switch defaultChecked={row==1?true:false} onChange={this.oachange.bind(this,record.id)} />
                </>
            },
            {
                title: '性别',
                width: 30,
                dataIndex: 'sex',
                align:'center',
                key: 'sex',
            },
            {
                title: '年龄',
                width: 30,
                dataIndex: 'age',
                key: 'age',
            },
            { title: '年假', dataIndex: 'nianjia', width: 30,align:'center', key: 'nianjia' },
            { title: '身份证号', dataIndex: 'idcard', width: 100, key: 'idcard' },
            { title: '移动电话', dataIndex: 'tel', width: 60, key: 'tel' },
            { title: '银行账号', dataIndex: 'banknum', width: 100, key: 'banknum' },
            { title: '银行', dataIndex: 'bank', width: 60, key: 'bank' },
            { title: '开户行', dataIndex: 'khbank', width: 120, key: 'khbank' },
            { title: '所属公司', dataIndex: 'company', width: 100, key: 'company' },
            { title: '入职日期', dataIndex: 'addtime', width: 65,align:'center', key: 'addtime' },
            { title: '合同', dataIndex: 'contract', width: 50,align:'center', key: 'contract' },
            { 
                title: '合同起止时间',
                dataIndex: 'consetime', 
                width: 150, 
                key: 'consetime', 
                render:(text,record) =>
                <>
                    <div dangerouslySetInnerHTML={{__html:text}}></div>
                </>
            },
            { title: '户口性质', dataIndex: 'hktype', width: 80, key: 'hktype' },
            {
                title: '操作',
                key: 'operation',
                fixed: 'right',
                width: 45,
                align:'center',
                render: (text, record) => 
                <>
                    {/* <EditOutlined key="edit" onClick={() => this.edituser(record.id)} style={{marginRight:15}} /> */}
                    <Link to={{ pathname: '/userview/' + record.id }} style={{ color:"rgba(0, 0, 0, 0.85)"}} ><EyeOutlined /></Link>
                </>
                ,
            },
        ];
        if (store.getState().data.info.id == 4){
            columns = [
                {
                    title: '姓名',
                    width: 100,
                    dataIndex: 'name',
                    key: 'name',
                    fixed: 'left',
                },
                {
                    title: '项目权限',
                    width: 40,
                    dataIndex: 'xmqx',
                    key: 'xmqx',
                    fixed: 'left',
                    render: (row, record) => 
                    <>
                        <Switch defaultChecked={row==1?true:false} onChange={this.oachange.bind(this,record.id)} />
                    </>
                },
                {
                    title: '性别',
                    width: 50,
                    dataIndex: 'sex',
                    key: 'sex',
                },
                {
                    title: '年龄',
                    width: 30,
                    dataIndex: 'age',
                    key: 'age',
                },
                { title: '年假', dataIndex: 'nianjia', width: 50, key: 'nianjia' },
                { title: '身份证号', dataIndex: 'idcard', width: 200, key: 'idcard' },
                { title: '移动电话', dataIndex: 'tel', width: 150, key: 'tel' },
                { title: '银行账号', dataIndex: 'banknum', width: 200, key: 'banknum' },
                { title: '银行', dataIndex: 'bank', width: 150, key: 'bank' },
                { title: '开户行', dataIndex: 'khbank', width: 150, key: 'khbank' },
                // { title: '所属公司', dataIndex: 'company', width: 150, key: 'company' },
                { title: '入职日期', dataIndex: 'addtime', width: 100, key: 'addtime' },
                { title: '合同', dataIndex: 'contract', width: 100, key: 'contract' },
                { 
                    title: '合同起止时间',
                    dataIndex: 'consetime', 
                    width: 150, 
                    key: 'consetime', 
                    render:(text,record) =>
                    <>
                        <div dangerouslySetInnerHTML={{__html:text}}></div>
                    </>
                },
                { title: '户口性质', dataIndex: 'hktype', width: 100, key: 'hktype' },
                {
                    title: '操作',
                    key: 'operation',
                    fixed: 'right',
                    width: 45,
                    align: 'center',
                    render: (text, record) =>
                        <>
                            {/* <EditOutlined key="edit" onClick={() => this.edituser(record.id)} style={{marginRight:15}} /> */}
                            <Link to={{ pathname: '/userview/' + record.id }} style={{ color: "rgba(0, 0, 0, 0.85)" }} ><EyeOutlined /></Link>
                        </>
                    ,
                },
            ];
        }
        return (
            <>
                {/* 弹出框 */}
                <Modal
                    title="员工信息"
                    width={800}
                    visible={visible}
                    onOk={this.handleOk}
                    okText={this.state.ismail==2?'确定':'发送'}
                    centered
                    confirmLoading={confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <div>
                        <Form
                            {...layout}
                            name="grinfo"
                        >
                            <Form.Item
                                label="姓名"
                            >
                                <Input name="name" value={this.state.name} onChange={this.onchange.bind(this)} />
                            </Form.Item>
                            {/* <Form.Item
                                label="性别"
                            >
                                <Radio.Group onChange={this.sexChange.bind(this)} value={this.state.sex}>
                                    <Radio value="1" key={1}>男</Radio>
                                    <Radio value="2" key={2}>女</Radio>
                                </Radio.Group>
                            </Form.Item> */}
                            <Form.Item
                                label="手机号"
                            >
                                <Input name="tel" value={this.state.tel} onChange={this.onchange.bind(this)} />
                            </Form.Item>
                            {store.getState().data.info.id != 4?
                                <Form.Item
                                    label="公司"
                                >
                                    <Select value={this.state.company == 0 ? null : parseInt(this.state.company)} placeholder="请选择" name="company" onChange={this.handleChange2.bind(this)}>
                                        {this.arrcom()}
                                    </Select>
                                </Form.Item>
                            :null}
                            <Form.Item
                                label="合同类型"
                            >
                                <Select value={this.state.contract == 0 ? null : parseInt(this.state.contract)} placeholder="请选择" name="company" onChange={this.handleChange3.bind(this)}>
                                    {this.arrcontract()}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="合同开始日期"
                            >
                                <DatePicker name="constart" onChange={this.constartonChange.bind(this)} value={this.state.constart ? moment(this.state.constart) : null} placeholder="合同开始日期" />
                            </Form.Item>
                            <Form.Item
                                label="合同结束日期"
                            >
                                <DatePicker name="conend" onChange={this.conendonChange.bind(this)} value={this.state.conend ? moment(this.state.conend) : null} placeholder="合同结束日期" />
                            </Form.Item>
                            <Form.Item
                                label="入职日期"
                            >
                                <DatePicker name="addtime" onChange={this.addtimeonChange.bind(this)} value={this.state.addtime ? moment(this.state.addtime) : null} placeholder="入职时间" />
                            </Form.Item>
                            <Form.Item
                                label="是否发送邮件"
                            >
                                <Radio.Group onChange={this.mailChange.bind(this)} value={this.state.ismail}>
                                    <Radio value="1" key={1}>是</Radio>
                                    <Radio value="2" key={2}>否</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {this.state.ismail==1?
                                <>
                                    <Form.Item
                                        label="邮箱"
                                    >
                                        <Input name="email" value={this.state.email} onChange={this.onchange.bind(this)} />
                                    </Form.Item>
                                    <Form.Item
                                        label="邮箱内容"
                                    >
                                        <TextArea rows={8} name="emailinfo" value={this.state.emailinfo} onChange={this.onchange.bind(this)} />
                                    </Form.Item>
                                </>
                                :
                                null
                            }
                        </Form>
                    </div>
                </Modal>
                <div style={{ float: 'right',marginBottom:15 }}><Button type="primary" onClick={() => this.edituser()} style={{ marginLeft: 10 }}   >添加员工</Button></div>

                {/* 员工列表 */}
                <Table
                    bordered
                    dataSource={this.state.yglist}
                    columns={columns}
                    rowClassName="editable-row"
                    // pagination={{ pageSize: 10, showTotal: total => `共${total}条数据` }}
                    pagination={false}
                    scroll={{ x: 2700, y: 'calc(100vh - 300px)' }}
                    />
            </>
        )
    }
}
export default User2;