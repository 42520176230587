import React from 'react';
import { Divider, Select, Button, Radio, message, Switch, Modal,Upload } from 'antd';
import { ZoomInOutlined, UploadOutlined,AreaChartOutlined,DownloadOutlined } from '@ant-design/icons';
import store from "../store/store.js";
// store.dispatch({ type: 'GET_INFO' });
const { Option } = Select;
class Gzlist extends React.Component {
    constructor(props) {
        super(props);
        const myDate = new Date();
        this.state = {
            defaultyear: myDate.getFullYear(),
            defaultmonth: (myDate.getMonth() + 1).toString(),
            yearlist:[],
            gzinfo:''
        }
        store.subscribe(() => {
            // console.log('state状态改变了，新状态如下')
            // console.log(store.getState().data.info.type)
        })
    }
    componentDidMount() {
        this.getgz(this.state.defaultyear,this.state.defaultmonth)
    }
    handleChange = value => {
        this.setState({
            defaultyear: value,
        })
        this.getgz(value, this.state.defaultmonth);
    }
    handleChange = value => {
        this.setState({
            defaultmonth: value,
        })
        this.getgz(this.state.defaultyear, value);
    }
    arryear(){
        const arr = this.state.yearlist
        return (
            arr.map((list, index) =>
                <Option value={list.num} key={index}>{list.num + '年'}</Option>
            )
        )
    }
    //筛选工资单
    getgz = (year,month) =>{
        fetch('/Salary/getUserGzListMb', {
            method: "POST",
            body: JSON.stringify({ 'year': year, 'month': month ,'uid':store.getState().data.info.id})
        })
        .then(res => res.json())
        .then(data => {
            console.log(data)
            this.setState({
                defaultyear: data.year,
                defaultmonth: (data.month).toString(),
                yearlist: data.yearlist,
                gzinfo:data.gz
            })
        })
    }
    render(){
        return (
            <>
            <div style={{padding:15}}>
                <Select value={this.state.defaultyear} style={{ width: 120,marginRight:15 }} onChange={this.handleChange.bind(this)}>
                    {this.arryear()}
                </Select>
                <Select value={parseInt(this.state.defaultmonth)} style={{ width: 120,marginRight:15 }} onChange={this.handleChange.bind(this)}>
                    <option value={0}>请选择</option>
                    <option value={1}>1月</option>
                    <option value={2}>2月</option>
                    <option value={3}>3月</option>
                    <option value={4}>4月</option>
                    <option value={5}>5月</option>
                    <option value={6}>6月</option>
                    <option value={7}>7月</option>
                    <option value={8}>8月</option>
                    <option value={9}>9月</option>
                    <option value={10}>10月</option>
                    <option value={11}>11月</option>
                    <option value={12}>12月</option>
                </Select>
            </div>
            {this.state.gzinfo?
                <table className="ant-table grinfo">
                    <tbody>
                        <tr>
                            <td className="ant-table-cell title">发放时间</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.time}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">薪资标准</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.xzbj}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">岗位工资</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.gwgz}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">绩效工资</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.jxgz}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">加班工资</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.jbgz}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">报销</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.bx}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">补贴</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.bt}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">全勤奖</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.qqj}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">餐补</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.cb}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell th" colSpan="2">年假统计</td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">总年假</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.znj}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">请假天数</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.qjts}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">剩余年假</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.synj}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">扣款</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.kk}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">应发工资</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.yfgz}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell th" colSpan="2">代扣个人社保</td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">缴费基数</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.jfjs}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">养老(8%)</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.gryangl}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">医疗(2%)</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.gryil}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">失业(0.5%)</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.grsy}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">大额</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.grde}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">小计</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.grxj}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">个人公积金</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.grjj}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">计税工资</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.jsgz}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">个人所得税</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.grsds}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">实发工资</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.sfgz}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell th" colSpan="2">公司承担社保</td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">企业基本养老保险（16%）</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.gsyangl}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">失业保险（0.5%）</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.gssy}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">基本医疗保险（10%）</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.gsyil}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">工伤保险（0.4%）</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.gsgs}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">职工基本医疗(生育)保险（0.5%）</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.gsshengy}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">公司承担公积金</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.gsjj}
                            </td>
                        </tr>
                        <tr>
                            <td className="ant-table-cell title">备注</td>
                            <td className="ant-table-cell">
                                {this.state.gzinfo.beizhu}
                            </td>
                        </tr>
                    </tbody>
                </table>
                :
                <table className="ant-table grinfo">
                    <tbody>
                        <tr>
                            <td className="ant-table-cell th" colSpan="2" style={{textAlign:'center'}}>无</td>
                        </tr>
                    </tbody>
                </table>
            }
            </>
        )
    }
        
}
export default Gzlist;