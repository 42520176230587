import React, { useContext, useState, useEffect, useRef } from 'react';
import Highlighter from 'react-highlight-words';
import { Table, Button, Modal, Form, DatePicker, Select, Input, Space, message, Popconfirm, InputNumber, Divider, AutoComplete, Tooltip} from 'antd';
import { MinusCircleOutlined, PlusOutlined, EditOutlined, DeleteOutlined, SearchOutlined} from '@ant-design/icons';
import Uploadfile from '../financial/uploadfile2';
import moment from 'moment';
import store from "../store/store.js";
const { Option } = Select;
const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef();
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async (e) => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
                <div
                    className="editable-cell-value-wrap"
                    style={{
                        paddingRight: 24,
                    }}
                    onClick={toggleEdit}
                >
                    {children}
                </div>
            );
    }

    return <td {...restProps}>{childNode}</td>;
};
class Invoice extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            invoice: [],
            type: store.getState().data.info.id != 4 ?1:2,
            id: '',
            kytime: '',
            fptype: 0,
            fpnum: '',
            gmfname: '',
            jine: '',
            xiangqing: [],
            fkstatus: 0,
            status: 0,
            addtime: '',
        }
    }
    // 接收
    componentWillReceiveProps(nextProps) {
        // console.log(nextProps.invoice);
        this.setState({
            invoice: nextProps.invoice,
            type: nextProps.type
        })
    }
    componentWillMount() {
        this.props.getfp();
        // console.log(this);
        // return false;
    }
    // 弹出框 开始
    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    // handleOk = () => {
    //     this.setState({
    //         confirmLoading: true,
    //     });
    //     fetch('/Fapiao/doview', {
    //         method: "POST",
    //         body: JSON.stringify({
    //             id: this.state.id,
    //             kytime: this.state.kytime,
    //             fptype: this.state.fptype,
    //             fpnum: this.state.fpnum,
    //             gmfname: this.state.gmfname,
    //             jine: this.state.jine,
    //             xiangqing: this.state.xiangqing,
    //             fkstatus: this.state.fkstatus,
    //             status: this.state.status,
    //             addtime: this.state.addtime,
    //         })
    //     })
    //         .then(res => res.json())
    //         .then(data => {
    //             console.log(data);
    //             this.props.updatelist();
    //         })
    //     setTimeout(() => {
    //         this.setState({
    //             visible: false,
    //             confirmLoading: false,
    //         });
    //     }, 1000);
    // };
    //关闭时清空表单
    handleCancel = () => {
        this.setState({
            visible: false,
            id: '',
            kytime: '',
            fptype: 0,
            fpnum: '',
            gmfname: '',
            jine: '',
            xiangqing: [],
            fkstatus: 0,
            status: 0,
            addtime: '',
        });
    };
    //弹出框 结束
    //点击编辑后查询当前员工的信息
    editfp = id => {
        // console.log(id);
        if (id) {
            fetch('/Fapiao/view', {
                method: "POST",
                body: JSON.stringify({
                    id: id,
                })
            })
                .then(res => res.json())
                .then(data => {
                    // console.log(data);
                    this.setState({
                        id: id,
                        // kptime: moment(data.kptime),
                        // fptype: parseInt(data.fptype),
                        // fpnum: data.fpnum,
                        // gmfname: data.gmfname,
                        // jine: data.jine,
                        // xiangqing: data.xiangqing,
                        // fkstatus: parseInt(data.fkstatus),
                        // status: parseInt(data.status),
                        // addtime: moment(data.addtime),
                    })
                    if (data.xiangqing) {
                        data.xiangqing.map((item,index)=>{
                            item['shijian'] = moment(item['shijian'])
                        })
                        this.formRef.current.setFieldsValue({
                            xiangqing: data.xiangqing,
                        })
                    }
                    if (data.status) {
                        this.formRef.current.setFieldsValue({
                            status: parseInt(data.status),
                        })
                    }
                    if (data.isdy) {
                        this.formRef.current.setFieldsValue({
                            isdy: parseInt(data.isdy),
                        })
                    }
                    if (data.zftime){
                        this.formRef.current.setFieldsValue({
                            zftime: moment(data.zftime),
                        })
                    }
                    this.formRef.current.setFieldsValue({
                        id: id,
                        kptime: moment(data.kptime),
                        fptype: parseInt(data.fptype),
                        fpnum: data.fpnum,
                        gmfname: data.gmfname,
                        gmfshui: data.gmfshui,
                        gmfaddress: data.gmfaddress,
                        gmftel: data.gmftel,
                        gmfbanknum: data.gmfbanknum,
                        jine: data.jine,
                        addtime: moment(data.addtime),
                        fpcode: data.fpcode,
                        protitle: data.protitle,
                        remark: data.remark,
                        shui: data.shui,
                        shuie: data.shuie,
                    })
                })
            
        }
        this.showModal();
    }
    delfp = id => {
        if (id) {
            fetch('/Fapiao/delfp', {
                method: "POST",
                body: JSON.stringify({
                    id: id,
                })
            })
                .then(res => res.json())
                .then(data => {
                    this.props.getfp();
                })
            }
    }
    //发票分类
    arrfptype() {
        return (
            this.props.arrfptype.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    //付款状态
    arrfktype() {
        return (
            this.props.arrfktype.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    //发票状态
    arrfpstatus() {
        return (
            this.props.arrfpstatus.map((list, index) =>
                <Option value={list.id} key={index}>{list.name}</Option>
            )
        )
    }
    //客户历史信息
    // arrcustomer() {
    //     return (
    //         this.props.arrcustomer.map((list, index) =>
    //             <Option value={list.id} key={index}>{list.name}</Option>
    //         )
    //     )
    // }
    onFinish = (values) => {
        if (values['kptime']) {
            values['kptime'] = values['kptime'].format('YYYY-MM-DD HH:mm:ss')
        }
        if (values['zftime']) {
            values['zftime'] = values['zftime'].format('YYYY-MM-DD HH:mm:ss')
        }
        if (values['xiangqing']) {
            for (let i = 0; i < values['xiangqing'].length; i++) {
                if (values['xiangqing'][i]['shijian']) {
                    values['xiangqing'][i]['shijian'] = values['xiangqing'][i]['shijian'].format('YYYY-MM-DD')
                }
            }
        }
        // console.log(values);
        this.setState({
            confirmLoading: true,
        });
        fetch('/Fapiao/doview', {
            method: "POST",
            body: JSON.stringify({
                info: values,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data);
                if (data.msg) {
                    message.error(data.msg);
                }else{
                    this.props.getfp();
                    setTimeout(() => {
                        this.setState({
                            visible: false,
                            confirmLoading: false,
                        });
                    }, 1000);
                }
                
            })
    };
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`查询条件`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        搜索
          </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        清除
          </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                    text
                ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    handleSelChange = value => {
        // console.log(value);
        // this.setState({
        //     type: value
        // });
        this.props.typechange(value)
    };
    gmfchange = (value,option) => {
        // console.log(value, option);
        fetch('/Fapiao/hiscustom', {
            method: "POST",
            body: JSON.stringify({ 'id': option.id ? option.id : 0 })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data);
                this.formRef.current.setFieldsValue({
                    // id: id,
                    // kptime: moment(data.kptime),
                    // fptype: parseInt(data.fptype),
                    // fpnum: data.fpnum,
                    gmfname: data.name,
                    gmfshui: data.shui,
                    gmfaddress: data.address,
                    gmftel: data.tel,
                    gmfbanknum: data.banknum,
                    // jine: data.jine,
                    // addtime: moment(data.addtime),
                })
            })
    };
    render() {
        const { visible, confirmLoading, ModalText } = this.state;
        //弹出框内容 开始
        const layout = {
            labelCol: { span: 5 },
            wrapperCol: { span: 19 },
        };
        const tailLayout = {
            wrapperCol: { offset: 5, span: 19 },
        };
        //弹出框内容 结束
        const components = {
            body: {
                row: EditableRow,
                cell: EditableCell,
            },
        };
        var columns = [
            { title: '开票日期', width: 100, dataIndex: 'kptime', key: 'kptime', ...this.getColumnSearchProps('kptime') },
            { title: '作废日期', width: 100, dataIndex: 'zftime', key: 'zftime', ...this.getColumnSearchProps('zftime') },
            {
                title: '发票类型',
                width: 100,
                dataIndex: 'fptype',
                key: 'fptype',
                filters: [
                    {
                        text: '增值税普通发票',
                        value: '增值税普通发票',
                    },
                    {
                        text: '增值税专用发票',
                        value: '增值税专用发票',
                    }
                ],
                onFilter: (value, record) => record.fptype.includes(value) === true,
            },
            { title: '发票号码', width: 60, dataIndex: 'fpnum', key: 'fpnum', ...this.getColumnSearchProps('fpnum') },
            { title: '主要商品名称', width: 200, dataIndex: 'protitle', ellipsis: { showTitle: false }, key: 'protitle', ...this.getColumnSearchProps('protitle'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>) },
            {
                title: '购买方名称',
                width: 100,
                dataIndex: 'gmfname',
                ellipsis: { showTitle: false },
                key: 'gmfname',
                ...this.getColumnSearchProps('gmfname'),
                render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>)
            },
            { title: '购买方税号', width: 100, dataIndex: 'gmfshui', ellipsis: { showTitle: false }, key: 'gmfshui', ...this.getColumnSearchProps('gmfshui'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>) },
            { title: '购买方地址', width: 100, dataIndex: 'gmfaddress', ellipsis: { showTitle: false }, key: 'gmfaddress', ...this.getColumnSearchProps('gmfaddress'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>) },
            { title: '购买方电话', width: 100, dataIndex: 'gmftel', ellipsis: { showTitle: false }, key: 'gmftel', ...this.getColumnSearchProps('gmftel'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>) },
            { title: '购买方银行账号', width: 100, dataIndex: 'gmfbanknum', ellipsis: { showTitle: false }, key: 'gmfbanknum', ...this.getColumnSearchProps('gmfbanknum'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>) },
            {
                title: '合计金额',
                width: 100,
                dataIndex: 'jine',
                key: 'jine',
                ...this.getColumnSearchProps('jine'),
                render: (text, record) => {
                    return text.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
            },
            {title: '税率',width: 100,dataIndex: 'shui',key: 'shui',...this.getColumnSearchProps('shui'),},
            {
                title: '合计税额',
                width: 100,
                dataIndex: 'shuie',
                key: 'shuie',
                ...this.getColumnSearchProps('shuie'),
                render: (text, record) => {
                    return text.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
            },
            {
                title: '价税统计',
                width: 100,
                dataIndex: 'jstj',
                key: 'jstj',
                ...this.getColumnSearchProps('jstj'),
                render: (text, record) => {
                    var jstj = Number(record.jine) + Number(record.shuie)
                    return jstj.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
            },
            {
                title: '付款详情',
                width: 150,
                dataIndex: 'xiangqing',
                key: 'xiangqing',
                ...this.getColumnSearchProps('xiangqing'),
                render: (text, record) => {
                    const arr = JSON.parse(record.xiangqing);
                    if (arr) {
                        // arr.map((list, index) =>
                        //     list.fukuan = list.fukuan.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        // )
                        return (
                            arr.map((list, index) =>
                                <>
                                    <p key={index}>{list.shijian}&nbsp;&nbsp;&nbsp;&nbsp;￥{list.fukuan}</p>
                                </>
                            )
                        )
                    }
                }
            },
            {
                title: '付款状态',
                dataIndex: 'fkstatus',
                width: 60,
                key: 'fkstatus',
                filters: [
                    {
                        text: '未付款',
                        value: '未付款',
                    },
                    {
                        text: '已付款',
                        value: '已付款',
                    },
                    {
                        text: '未付完',
                        value: '未付完',
                    },
                ],
                onFilter: (value, record) => record.fkstatus.includes(value) === true,
            },
            {
                title: '发票状态',
                dataIndex: 'status',
                width: 60,
                key: 'status',
                filters: [
                    {
                        text: '正常',
                        value: '正常',
                    },
                    {
                        text: '作废',
                        value: '作废',
                    },
                    {
                        text: '冲红',
                        value: '冲红',
                    },
                ],
                onFilter: (value, record) => record.status.includes(value) === true,
                render: (text, record) => {
                    if (text == '冲红') {
                        return <><font color="#f00">{text}</font></>
                    } else {
                        return text;
                    }
                }
            },
            {
                title: '打印标志',
                dataIndex: 'isdy',
                width: 50,
                key: 'isdy',
                render: (text, record) => {
                    if (text == 1) {
                        return '是'
                    }
                    if (text == 2) {
                        return '否';
                    }
                }
            },
            { title: '备注', width: 200, dataIndex: 'remark', ellipsis: { showTitle: false }, key: 'remark', ...this.getColumnSearchProps('remark'), render: content => (<Tooltip placement="topLeft" title={content}>{content}</Tooltip>) },
            {
                title: '操作',
                width: 40,
                dataIndex: 'caozuo',
                key: 'caozuo',
                fixed: 'right',
                render: (text, record) =>
                    <>
                        <EditOutlined key="edit" onClick={() => this.editfp(record.id)} style={{ marginRight: 15 }} />
                        <Popconfirm title="确定删除吗?" onConfirm={() => this.delfp(record.id)}>
                            <DeleteOutlined key="del" />
                        </Popconfirm>
                    </>
            },
        ];
        
        const column = columns.map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });
        return (
            <>
                {store.getState().data.info.id != 4 ?
                <Select value={this.state.type} style={{ width: 120, marginLeft: 15 }} onChange={this.handleSelChange.bind(this)}>
                    <Option value={1} key={1}>炎汉科技</Option>
                    <Option value={2} key={2}>朝夕科技</Option>
                    <Option value={3} key={3}>度尺科技</Option>
                </Select>
                :null}
                {/* 弹出框 */}
                <Modal
                    title="发票信息"
                    visible={visible}
                    onOk={this.handleOk}
                    centered
                    confirmLoading={confirmLoading}
                    onCancel={this.handleCancel}
                    footer={null}
                >
                    <div>
                        <Form
                            {...layout}
                            name="fpinfo"
                            ref={this.formRef}
                            initialValues={{ 
                                // id:this.state.id,
                                type: this.state.type,
                                // kytime: this.state.kytime,
                                fptype: 1,
                                // fpnum: this.state.fpnum,
                                // gmfname: this.state.gmfname,
                                // jine: this.state.jine,
                                // xiangqing: this.state.xiangqing,
                                // fkstatus: this.state.fkstatus,
                                status: 1,
                                // addtime: this.state.addtime,
                             }}

                            onFinish={this.onFinish}
                        >
                            <Form.Item name="id" label="id" style={{display:'none'}}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="type" label="type" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="kptime" label="开票日期" >
                                <DatePicker showTime />
                            </Form.Item>
                            <Form.Item name="fpcode" label="发票代码" >
                                <Input />
                            </Form.Item>
                            <Form.Item name="fptype" label="发票类型" >
                                <Select placeholder="请选择" >
                                    {this.arrfptype()}
                                </Select>
                            </Form.Item>
                            <Form.Item name="fpnum" label="发票号码" >
                                <Input />
                            </Form.Item>
                            <Form.Item name="protitle" label="主要商品名称" >
                                <Input />
                            </Form.Item>
                            <Divider plain></Divider>
                            <Form.Item name="gmfname" label="购买方名称" >
                                <AutoComplete onSelect={this.gmfchange} options={this.props.arrcustomer} allowClear={true}>
                                    <Input />
                                </AutoComplete>
                            </Form.Item>
                            <Form.Item name="gmfshui" label="购买方税号" >
                                <Input />
                            </Form.Item>
                            <Form.Item name="gmfaddress" label="购买方地址" >
                                <Input />
                            </Form.Item>
                            <Form.Item name="gmftel" label="购买方电话" >
                                <Input />
                            </Form.Item>
                            <Form.Item name="gmfbanknum" label="购买方银行账号" >
                                <Input />
                            </Form.Item>
                            <Divider plain></Divider>
                            <Form.Item name="jine" label="合计金额" >
                                <InputNumber 
                                    style={{width:'100%'}}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                            <Form.Item name="shui" label="税率" >
                                <Input />
                            </Form.Item>
                            <Form.Item name="shuie" label="合计税额" >
                                <InputNumber
                                    style={{ width: '100%' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                            {/* <Form.Item name="xiangqing" label="付款详情" >
                                <Input />
                            </Form.Item> */}
                            <Form.Item  label="付款详情" >
                                <Form.List name="xiangqing"  >
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(field => (
                                            <Space key={field.key}  align="baseline" >
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'shijian']}
                                                    fieldKey={[field.fieldKey, 'shijian']}
                                                >
                                                    <DatePicker />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'fukuan']}
                                                    fieldKey={[field.fieldKey, 'fukuan']}
                                                >
                                                    <InputNumber
                                                        style={{ width: '100%' }}
                                                        placeholder="金额"
                                                        formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                    />
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                                            </Space>
                                        ))}
                                        <Form.Item style={{marginBottom:0}}>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Add field
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                            </Form.Item>
                            {/* <Form.Item name="fkstatus" label="付款状态" >
                                <Select placeholder="请选择" >
                                    {this.arrfktype()}
                                </Select>
                            </Form.Item> */}
                            <Form.Item name="remark" label="备注" >
                                <Input />
                            </Form.Item>
                            <Form.Item name="isdy" label="打印标志" >
                                <Select placeholder="请选择" >
                                    <option value={1}>是</option>
                                    <option value={2}>否</option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="zftime" label="作废日期" >
                                <DatePicker showTime />
                            </Form.Item>
                            <Form.Item name="status" label="发票状态" >
                                <Select placeholder="请选择" >
                                    {this.arrfpstatus()}
                                </Select>
                            </Form.Item>
                            <Form.Item {...tailLayout}>
                                <Button type="primary" htmlType="submit">
                                    提交
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>
                <div style={{ float: 'right', marginBottom: 15 }}><Button type="primary" onClick={() => this.editfp()} style={{ marginLeft: 10 }}   >添加发票</Button></div>
                <div style={{ float: 'right', marginBottom: 15 }}><Uploadfile type={this.state.type} action="/Fapiao/uploadExcel" getfp={this.props.getfp} /></div>
                {/* 员工列表 */}
                <Table
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    dataSource={this.state.invoice}
                    columns={column}
                    pagination={false}
                    scroll={{ x: 4000, y: 'calc(100vh - 400px)' }}
                    style={{ marginTop: 25 }}
                    // pagination={{ pageSize: 10, showTotal: total => `共${total}条数据` }}
                    pagination={false}
                    summary={
                            pageData => {
                                let totaljine = 0;
                                let totalshuie = 0;
                                let totaljstj = 0;
                                pageData.forEach(({ jine, shuie, jstj = Number(jine) + Number(shuie)}) => {
                                    totaljine += Number(jine);
                                    totalshuie += Number(shuie);
                                    totaljstj += Number(jstj);
                                });
                                // totaljstj = totaljine + totalshuie
                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell>总计</Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                {totaljine.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                {totalshuie.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                {totaljstj.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }
                        }
                />
            </>
        )
    }
}
export default Invoice;